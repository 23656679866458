import { useEffect, useState } from "react";
import { Accordion } from "react-component-library";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { shoot } from "src/redux/actions";
import { select_history_item, select_quote } from "src/redux/store";
import {
  DataEntryStep3Props,
  agency_product_t,
  coverage_t,
  coverages_t,
  formik_value_t,
} from "src/types";
import { quote_t } from "src/types/http/quote.types";
import { get_language } from "src/utils";
import { InputCheckbox } from "src/widgets/input-checkbox/input-checkbox";
import { Recaptcha } from "src/widgets/recaptcha/recaptcha";
import ScrollToTop from "src/widgets/scroll-to-top/scroll-to-top";

import { get_quote_data } from "../../handlers/data-entry-quote";

const DataEntryCustomStep3 = (props: DataEntryStep3Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    validationSchema,
    formik: { values, errors, touched, handleChange, handleBlur },
  } = props;

  /**
   * UseState
   */
  const [items, set_items] = useState<formik_value_t>();
  const [coverage, set_coverage] = useState<coverage_t>();
  const [language] = useState(get_language().toLocaleLowerCase());

  /**
   * UseSelector
   */
  const quote = useSelector(select_quote);

  const agency: agency_product_t = useSelector(
    select_history_item("history_agency")
  );
  const products = useSelector(select_history_item("history_products"));

  const coverages: coverages_t = useSelector(
    select_history_item("history_coverages")
  );

  /**
   * UseEffect
   */
  useEffect(() => {
    const products_: formik_value_t = JSON.parse(products);
    const data_: quote_t = get_quote_data(
      products_ || {},
      agency,
      quote?.quoteId
    );
    dispatch(shoot.saga_quote(data_));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values) {
      set_items({ ...values });
      set_coverage(coverages[values?.coverage_type_radios || ""]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  /**
   * Render functions
   */
  const render_data_summary = () => {
    return (
      <div>
        {coverage && (
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 mb-3 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t("data_entry_step_3.coverage_type_title")}
              </div>
              <div className="font-14">{coverage?.name?.[language] || ""}</div>
            </div>
          </div>
        )}
        <div className="d-block d-md-flex mb-3 mt-3">
          <div className="col-12 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t("data_entry_step_3.insurance_title")}
            </div>
            {coverages?.[items?.coverage_type_radios || ""]?.types &&
              coverages?.[items?.coverage_type_radios || ""]?.types?.map(
                (item, index: number) => (
                  <div key={index + 1} className="font-14">
                    - {item?.[language]}
                  </div>
                )
              )}
          </div>
        </div>
        <div className="d-block d-md-flex">
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t("data_entry_step_3.email")}
            </div>
            <div className="font-14">{items?.insured_email?.toLowerCase()}</div>
          </div>
        </div>
      </div>
    );
  };

  const render_insured_data_summary = () => {
    return (
      <div className="col-12">
        <div>
          <div className="font-14-semibold text-uppercase mb-2">
            {t("data_entry_step_3.insured_title")} 1
          </div>
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 col-md-7 mb-3 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t("data_entry_step_3.insured_name")}
              </div>
              <div className="font-14">{items?.insured_name}</div>
            </div>
            <div className="col-5">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t("data_entry_step_3.insured_surname")}
              </div>
              <div className="font-14">{items?.insured_surname}</div>
            </div>
          </div>
          {items?.insured_fc_question === "no_id" && (
            <div>
              <div className="d-block d-md-flex mb-3">
                {items?.insured_birth && (
                  <div className="col-12 col-md-7 mb-4 mb-md-0">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t("data_entry_step_3.insured_birth")}
                    </div>
                    <div className="font-14">{items?.insured_birth}</div>
                  </div>
                )}
                {items?.insured_birth_nation && (
                  <div className="col-5">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t("data_entry_step_3.insured_birth_nation")}
                    </div>
                    <div className="font-14">{items?.insured_birth_nation}</div>
                  </div>
                )}
              </div>
              <div className="d-block d-md-flex mb-3">
                {items?.insured_birth_place && (
                  <div className="col-12 col-md-7 mb-4 mb-md-0">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t("data_entry_step_3.insured_birth_place")}
                    </div>
                    <div className="font-14">{items?.insured_birth_place}</div>
                  </div>
                )}

                {items?.insured_gender && (
                  <div className="col-12 col-md-7 mb-4 mb-md-0">
                    <div className="font-12-semibold text-uppercase c-7f7f7f">
                      {t("data_entry_step_3.insured_gender")}
                    </div>
                    <div className="font-14">
                      {items?.insured_gender === "male"
                        ? t("data_entry_step_3.insured_gender_male")
                        : items?.insured_gender === "female"
                        ? t("data_entry_step_3.insured_gender_female")
                        : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 col-md-7 mb-4 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t("data_entry_step_3.insured_phone")}
              </div>
              <div className="font-14">
                {items?.insured_phone?.formattedPhone ? items?.insured_phone?.formattedPhone : "-"}
              </div>
            </div>

            {items?.insured_fc && items?.insured_fc_question === "yes_id" && (
              <div className="col-12 col-md-7 mb-4 mb-md-0">
                <div className="font-12-semibold text-uppercase c-7f7f7f">
                  {t("data_entry_step_3.insured_fc")}
                </div>
                <div className="font-14">
                  {items?.insured_fc?.toUpperCase()}
                </div>
              </div>
            )}
          </div>
          <div className="d-block d-md-flex mb-3">
            <div className="col-12 col-md-7 mb-4 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t("data_entry_step_3.insured_cap")}
              </div>
              <div className="font-14">
                {items?.insured_cap ? items?.insured_cap : "-"}
              </div>
            </div>
          </div>
        </div>
        {items?.insured_list && items?.insured_list?.length !== 0 ? (
          <div className="mt-3 mb-3 border-top" />
        ) : (
          <></>
        )}
        {items?.insured_list?.map((_, id: number) => (
          <div key={id}>
            <div className="font-14-semibold text-uppercase mb-2">
              {t("data_entry_step_3.insured_title")} {id + 2}
            </div>
            <div className="d-block d-md-flex mb-3">
              {items?.insured_list?.[id]?.name && (
                <div className="col-12 col-md-7 mb-3 mb-md-0">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t("data_entry_step_3.insured_name")}
                  </div>
                  <div className="font-14">
                    {items?.insured_list?.[id]?.name}
                  </div>
                </div>
              )}
              {items?.insured_list?.[id]?.surname && (
                <div className="col-5">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t("data_entry_step_3.insured_surname")}
                  </div>
                  <div className="font-14">
                    {items?.insured_list?.[id]?.surname}
                  </div>
                </div>
              )}
            </div>
            {items?.insured_list?.[id]?.fc_question === "no_id" ? (
              <div>
                <div className="d-block d-md-flex mb-3">
                  {items?.insured_list?.[id]?.birth && (
                    <div className="col-12 col-md-7 mb-4 mb-md-0">
                      <div className="font-12-semibold text-uppercase c-7f7f7f">
                        {t("data_entry_step_3.insured_birth")}
                      </div>
                      <div className="font-14">
                        {items?.insured_list?.[id]?.birth}
                      </div>
                    </div>
                  )}
                  {items?.insured_list?.[id]?.birth_nation && (
                    <div className="col-5">
                      <div className="font-12-semibold text-uppercase c-7f7f7f">
                        {t("data_entry_step_3.insured_birth_nation")}
                      </div>
                      <div className="font-14">
                        {items?.insured_list?.[id]?.birth_nation}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-block d-md-flex mb-3">
                  {items?.insured_list?.[id]?.birth_place && (
                    <div className="col-12 col-md-7 mb-4 mb-md-0">
                      <div className="font-12-semibold text-uppercase c-7f7f7f">
                        {t("data_entry_step_3.insured_birth_place")}
                      </div>
                      <div className="font-14">
                        {items?.insured_list?.[id]?.birth_place}
                      </div>
                    </div>
                  )}

                  {items?.insured_list?.[id]?.gender && (
                    <div className="col-12 col-md-7 mb-4 mb-md-0">
                      <div className="font-12-semibold text-uppercase c-7f7f7f">
                        {t("data_entry_step_3.insured_gender")}
                      </div>
                      <div className="font-14">
                        {items?.insured_list?.[id]?.gender === "male"
                          ? t("data_entry_step_3.insured_gender_male")
                          : items?.insured_list?.[id]?.gender === "female"
                          ? t("data_entry_step_3.insured_gender_female")
                          : ""}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              items?.insured_list?.[id]?.fc_question === "yes_id" && (
                <div className="d-block d-md-flex mb-3">
                  {items?.insured_list?.[id]?.fc && (
                    <div className="col-12 col-md-7 mb-4 mb-md-0">
                      <div className="font-12-semibold text-uppercase c-7f7f7f">
                        {t("data_entry_step_3.insured_fc")}
                      </div>
                      <div className="font-14">
                        {items?.insured_list?.[id]?.fc?.toUpperCase()}
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
            {items?.insured_list && id < items?.insured_list?.length - 1 ? (
              <div className="mt-3 mb-3 border-top" />
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    );
  };

  const render_info_company = () => {
    return (
      <div>
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t("data_entry_step_3.product_label")}
            </div>
            <div className="font-14">
              {t("data_entry_step_3.product_value")}
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t("data_entry_step_3.company_title")}
            </div>
            <div className="font-14">{t("data_entry_step_3.company")}</div>
          </div>
        </div>
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t("data_entry_step_3.policy_label")}
            </div>
            <div className="font-14">{coverage?.policyCode || ""}</div>
          </div>
          <div className="col-12 col-md-5">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t("data_entry_step_3.contractor_label")}
            </div>
            <div className="font-14">
              {agency?.denomination
                ? agency?.denomination
                : t("data_entry_step_3.contractor_value")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const render_conditions = () => {
    return (
      <div>
        <div className="mb-4">
          <InputCheckbox
            name="informative_vision"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.informative_vision}
            value={values.informative_vision}
            error={
              errors?.informative_vision && touched?.informative_vision
                ? errors?.informative_vision
                : ""
            }
            label={t("data_entry_step_3.informative_vision")}
            classesLabel="m-0 font-15 d-flex align-items-baseline"
            classes="me-2"
          />
        </div>
        <InputCheckbox
          name="consent_td"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.consent_td}
          value={values.consent_td}
          error={
            errors?.consent_td && touched?.consent_td ? errors?.consent_td : ""
          }
          label={t("data_entry_step_3.consent_td_title")}
          classesLabel="m-0 font-15 d-flex align-items-baseline"
          classes="me-2"
        />
        <div className="pt-4 font-15-semibold">
          {t("commons.required_field")}
        </div>
      </div>
    );
  };

  return (
    <div className="mb-1 mb-md-5 pb-1 ps-md-3 pe-md-3">
      <ScrollToTop />
      <Recaptcha />
      <div className="border border-1 rounded-3 d-flex justify-content-center container-fluid bg-ffffff pt-4 pb-4">
        <div className="col-12 col-md-12 col-xxl-9">
          <div className="font-28-semibold text-center mb-2">
            {t("data_entry_step_3.title")}
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-lg-9 d-block">
              <div className="mb-4">{render_data_summary()}</div>
              <div className="mt-3 mb-3 border-top" />
              <div className="mb-4">{render_insured_data_summary()}</div>
              <div className="mt-3 mb-3 border-top" />
              <div className="mb-4">{render_info_company()}</div>
              <div className="mt-3 mb-3 border-top" />
              <div>{render_conditions()}</div>
              <div className="mt-3 mb-3 border-top" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataEntryCustomStep3;
