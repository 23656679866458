import "./header.scss";

import { useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  itasMutuaIcon,
  itasMutuaIconWhite,
  RightArrowIcon,
} from "src/assets";
import { MOCKED } from "src/config";
import { TO_GRUPPO_ITAS } from "src/constants";
import i18n from "src/i18n";
import { select_history_item } from "src/redux/store";
import { get_language } from "src/utils";
import { Button } from "src/widgets/button/button";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/navigation";
import { shoot } from "src/redux/actions";
import CanonicalMetaTag from "src/widgets/canonical-meta-tag/canonical-meta-tag";

export const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * UseState
   */
  const [current_language, set_current_language] = useState(
    get_language().toLowerCase()
  );

  /**
   * UseSelector
   */
  const languages: string[] = useSelector(
    select_history_item("history_languages")
  );
  const is_landing: boolean = useSelector(
    select_history_item("history_is_landing")
  );

  const is_faq: boolean = useSelector(select_history_item("history_is_faq"));

  /**
   * Functions
   */
  const on_change = (language: string) => {
    set_current_language(language);
    change_language(language);
  };

  const change_language = (language: string) => {
    i18n.changeLanguage(language);
    setTimeout(() => window.location.reload());
  };

  const toInsertPage = () => {
    navigate(Paths.DataEntry);
    dispatch(shoot.reduce_history_item("history_is_landing"));
  };

  /**
   * Render function
   */
  const render_buttons_language = () => {
    return (
      <div className="d-flex">
        {languages?.map((language: string, index: number) => {
          return (
            <div
              key={index}
              className={`header-child d-flex align-items-baseline ${
                is_landing ? "c-ffffff" : "c-862633"
              }`}
            >
              <div className="ps-2 pe-2">
                <Button
                  text={language}
                  classes="header-languages"
                  classesText={`text-uppercase font-14-ag-400  ${
                    is_landing ? "c-ffffff" : "c-862633"
                  } ${
                    current_language === language
                      ? "header-selected-language"
                      : "header-unselected-language"
                  }`}
                  onPress={() => on_change(language)}
                />
              </div>
              <div className="header-separator">|</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <header
      className={
        is_landing || is_faq ? "header-navbar-landing" : "header-navbar"
      }
    >
      <CanonicalMetaTag />
      <div className="header-logo">
        <a href={TO_GRUPPO_ITAS} target="_blank" rel="noreferrer">
          <Image
            src={is_landing ? itasMutuaIconWhite : itasMutuaIcon}
            width={108}
            height={40}
            alt={t("commons.logo_label")}
          />
        </a>
      </div>
      {MOCKED === "true" && (
        <div className="font-20-semibold c-ffffff">-- MOCK: ACTIVE -- </div>
      )}
      <div className="d-flex align-items-center">
        {render_buttons_language()}
        {(is_landing || is_faq) && (
          <Button
            text={t("commons.buy_now_button")}
            iconRight={RightArrowIcon}
            iconSize={18}
            classesIconRight={`${is_faq ? "ms-3 filter-ffffff" : "ms-3"}`}
            classes={`ms-4 p-2 ps-3 pe-3 d-none d-md-flex justify-content-center ${
              is_faq ? "bg-4145e5 c-ffffff" : ""
            }`}
            classesText="font-14-ag-500"
            onPress={toInsertPage}
          />
        )}
      </div>
    </header>
  );
};
