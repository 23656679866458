import { call, put } from 'redux-saga/effects'
import { http_payment } from 'src/http'
import { saga_act } from 'src/types'
import { payment_outcome_t } from 'src/types/http/payment.types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Get payment
 */
export function* saga_payment(action: saga_act): unknown {
  const payment: payment_outcome_t = yield call(
    saga_do_request,
    http_payment,
    action
  );
  if (payment) {
    yield put(shoot.reduce_payment(payment));
  }
}
