import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { select_history_item } from "src/redux/store";
import { Logo } from "src/types";
import "./section6.scss";
export const useSection6 = () => {
  const { t } = useTranslation();

  /**
   * useSelector
   */
  const logos: Logo[] = useSelector(
    select_history_item("history_logos")
  );

  /**
   * Functions
   */
  const contactUs = () => {
    window.open("mailto:" + t("footer.customer_service_email_value"));
  };

  return {
    t,
    logos,
    contactUs,
  };
};
