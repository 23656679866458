import { response_t, saga_act } from 'src/types'
import { registration_t } from 'src/types/http/Registration.types';

import { do_request } from './base.http'

/**
 * Registration
 */
export async function http_registration({
  args,
  type,
}: saga_act): Promise<response_t<registration_t>> {
  let value = await do_request<registration_t>({
    path: "/registration",
    args: args,
    saga_type: type,
  });

  return value;
}
