import "./footer.scss";

import { useEffect, useState } from "react";
import { FailureModal } from "react-component-library";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ERROR_406,
  MAPS_LINK,
  QUOTE_ERRORS,
  RESOURCE_NOT_FOUND_EC,
} from "src/constants";
import { Paths } from "src/navigation";
import { shoot } from "src/redux/actions";
import { select_error, select_history_item } from "src/redux/store";
import { AgencyCompleteAddress, agency_product_t, document_t } from "src/types";
import { capitalizeCase, get_document_by_id, get_language } from "src/utils";

export const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * UseSelector
   */
  const error = useSelector(select_error());
  const documents: document_t[] = useSelector(
    select_history_item("history_documents")
  );
  const agency: agency_product_t = useSelector(
    select_history_item("history_agency")
  );

  /**
   * UseState
   */
  const [language] = useState(get_language().toLocaleLowerCase());
  const [informative_web_file, set_informative_web_file] =
    useState<document_t>();
  const [claim_file, set_claim_file] = useState<document_t>();
  const [showChatBot, setShowChatBot] = useState(false);

  /**
   * UseEffect
   */
  useEffect(() => {
    dispatch(shoot.reduce_error());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agency?.isOriginalPayment === undefined || agency?.isOriginalPayment) {
      setShowChatBot(true);
    } else {
      setShowChatBot(false);

      setTimeout(() => {
        const scriptChatComponentPluginsId =
          document.getElementById("pluginloaderCssId");
        document
          .getElementById("chat-container")
          ?.style.setProperty("display", "none");
        document
          .getElementById("toolbar-container")
          ?.style.setProperty("display", "none");
        if (scriptChatComponentPluginsId) {
          scriptChatComponentPluginsId.remove();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency]);

  useEffect(() => {
    if (documents) {
      //Get Informative web
      const informative_web_file_ = get_document_by_id(documents, "7");
      if (informative_web_file_)
        set_informative_web_file(informative_web_file_);

      //Get Claim
      const claim_file_ = get_document_by_id(documents, "8");
      if (claim_file_) set_claim_file(claim_file_);
    }
  }, [documents]);

  useEffect(() => {
    if (error) {
      if (
        agency?.isOriginalPayment === undefined ||
        agency?.isOriginalPayment
      ) {
        dispatch(shoot.reduce_history_item("history_current_step", "0"));
      } else dispatch(shoot.reduce_history_item("history_current_step", "1"));
    }

    if (error?.code === RESOURCE_NOT_FOUND_EC) {
      navigate(Paths.Landing);
      dispatch(shoot.reduce_error());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * Render function
   */
  const render_chat_bot = () => (
    <Helmet>
      <script src="chatComponent/libs/jquery-1.12.4.min.js"></script>
      <script src="chatComponent/plugins/pluginloader.js"></script>
    </Helmet>
  );

  const render_error_modal = () => {
    return error?.code && error?.code === ERROR_406 ? (
      <FailureModal
        error={error}
        on_close={() => {
          navigate(Paths?.Landing);
          dispatch(shoot.reduce_error());
        }}
        classes_description="mt-4 font-16-semibold pre-line"
        classes_button="d-flex mt-4 mb-2 d-flex align-items-center justify-content-center"
        classes_button_text="font-14-semibold text-uppercase"
        button_text={t("commons.back_to_homepage")}
      />
    ) : (
      !QUOTE_ERRORS.includes(error?.code || "") && (
        <FailureModal
          error={error}
          on_close={() => {
            dispatch(shoot.reduce_error());
          }}
          classes_description="mt-4 font-16-semibold pre-line"
          classes_button="d-flex mt-4 mb-2 d-flex align-items-center justify-content-center"
          classes_button_text="font-14-semibold text-uppercase"
          button_text={t("commons.reload_page")}
        />
      )
    );
  };

  const render_customer_service = () => {
    return (
      <div>
        <div className="ml-auto font-12 c-ffffffbf text-uppercase">
          {t("footer.customer_service_title")}
        </div>

        <div className="footer-line mt-2 mb-3" />

        <div className="mt-2">
          <div className="d-flex d-md-block d-xl-flex">
            <div className="font-15 c-ffffffbf">
              {t("footer.customer_service_phone_label")}
            </div>
            <div
              className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
              onClick={() =>
                window.open("tel:" + t("footer.customer_service_phone_value"))
              }
            >
              {t("footer.customer_service_phone_value")}
            </div>
          </div>
          <div className="d-flex d-md-block d-xl-flex">
            <div className="font-15 c-ffffffbf">
              {t("footer.customer_service_email_label")}
            </div>
            <div
              className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
              onClick={() =>
                window.open(
                  "mailto:" + t("footer.customer_service_email_value")
                )
              }
            >
              {t("footer.customer_service_email_value")}
            </div>
          </div>

          <div className="font-15 c-ffffffbf white-space-pre mt-3">
            {t("footer.customer_service_info")}
          </div>
        </div>
      </div>
    );
  };

  const render_claims_report = () => {
    return (
      <div>
        <div className="ml-auto font-12 c-ffffffbf text-uppercase mt-4">
          {t("footer.claims_title")}
        </div>

        <div className="footer-line mt-2 mb-3" />

        <div className="mt-2">
          <div className="d-flex d-md-block d-xl-flex">
            <div className="font-15 c-ffffffbf">
              {t("footer.claims_email_label")}
            </div>
            <div
              className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
              onClick={() =>
                window.open("mailto:" + t("footer.claims_email_value"))
              }
            >
              {t("footer.claims_email_value")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const render_information_on_open_claims = () => {
    return (
      <div>
        <div className="ml-auto font-12 c-ffffffbf text-uppercase mt-4">
          {t("footer.open_claims_title")}
        </div>

        <div className="footer-line mt-2 mb-3" />

        <div className="mt-2">
          <div className="d-flex d-md-block d-xl-flex">
            <div className="font-15 c-ffffffbf">
              {t("footer.open_claims_phone_label")}
            </div>
            <div
              className="font-15 c-ffffffbf ms-2 ms-md-0 ms-xl-2 cursor-pointer"
              onClick={() =>
                window.open("tel:" + t("footer.open_claims_phone_value"))
              }
            >
              {t("footer.open_claims_phone_value")}
            </div>
          </div>

          <div className="font-15 c-ffffffbf">
            {t("footer.open_claims_date")}
          </div>
        </div>
      </div>
    );
  };

  const renderCustomAddress = (agencyAddress: AgencyCompleteAddress) => {
    if (!agencyAddress || Object?.keys(agencyAddress)?.length === 0) return "";

    const formattedAddress: string =
      capitalizeCase(agencyAddress?.address || "") +
      ", " +
      capitalizeCase(agencyAddress?.location || "") +
      ", " +
      capitalizeCase(agencyAddress?.province || "");

    return (
      <div
        className="font-15 c-ffffffbf ms-xl-2 cursor-pointer"
        onClick={() => window.open(MAPS_LINK + formattedAddress)}
      >
        {formattedAddress}
      </div>
    );
  };

  const render_agency_information = () => {
    return (
      <div className="mt-4 mt-md-0">
        <div className="ml-auto font-12 c-ffffffbf text-uppercase">
          {agency?.isCustomActive
            ? agency?.denomination
            : t("footer.srl_title")}
        </div>

        <div className="footer-line mt-2 mb-3" />

        <div className="font-15 c-ffffffbf white-space-pre">
          {t("footer.srl_label_1")}
          {agency?.isCustomActive
            ? agency?.ruiNumber
            : t("footer.srl_label_number")}
          {t("footer.srl_label_2")}
        </div>

        <div className="d-block d-md-flex">
          <div className="font-15 c-ffffffbf">
            {t("footer.srl_check_label")}
          </div>
          <div
            className="font-15 c-ffffffbf ms-md-2 cursor-pointer"
            onClick={() => window.open(t("footer.srl_check_value"))}
          >
            {t("footer.srl_check_value")}
          </div>
        </div>

        <div className="d-block d-xl-flex">
          <div className="font-15 c-ffffffbf">
            {t("footer.srl_registered_office_label")}
          </div>
          {agency?.isCustomActive ? (
            renderCustomAddress(agency?.registeredOfficeCompleteAddress || {})
          ) : (
            <div
              className="font-15 c-ffffffbf ms-xl-2 cursor-pointer"
              onClick={() =>
                window.open(MAPS_LINK + t("footer.srl_registered_office_value"))
              }
            >
              {t("footer.srl_registered_office_value")}
            </div>
          )}
        </div>

        <div className="d-block d-xl-flex">
          <div className="font-15 c-ffffffbf">
            {t("footer.srl_operational_office_label")}
          </div>
          {agency?.isCustomActive ? (
            renderCustomAddress(
              agency?.operationalHeadquartersCompleteAddress || {}
            )
          ) : (
            <div
              className="font-15 c-ffffffbf ms-xl-2 cursor-pointer"
              onClick={() =>
                window.open(
                  MAPS_LINK + t("footer.srl_operational_office_value")
                )
              }
            >
              {t("footer.srl_operational_office_value")}
            </div>
          )}
        </div>

        <div className="d-flex">
          <div className="font-15 c-ffffffbf">
            {t("footer.srl_phone_label")}
          </div>
          {agency?.isCustomActive && agency?.phoneNumber ? (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() => window.open("tel:" + agency?.phoneNumber)}
            >
              {agency?.phoneNumber}
            </div>
          ) : (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() => window.open("tel:" + t("footer.srl_phone_value"))}
            >
              {t("footer.srl_phone_value")}
            </div>
          )}
        </div>
        <div className="d-flex">
          <div className="font-15 c-ffffffbf">
            {t("footer.srl_email_label")}
          </div>

          {agency?.isCustomActive && agency?.mail ? (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() => window.open("mailto:" + agency?.mail)}
            >
              {agency?.mail}
            </div>
          ) : (
            <div
              className="font-15 c-ffffffbf ms-2 cursor-pointer"
              onClick={() =>
                window.open("mailto:" + t("footer.srl_email_value"))
              }
            >
              {t("footer.srl_email_value")}
            </div>
          )}
        </div>
        <div
          className={get_language() === "IT" ? "d-flex" : "d-block d-xl-flex"}
        >
          <div className="font-15 c-ffffffbf">{t("footer.srl_pec_label")}</div>

          {agency?.isCustomActive && agency?.pecMail ? (
            <div
              className={
                get_language() === "IT"
                  ? "font-15 c-ffffffbf ms-2 cursor-pointer"
                  : "font-15 c-ffffffbf cursor-pointer"
              }
              onClick={() => window.open("mailto:" + agency?.pecMail)}
            >
              {agency?.pecMail}
            </div>
          ) : (
            <div
              className={
                get_language() === "IT"
                  ? "font-15 c-ffffffbf ms-2 cursor-pointer"
                  : "font-15 c-ffffffbf cursor-pointer"
              }
              onClick={() => window.open("mailto:" + t("footer.srl_pec_value"))}
            >
              {t("footer.srl_pec_value")}
            </div>
          )}
        </div>
      </div>
    );
  };

  const render_bottom_footer = () => {
    return (
      <div className="bg-000000 py-4 px-3 py-md-4 px-md-5">
        <div className="d-block white-space-pre">
          <div
            className="font-15 c-ffffff cursor-pointer display-contents"
            onClick={() => {
              dispatch(
                shoot.reduce_history_item("history_cookie_loaded", false)
              )
              navigate(Paths.CookiePolicy);
            }}
          >
            {t("footer.privacy_1")}
          </div>
          <div
            className="font-15 c-ffffff cursor-pointer display-contents"
            onClick={() =>
              informative_web_file?.file &&
              window.open(informative_web_file?.file[language], "_blank")
            }
          >
            {t("footer.privacy_2")}
          </div>
          <div
            className="font-15 c-ffffff cursor-pointer display-contents"
            onClick={() =>
              claim_file?.file &&
              window.open(claim_file?.file[language], "_blank")
            }
          >
            {t("footer.privacy_3")}
          </div>
          <div
            className="font-15 c-ffffff cursor-pointer display-contents"
            onClick={() => navigate(Paths.Accessibility)}
          >
            {t("footer.privacy_4")}
          </div>
        </div>

        <div className="font-13 c-ffffffbf mt-2">
          {t("footer.privacy_subtitle")}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="footer">
        {showChatBot && render_chat_bot()}
        {render_error_modal()}
        <div className="bg-000000E6 d-md-flex justify-content-md-between py-4 px-3 py-md-4 px-md-5">
          <div className="d-lg-flex">
            <div>
              {render_customer_service()}
              {render_claims_report()}
              {render_information_on_open_claims()}
            </div>
          </div>
          {render_agency_information()}
        </div>
        {render_bottom_footer()}
      </div>
    </>
  );
};
