import Amplify, { API } from 'aws-amplify'
import axios from 'axios'
import awsExports from 'src/aws-exports'
import { MOCKED, WEBAPP_API_NAME } from 'src/config'
import { response_t } from 'src/types'

Amplify.configure(awsExports);

interface request_args {
  path?: string;
  args?: any;
  saga_type?: string;
}

export async function do_request<T>({
  path,
  args,
}: request_args): Promise<response_t<T>> {
  const mock_src = path
    ?.slice(1)
    ?.replaceAll("/", "-")
    .replaceAll("?", "__")
    .replaceAll("=", "")
    .replaceAll("&", "-");
  const mock_url = `mocks/${mock_src}.json`;

  if (MOCKED === "true") {
    let mock_req;
    if (args?.family && path === "/quote") {
      mock_req = axios.get("mocks/quote-family.json");
    } else if (args?.discount && path === "/quote"){
      mock_req = axios.get("mocks/quote-discount.json");
    } else if (args === "C000") {
      mock_req = axios.get("mocks/error.json");
    } else if (args === "F000") {
      mock_req = axios.get("mocks/offline-product.json");
    } else {
      mock_req = axios.get(mock_url);
    }
    console.log("MOCK REQUEST:\nMOCK-PATH: ", mock_url, "\nMOCK-ARGS: ", args);
    return (await mock_req).data;
  }

  let req;
  if (path === "/quote") {
    req = await API.post(WEBAPP_API_NAME || "", path, { body: { ...args } });
  } else if (path === "/product") {
    req = await API.get(WEBAPP_API_NAME || "", path + "/" + args, {});
  } else if (path === "/payment") {
    req = await API.post(WEBAPP_API_NAME || "", path, { body: { ...args } });
  } else if (path === "/registration") {
    req = await API.post(WEBAPP_API_NAME || "", path, { body: { ...args } });
  }else if (path === "/evaluate-premium") {
    req = await API.post(WEBAPP_API_NAME || "", path, { body: { ...args } });
  } else if (path === "/certificate") {
    const myInit = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    req = await API.post(WEBAPP_API_NAME || "", path + "/" + args, myInit);
  } else if (path === "/cookie") {
    const cookie_template_start = await axios.get(args?.start, {
      method: "GET",
    });
    const cookie_template_end = await axios.get(args?.end, {
      method: "GET",
    });
    req = {
      start: cookie_template_start?.data,
      end: cookie_template_end?.data,
    };
  }

  return req;
}
