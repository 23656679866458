import { response_t, saga_act } from 'src/types'
import { quote_t } from 'src/types/http/quote.types'

import { do_request } from './base.http'

/**
 * Quote
 */
export async function http_quote({
  args,
  type,
}: saga_act): Promise<response_t<quote_t>> {
  let value = await do_request<quote_t>({
    path: "/quote",
    args: args,
    saga_type: type,
  });

  return value;
}
