import { useSection5 } from "./section5.hook";

export const Section5 = () => {
  const { t } = useSection5();

  const Title = (
    <h2 className="mb-5 ps-1 pe-1 section5-dynamic-size-title d-flex align-item-center justify-content-center text-uppercase">
      {t("landing.section_5_title")}
    </h2>
  );

  const Subtitle = (
    <div className="mb-5 section5-dynamic-size-subtitle d-flex align-items-center justify-content-center white-space-pre-subtitle text-center">
      {t("landing.section_5_subtitle")}
    </div>
  );

  return (
    <div>
      {Title}
      {Subtitle}
    </div>
  );
};
