import { response_t, saga_act } from 'src/types'
import { certificate_t } from 'src/types/http/certificate.types'

import { do_request } from './base.http'

/**
 * Certificate
 */
export async function http_certificate({
  args,
  type,
}: saga_act): Promise<response_t<certificate_t>> {
  let value = await do_request<certificate_t>({
    path: "/certificate",
    args: args,
    saga_type: type,
  });

  return value;
}
