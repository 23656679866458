import { Helmet } from "react-helmet";
import { useLanding } from "./landing.hook";
import "./landing.scss";
import { Section1 } from "./sections/section1/section1";
import { Section2 } from "./sections/section2/section2";
import { Section3 } from "./sections/section3/section3";
import { Section4 } from "./sections/section4/section4";
import { Section5 } from "./sections/section5/section5";
import { Section6 } from "./sections/section6/section6";
import { Section7 } from "./sections/section7/section7";
import { Section8 } from "./sections/section8/section8";

export const Landing = () => {
  const { t } = useLanding();

  return (
    <div className="landing-bg">
      <Helmet>
        <title>{t("meta.landing.title")}</title>
        <meta name="description" content={t("meta.landing.description")} />
      </Helmet>
      <div className="landing-over-position">
        <Section1 />
      </div>
      <div className="pb-4 ps-4 pe-4 margin-from-carousel">
        <Section2 />
      </div>
      <div className="mt-md-5 pt-5">
        <Section3 />
      </div>
      <div className="section4-bg">
        <Section4 />
      </div>
      <div className="section5-bg">
        <Section5 />
      </div>
      <div className="section6-bg">
        <Section6 />
      </div>
      <div className="section7-bg">
        <Section7 />
      </div>
      <div className="section8-bg">
        <Section8 />
      </div>
    </div>
  );
};
