import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Loader } from "react-component-library";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SAGA_GUARDS } from "src/navigation";
import { shoot } from "src/redux/actions";
import {
  select_cookie_templates,
  select_history_item,
  select_loading,
  select_product,
} from "src/redux/store";
import { get_language } from "src/utils";

export const CookiePolicy = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [language] = useState(get_language().toLocaleLowerCase());

  /**
   * UseSelector
   */
  const cookie_templates = useSelector(select_cookie_templates);
  const loading = useSelector(select_loading(SAGA_GUARDS.COOKIE_TEMPLATES));
  const product = useSelector(select_product);
  const isReloaded: boolean = useSelector(
    select_history_item("history_cookie_loaded")
  );

  useEffect(() => {
    if (product?.configuration?.cookieTemplates) {
      const cookie_templates_link_ = {
        start: product?.configuration?.cookieTemplates?.startTemplate[language],
        end: product?.configuration?.cookieTemplates?.endTemplate[language],
      };
      dispatch(shoot.saga_cookie_templates(cookie_templates_link_));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * UseEffect
   */
  useEffect(() => {
    dispatch(shoot.reduce_history_item("history_is_landing", false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   /**
   * UseEffect
   */
   useEffect(() => {
    console.log("var", isReloaded)
    if (!isReloaded) {
      dispatch(shoot.reduce_history_item("history_cookie_loaded", true));
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }
  }, [dispatch, isReloaded])

  return (
    <div>
      <Helmet>
        <title>{t("meta.cookie.title")}</title>
      </Helmet>
      {loading ? (
        <Loader overlay={true} />
      ) : (
        <Container fluid>
          {/* OneTrust Cookies List start  */}
          <div
            className="mt-5"
            dangerouslySetInnerHTML={{ __html: cookie_templates?.start || "" }}
          />
          <div id="ot-sdk-cookie-policy" />
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            {t("commons.cookie_label")}
          </button>
          <div
            className="mt-5 mb-5"
            dangerouslySetInnerHTML={{ __html: cookie_templates?.end || "" }}
          />
          {/* OneTrust Cookies List end  */}
        </Container>
      )}
    </div>
  );
};
