import { Image } from "react-component-library";
import { RightArrowIcon } from "src/assets";
import { Button } from "src/widgets/button/button";
import { useSection6 } from "./section6.hook";

export const Section6 = () => {
  const { t, logos, contactUs } = useSection6();

  const PreTitle = (
    <div className="mb-5 d-flex align-items-center justify-content-center">
      <div className="p-2 section6-dynamic-size-pretitle text-uppercase">
        {t("landing.section_6_pre_title")}
      </div>
    </div>
  );

  const Title = (
    <h2 className="mb-4 mb-md-5 ps-1 pe-1 section6-dynamic-size-title d-flex align-item-center justify-content-center text-uppercase">
      <div className="d-inline-block text-center">
        <div className="display-contents">{t("landing.section_6_title_1")}</div>
        <div className="ms-2 c-4145e5 display-contents">
          {t("landing.section_6_title_2")}
        </div>
      </div>
    </h2>
  );

  const Subtitle = (
    <div className="mb-5 section6-dynamic-size-subtitle d-flex align-items-center justify-content-center white-space-pre-subtitle text-center">
      {t("landing.section_6_subtitle")}
    </div>
  );

  const ButtonForm = (
    <div className="mb-5 d-flex align-items-center justify-content-center">
      <Button
        text={t("landing.section_6_contact_button")}
        iconRight={RightArrowIcon}
        classesIconRight="ms-3 filter-ffffff"
        iconSize={18}
        classes="p-2 ps-3 pe-3 d-flex justify-content-center bg-4145e5"
        classesText="font-14-ag-500 c-ffffff"
        onPress={contactUs}
      />
    </div>
  );

  const Partners = (
    <div>
      <div className="mb-5 section6-dynamic-size-partners-title d-flex align-items-center justify-content-center">
        {t("landing.section_6_partners_title")}
      </div>
      <div className="d-flex align-items-center justify-content-md-around">
        <div
          className={`${
            logos?.length <= 3 ? "d-flex" : "d-md-flex"
          } ps-4 pe-4 align-items-center justify-content-center col-12 col-md-6`}
        >
          {logos?.length > 0 &&
            logos.map((logo) => (
              <div
                key={logo?.id}
                className={`${
                  logos?.length <= 3 ? "col-4 " : "col-md-4"
                } d-flex align-items-center justify-content-center cursor-pointer`}
                onClick={() => window.open(logo?.logoUrl, "_blank")}
              >
                <Image
                  src={logo?.logoDesktop}
                  width="auto"
                  height="100%"
                  className="d-none d-md-block"
                />
                <Image
                  src={logo?.logoMobile}
                  width="auto"
                  height="100%"
                  className="d-block d-md-none m-2"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {PreTitle}
      {Title}
      {Subtitle}
      {ButtonForm}
      {Partners}
    </div>
  );
};
