import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { shoot } from "src/redux/actions";

export const useLanding = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /**
   * UseEffect
   */
  useEffect(() => {
    dispatch(shoot.reduce_payment());
    dispatch(shoot.reduce_certificate());
    dispatch(shoot.reduce_quote());
    dispatch(shoot.reduce_history_item("history_is_landing", true));
    dispatch(shoot.reduce_history_item("history_is_faq", false));
    dispatch(shoot.reduce_history_item("history_products"));
    dispatch(shoot.reduce_history_item("history_current_step", "0"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { t };
};
