import { createReducer } from 'reduxsauce'
import { errors_types } from 'src/redux/actions/errors.actions'
import { errors_store } from 'src/types'

export const reduce_error = (
  state: errors_store,
  { error }: errors_store
): errors_store => {
  if (error) {
    return {
      ...state,
      [error?.saga_type || ""]: error,
    };
  }
  return {};
};

export const remove_error = (
  state: errors_store,
  { error }: errors_store
): errors_store => {
  if (error?.saga_type && state?.[error?.saga_type]) {
    const errors = { ...state };
    delete errors[error?.saga_type];
    return { ...errors };
  }
  return { ...state };
};

export default createReducer(
  {},
  {
    [errors_types.REDUCE_ERROR]: reduce_error,
    [errors_types.REMOVE_ERROR]: remove_error,
  }
);
