import { put } from 'redux-saga/effects'

import { shoot } from '../actions'

/**
 * Startup
 */
export function* saga_startup(): unknown {
  yield put(shoot.reduce_started(true));
}

export function* saga_invalidate_cache(): unknown {
  yield put(shoot.reduce_certificate());
  yield put(shoot.reduce_payment());
  yield put(shoot.reduce_evaluate_premium());
  yield put(shoot.reduce_registration());
  yield put(shoot.reduce_product());
  yield put(shoot.reduce_quote());
}

export function* saga_invalidate_cache_history(): unknown {
  yield put(shoot.reduce_history_item("history_agency"));
  yield put(shoot.reduce_history_item("history_languages"));
  yield put(shoot.reduce_history_item("history_documents"));
  yield put(shoot.reduce_history_item("history_domains"));
  yield put(shoot.reduce_history_item("history_sections"));
  yield put(shoot.reduce_history_item("history_contacts"));
  yield put(shoot.reduce_history_item("history_factors"));
  yield put(shoot.reduce_history_item("history_periods"));
}
