import { Image } from "react-component-library";
import {
  CrossIcon,
  RightArrowIcon,
  StarOffIcon,
  StarOnIcon,
  TickIcon,
} from "src/assets";
import { Button } from "src/widgets/button/button";
import { useSection2 } from "./section2.hook";

export const Section2 = () => {
  const {
    t,
    PACKAGE_BASE_PRICE,
    PACKAGE_PLUS_PRICE,
    selectedPeriod,
    selectedPackage,
    setSelectedPeriod,
    setSelectedPackage,
    nextPage,
    openInformationSetFile,
  } = useSection2();

  const Title = (
    <h2 className="mb-4 mb-md-5 ps-1 pe-1 section2-dynamic-size-title d-flex align-item-center justify-content-center text-uppercase">
      <div className="d-inline-block text-center">
        <div className="display-contents">{t("landing.section_2_title_1")}</div>
        <div className="ms-2 c-4145e5 display-contents">
          {t("landing.section_2_title_2")}
        </div>
        <div className="display-contents">{t("landing.section_2_title_3")}</div>
      </div>
    </h2>
  );

  const Subtitle = (
    <div className="mb-5 section2-dynamic-size-subtitle d-flex align-items-center justify-content-center text-center">
      {t("landing.section_2_subtitle")}
    </div>
  );

  const PackageTitle = (
    <div className="mb-3 d-block d-md-none font-16-ag-400">
      {t("landing.section_2_package_title")}
    </div>
  );

  const Periods = (
    <div className="pb-4">
      <div>{PackageTitle}</div>
      <div className="mb-5 d-md-flex align-items-center justify-content-center">
        <div className="periods-border d-md-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pe-3 pe-md-0 pb-4 pb-md-0 col-6 col-md-auto">
              <div
                className={`ms-md-3 me-md-3 ps-md-3 pe-md-3 pt-3 pb-3 col-md-auto d-flex align-items-center justify-content-center font-16-ag-500 cursor-pointer ${
                  selectedPeriod === "id_daily"
                    ? "selected-period-border"
                    : "unselected-period-border"
                }`}
                onClick={() => setSelectedPeriod("id_daily")}
              >
                {t("landing.section_2_period_daily")}
              </div>
            </div>
            <div className="ps-3 pe-md-0 pb-4 pb-md-0 col-6 col-md-auto">
              <div
                className={`ms-md-3 me-md-3 ps-md-3 pe-md-3 pt-3 pb-3 col-md-auto d-flex align-items-center justify-content-center font-16-ag-500 cursor-pointer ${
                  selectedPeriod === "id_weekly"
                    ? "selected-period-border"
                    : "unselected-period-border"
                }`}
                onClick={() => setSelectedPeriod("id_weekly")}
              >
                {t("landing.section_2_period_weekly")}
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="pe-3 pe-md-0 pb-4 pb-md-0 col-6 col-md-auto">
              <div
                className={`ms-md-3 me-md-3 ps-md-3 pe-md-3 pt-3 pb-3 col-md-auto d-flex align-items-center justify-content-center font-16-ag-500 d-flex align-items-center cursor-pointer ${
                  selectedPeriod === "id_monthly"
                    ? "selected-period-border"
                    : "unselected-period-border"
                }`}
                onClick={() => setSelectedPeriod("id_monthly")}
              >
                <div>
                  <Image
                    src={
                      selectedPeriod === "id_monthly" ? StarOnIcon : StarOffIcon
                    }
                    width={14}
                    height={14}
                    className="align-text-top"
                  />
                </div>
                <div className="ms-2">
                  {t("landing.section_2_period_monthly")}
                </div>
              </div>
            </div>
            <div className="ps-3 pe-md-0 pb-4 pb-md-0 col-6 col-md-auto">
              <div
                className={`ms-md-3 me-md-3 ps-md-3 pe-md-3 pt-3 pb-3 col-md-auto d-flex align-items-center justify-content-center font-16-ag-500 d-flex align-items-center cursor-pointer ${
                  selectedPeriod === "id_quarterly"
                    ? "selected-period-border"
                    : "unselected-period-border"
                }`}
                onClick={() => setSelectedPeriod("id_quarterly")}
              >
                <div>
                  <Image
                    src={
                      selectedPeriod === "id_quarterly"
                        ? StarOnIcon
                        : StarOffIcon
                    }
                    width={14}
                    height={14}
                    className="align-text-top"
                  />
                </div>
                <div className="ms-2">
                  {t("landing.section_2_period_quarterly")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const GuaranteesCard = (
    <div className="pt-4">
      <div className="font-24-age-500 section2-space-0">
        <div className="mb-4 ps-4 pe-4 font-24-age-500 white-space-pre">
          {t("landing.section_2_guarantees_title")}
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center section2-space-1">
        <div>
          <div className="pb-2 font-20-ag-400">
            {t("landing.section_2_civil_liability_title")}
          </div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_civil_liability_info_1")}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_civil_liability_info_2")}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center section2-space-2">
        <div>
          <div className="pb-2 font-20-ag-400">
            {t("landing.section_2_injury_title")}
          </div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_injury_info_1")}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_injury_info_2")}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center section2-space-3">
        <div>
          <div className="pb-2 font-20-ag-400">
            {t("landing.section_2_reimbursement_title")}
          </div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_reimbursement_info_1")}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_reimbursement_info_2")}
              </li>
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_reimbursement_info_3")}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center section2-space-4">
        <div>
          <div className="pb-2 font-20-ag-400">
            {t("landing.section_2_legal_protection_title")}
          </div>
          <div>
            <ul className="m-0 p-0 ps-4 pe-3">
              <li className="font-14-ag-400 label-opacity-grey">
                {t("landing.section_2_legal_protection_info_1")}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center section2-space-5">
        <div>
          <div className="pb-2 font-20-ag-400">
            {t("landing.section_2_discount_title")}
          </div>
        </div>
      </div>
    </div>
  );

  const BaseCardDesktop = (
    <div className="pt-4">
      <div className="font-24-age-500 section2-space-0">
        <div className="mb-4 ps-4 pe-4 font-24-age-500">
          {t("landing.section_2_base_title")}
        </div>
        <div className="mb-4 ps-4 pe-4 font-36-age-700">
          {PACKAGE_BASE_PRICE[selectedPeriod]}
        </div>
        <div className="mb-4 ps-4 pe-4">
          <Button
            text={t("commons.active_now_button")}
            iconRight={RightArrowIcon}
            iconSize={18}
            classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-4145e5 c-ffffff"
            classesText="font-14-ag-500"
            classesIconRight="filter-ffffff"
            onPress={nextPage}
          />
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-1">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-2">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-3">
        <Image src={CrossIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-4">
        <Image src={CrossIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-5">
        <Image src={TickIcon} width={20} height={20} />
      </div>
    </div>
  );

  const PlusCardDesktop = (
    <div className="pt-4">
      <div className="font-24-age-500 section2-space-0">
        <div className="mb-4 ps-4 pe-4 font-24-age-500">
          {t("landing.section_2_plus_title")}
        </div>
        <div className="mb-4 ps-4 pe-4 font-36-age-700">
          {PACKAGE_PLUS_PRICE[selectedPeriod]}
        </div>
        <div className="mb-4 ps-4 pe-4">
          <Button
            text={t("commons.active_now_button")}
            iconRight={RightArrowIcon}
            iconSize={18}
            classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-4145e5 c-ffffff"
            classesText="font-14-ag-500"
            classesIconRight="filter-ffffff"
            onPress={nextPage}
          />
        </div>
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-1">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-2">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-3">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-4">
        <Image src={TickIcon} width={20} height={20} />
      </div>

      <div className="ps-4 pe-4 d-flex align-items-center justify-content-center section2-space-5">
        <Image src={TickIcon} width={20} height={20} />
      </div>
    </div>
  );

  const PackagesDesktop = (
    <div className="d-none d-md-block">
      <div className="ms-md-4 me-md-4 mb-4 d-flex align-items-center justify-content-center">
        <div className="col-md-12 row gx-4 m-0 p-0">
          <div className="col-md-4">
            <div className="section2-card">{GuaranteesCard}</div>
          </div>
          <div className="col-md-4" onClick={() => setSelectedPackage("base")}>
            <div
              className={`cursor-pointer ${
                selectedPackage === "base"
                  ? "selected-package"
                  : "section2-card"
              }`}
            >
              {BaseCardDesktop}
            </div>
          </div>
          <div className="col-md-4" onClick={() => setSelectedPackage("plus")}>
            <div
              className={`cursor-pointer ${
                selectedPackage === "plus"
                  ? "selected-package"
                  : "section2-card"
              }`}
            >
              {PlusCardDesktop}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const BaseCardMobile = (
    <div className="pt-4 pb-4">
      <div className="ps-4 pe-4 font-20-age-500">
        {t("landing.section_2_base_title")}
      </div>
      <div className="ps-4 pe-4 font-24-age-700">
        {PACKAGE_BASE_PRICE[selectedPeriod]}
      </div>
      <div className="mt-3 ps-4 pe-4">
        <Button
          text={t("commons.active_now_button")}
          iconRight={RightArrowIcon}
          iconSize={18}
          classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-4145e5 c-ffffff"
          classesText="font-14-ag-500"
          classesIconRight="filter-ffffff"
          onPress={nextPage}
        />
      </div>

      <div className="pt-5 pb-2 ps-4 pe-4">
        <div className="font-18-ag-400 d-flex align-items-center">
          <Image src={TickIcon} width={20} height={20} />
          <div className="ms-2">
            {t("landing.section_2_civil_liability_title")}
          </div>
        </div>
        <div>
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_civil_liability_info_1")}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_civil_liability_info_2")}
            </li>
          </ul>
        </div>
      </div>

      <div className="pb-2 ps-4 pe-4">
        <div className="font-18-ag-400 d-flex align-items-center">
          <Image src={TickIcon} width={20} height={20} />
          <div className="ms-2">{t("landing.section_2_injury_title")}</div>
        </div>
        <div>
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_injury_info_1")}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_injury_info_2")}
            </li>
          </ul>
        </div>
      </div>

      <div className="ps-4 pe-4 font-18-ag-400 d-flex align-items-start">
        <Image src={TickIcon} width={20} height={20} />
        <div className="ms-2"> {t("landing.section_2_discount_title")}</div>
      </div>
    </div>
  );

  const PlusCardMobile = (
    <div className="pt-4 pb-4">
      <div className="ps-4 pe-4 font-20-age-500">
        {t("landing.section_2_plus_title")}
      </div>
      <div className="ps-4 pe-4 font-24-age-700">
        {PACKAGE_PLUS_PRICE[selectedPeriod]}
      </div>
      <div className="mt-3 ps-4 pe-4">
        <Button
          text={t("commons.active_now_button")}
          iconRight={RightArrowIcon}
          iconSize={18}
          classes="p-2 ps-3 pe-3 w-100 d-flex align-items-center justify-content-between bg-4145e5 c-ffffff"
          classesText="font-14-ag-500"
          classesIconRight="filter-ffffff"
          onPress={nextPage}
        />
      </div>

      <div className="pt-5 pb-2 ps-4 pe-4">
        <div className="font-18-ag-400 d-flex align-items-center">
          <Image src={TickIcon} width={20} height={20} />
          <div className="ms-2">
            {t("landing.section_2_civil_liability_title")}
          </div>
        </div>
        <div>
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_civil_liability_info_1")}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_civil_liability_info_2")}
            </li>
          </ul>
        </div>
      </div>

      <div className="pb-2 ps-4 pe-4">
        <div className="font-18-ag-400 d-flex align-items-center">
          <Image src={TickIcon} width={20} height={20} />
          <div className="ms-2">{t("landing.section_2_injury_title")}</div>
        </div>
        <div>
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_injury_info_1")}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_injury_info_2")}
            </li>
          </ul>
        </div>
      </div>

      <div className="pb-2 ps-4 pe-4">
        <div className="font-18-ag-400 d-flex align-items-center">
          <Image src={TickIcon} width={20} height={20} />
          <div className="ms-2">
            {t("landing.section_2_reimbursement_title")}
          </div>
        </div>
        <div>
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_reimbursement_info_1")}
            </li>
            <li className="font-14-ag-400 label-opacity-grey white-space-pre">
              {t("landing.section_2_reimbursement_info_2")}
            </li>
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_reimbursement_info_3")}
            </li>
          </ul>
        </div>
      </div>

      <div className="pb-2 ps-4 pe-4">
        <div className="font-18-ag-400 d-flex align-items-center">
          <Image src={TickIcon} width={20} height={20} />
          <div className="ms-2">
            {t("landing.section_2_legal_protection_title")}
          </div>
        </div>
        <div>
          <ul className="pt-2 ps-4 pe-3">
            <li className="font-14-ag-400 label-opacity-grey">
              {t("landing.section_2_legal_protection_info_1")}
            </li>
          </ul>
        </div>
      </div>

      <div className="ps-4 pe-4 font-18-ag-400 d-flex align-items-start">
        <Image src={TickIcon} width={20} height={20} />
        <div className="ms-2"> {t("landing.section_2_discount_title")}</div>
      </div>
    </div>
  );

  const PackagesMobile = (
    <div className="d-block d-md-none">
      <div className="ms-md-4 me-md-4 mb-4 d-flex align-items-center">
        <div className="col-md-12 row gx-4">
          <div className="col-md-4" onClick={() => setSelectedPackage("base")}>
            <div
              className={`cursor-pointer ${
                selectedPackage === "base"
                  ? "selected-package"
                  : "section2-card"
              }`}
            >
              {BaseCardMobile}
            </div>
          </div>
          <div
            className="mt-4 col-md-4"
            onClick={() => setSelectedPackage("plus")}
          >
            <div
              className={`cursor-pointer ${
                selectedPackage === "plus"
                  ? "selected-package"
                  : "section2-card"
              }`}
            >
              {PlusCardMobile}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const InformationSet = (
    <div className="ms-md-4 me-md-4 mt-3 ps-md-3 label-opacity-grey d-inline-block">
      <div className="font-14-ag-500 display-contents">
        {t("landing.section_2_information_set_label_1")}
      </div>
      <Button
        text={t("landing.section_2_information_set_link")}
        classes="py-md-2 ms-1 me-1 display-contents"
        classesText="font-14-ag-500 d-inline text-underline-grey"
        flavor="transparentAccent"
        onPress={openInformationSetFile}
      />
      <div className="font-14-ag-500 display-contents">
        {t("landing.section_2_information_set_label_2")}
      </div>
    </div>
  );

  return (
    <div>
      {Title}
      {Subtitle}
      {Periods}
      {PackagesDesktop}
      {PackagesMobile}
      {InformationSet}
    </div>
  );
};
