import { error_t, opt, redux_store } from 'src/types'

export const select_error =
  (saga_types?: string[]) =>
  (state: redux_store): opt<error_t> => {
    for (const s_type of saga_types || Object.keys(state?.errors)) {
      if (state?.errors[s_type]) {
        return state?.errors[s_type];
      }
    }
    return undefined;
  };
