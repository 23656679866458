import { useEffect, useState } from "react";
import { Button, Loader } from "react-component-library";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SAGA_GUARDS } from "src/navigation";
import { shoot } from "src/redux/actions";
import {
  select_certificate,
  select_history_item,
  select_loading,
} from "src/redux/store";
import { agency_product_t } from "src/types";
import { certificate_t } from "src/types/http/certificate.types";
import { Recaptcha, submit_captcha } from "src/widgets/recaptcha/recaptcha";

interface react_native_window extends Window {
  ReactNativeWebView?: {
    postMessage?: (message: string) => void;
  };
}

export const PaymentOutcomeOk = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(select_loading(SAGA_GUARDS.CERTIFICATE));

  const contract_id = new URLSearchParams(useLocation().search)
    .get("contractId")
    ?.toString();

  const security_hash = new URLSearchParams(useLocation().search)
    .get("securityHash")
    ?.toString();

  const email = new URLSearchParams(useLocation().search)
    .get("email")
    ?.toString();

  const isRegistration = new URLSearchParams(useLocation().search)
    .get("isRegistration")
    ?.toString();

  const agency: agency_product_t = useSelector(
    select_history_item("history_agency")
  );

  /**
   * UseState
   */
  const [fake_loading, set_fake_loading] = useState(false);
  const [show_loader, set_show_loader] = useState("");
  const [captcha, set_captcha] = useState("");
  const [download, set_download] = useState("");

  /**
   * UseSelector
   */
  const certificate = useSelector(select_certificate);
  const from_app_download = useSelector(select_history_item("FROM_APP"));
  const ecommerce = useSelector(select_history_item("history_ecommerce"));

  /**
   * UseEffect
   */
  useEffect(() => {
    if (
      agency?.isOriginalPayment !== undefined &&
      agency?.isOriginalPayment === false
    )
      dispatch(
        shoot.reduce_history_item("history_current_step", (1).toString())
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const googleWindow: any = window;
    googleWindow?.gtag?.("event", "conversion", {
      send_to: "AW-1020646686/gicXCNei5K8DEJ6q1-YD",
      value: 1.0,
      currency: "EUR",
      transaction_id: "",
    });
    googleWindow.dataLayer = googleWindow?.dataLayer || [];
    googleWindow.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    googleWindow.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: contract_id,
        ...ecommerce,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (captcha && contract_id && security_hash) {
      set_fake_loading(true);
      const certificate_: certificate_t = {
        contractId: contract_id,
        securityHash: security_hash,
      };

      setTimeout(() => {
        dispatch(
          shoot.saga_certificate(
            certificate_?.contractId +
              "?securityHash=" +
              certificate_?.securityHash
          )
        );
        set_fake_loading(false);
        set_download("start");
      }, 7000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha, contract_id, security_hash]);

  useEffect(() => {
    if (!loading) {
      set_show_loader("first_step");
    } else {
      set_show_loader("second_step");
    }

    if (show_loader === "first_step") {
      if (fake_loading) {
        set_show_loader("show");
      } else {
        set_show_loader("");
      }
    }

    if (show_loader === "second_step") {
      if (loading) {
        set_show_loader("show");
      } else {
        set_show_loader("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fake_loading, loading]);

  const base64Arraybuffer = async (data: Uint8Array) => {
    const base64url: any = await new Promise((r) => {
      const reader = new FileReader();
      reader.onload = () => r(reader.result);
      reader.readAsDataURL(new Blob([data]));
    });
    return base64url?.split(",", 2)[1];
  };

  useEffect(() => {
    if (download === "start" && certificate && certificate?.data) {
      if (from_app_download) {
        const rn_window: react_native_window = window;
        base64Arraybuffer(new Uint8Array(certificate?.data)).then((base64) => {
          rn_window?.ReactNativeWebView?.postMessage?.("PDF_BASE64:" + base64);
        });
      } else {
        const arr = new Uint8Array(certificate?.data);
        var data = new Blob([arr], { type: "application/pdf" });
        var url = window.URL.createObjectURL(data);
        var a = document.createElement("a");
        a.href = url;
        a.download = t("commons.pdf_title");
        a.click();
        a.remove();
      }

      set_download("");
      dispatch(shoot.reduce_certificate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificate, download, from_app_download]);

  /**
   * Functions
   */
  const get_recaptcha = async () => {
    set_captcha("");
    await submit_captcha().then((token: string) => {
      set_captcha(token);
    });
  };

  return (
    <div className="font-27 mx-auto my-auto text-center white-space-pre p-4">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div>
        <Recaptcha />
        <div className="font-27-semibold mb-3 text-uppercase">
          {t("payment.success_thanks")}
        </div>
        <div className="mb-4">
          {isRegistration?.toString() === "true"
            ? t("payment.success_registration_title")
            : t("payment.success_title")}
        </div>

        {show_loader === "show" ? (
          <Loader />
        ) : contract_id && security_hash ? (
          <div className="d-flex justify-content-center">
            <Button
              flavor="accent"
              classes_text="text-uppercase font-14-semibold"
              classes="mt-2 ps-5 pe-5 mb-4"
              onClick={() => {
                get_recaptcha();
              }}
            >
              {t("payment.save_certificate")}
            </Button>
          </div>
        ) : (
          <></>
        )}

        <div className="mb-4">
          {t("payment.success_subtitle_1")}
          <div className="text-center mt-3 mt-md-1 font-25">{email}</div>
        </div>
        <div className="mb-3 font-20">{t("payment.success_subtitle_2")}</div>
      </div>
    </div>
  );
};
