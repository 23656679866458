import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MAX_FILE, MAX_FILE_MB } from "src/constants";
import { DataEntryStepCustom2Props } from "src/types";
import { RegistrationFileProps } from "src/types/http/Registration.types";
import { fromBase64ToBlob } from "src/utils";

export const useDataEntryCustomStep2 = (props: DataEntryStepCustom2Props) => {
  const { values, setFieldValue } = props.formik;

  const { t } = useTranslation();

  const inputFileAccept = ".pdf,.jpg,.png,.tif";

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isOutOfSizeFile, setIsOutOfSizeFile] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  // //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 810;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    if (values?.registration_file) {
      setFile(
        values?.registration_file
          ? new File(
              [fromBase64ToBlob(values?.registration_file?.data || "")],
              values?.registration_file?.name,
              {
                type: values?.registration_file?.type,
              }
            )
          : undefined
      );
    }
  }, [values?.registration_file]);

  // //////////////////////////////////////////////////////////////////////////////

  const uploadDocument = (file: File) => {
    if (!file) return;
    setLoadingFile(true);
    const fileSize = file.size / MAX_FILE;

    if (fileSize > MAX_FILE_MB) {
      setIsOutOfSizeFile(true);
      setShowUploadModal(false);
      setLoadingFile(false);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const file_: RegistrationFileProps = {
          name: file?.name,
          size: file?.size,
          data: reader.result?.toString() || "",
          type: file?.type,
          lastModified: file?.lastModified,
        };

        setFile(file);
        setFieldValue("registration_file", file_);
        setShowUploadModal(false);
        setLoadingFile(false);
      };
    }
  };

  const deleteDocument = () => {
    setFile(undefined);
    setFieldValue("registration_file", undefined);
  };

  return {
    t,
    file,
    loadingFile,
    inputFileAccept,
    showUploadModal,
    isOutOfSizeFile,
    isMobile,
    uploadDocument,
    deleteDocument,
    setShowUploadModal,
    setIsOutOfSizeFile,
  };
};
