import { CountryData } from "react-phone-input-2";
import i18n from "src/i18n";
import { regex_fiscal_code, regex_phone_number, regex_string } from "src/regex";
import {
  add_start_days,
  checkFiscalCode,
  compare_dates,
  format_date,
  is_valid_date,
  replaceDotPhoneFormat,
  ybirth_date,
  ybool,
  ybool_required,
  ydate,
  ydate_required,
  yemail_required,
  yobj_required,
  ystr,
  ystr_required,
} from "src/utils";
import * as Yup from "yup";

export const useValidationSchemas = (
  end_date?: string,
  min_period?: number,
  max_period?: number
) => [
  Yup.object().shape({
    coverage_type_radios: ystr_required,
    insured_days: ystr_required,
    insured_daily: ystr.when("insured_days", (item, schema) => {
      return item === "daily"
        ? schema.required(i18n.t("commons.required"))
        : schema;
    }),
    insured_number: ystr_required.test(
      "check_validation_insured_number",
      i18n.t("commons.error_is_wrong"),
      (value) => {
        return Number(value) > 0 && Number(value) <= 7;
      }
    ),
    convention: ystr,
  }),
  Yup.object().shape({
    coverage_radios: ystr_required,
    coverage_start_date: ydate_required
      ?.test("check_date", i18n.t("data_entry_step_2.error_date"), (value) => {
        return (
          compare_dates(
            new Date(value?.split("/").reverse().join("/") || ""),
            new Date()
          ) >= 0 && is_valid_date(value?.split("/").reverse().join("/") || "")
        );
      })
      .test(
        "check_date",
        i18n.t("data_entry_step_2.error_date_season"),
        (value) => {
          return (
            compare_dates(
              new Date(value?.split("/").reverse().join("/") || ""),
              new Date(end_date?.split("/").reverse().join("/") || "")
            ) <= 0 && is_valid_date(value?.split("/").reverse().join("/") || "")
          );
        }
      ),
    coverage_end_date: ydate_required
      ?.test(
        "check_date",
        i18n.t("data_entry_step_2.error_date"),
        (value, field) => {
          if (field.parent.coverage_radios === "id_daily") {
            return (
              compare_dates(
                new Date(
                  field.parent.coverage_start_date
                    ?.split("/")
                    .reverse()
                    .join("/") || ""
                ),
                new Date(value?.split("/").reverse().join("/") || "")
              ) === 0 &&
              is_valid_date(value?.split("/").reverse().join("/") || "")
            );
          } else if (field.parent.coverage_radios === "id_period") {
            return (
              compare_dates(
                new Date(value?.split("/").reverse().join("/") || ""),
                new Date(
                  format_date(
                    add_start_days(
                      field.parent.coverage_start_date
                        ?.split("/")
                        .reverse()
                        .join("/") || "",
                      max_period || 0
                    )
                  )
                    .split("/")
                    ?.reverse()
                    ?.join("/")
                )
              ) <= 0 &&
              is_valid_date(value?.split("/").reverse().join("/") || "")
            );
          } else {
            return !(
              compare_dates(
                new Date(
                  field.parent.coverage_start_date
                    ?.split("/")
                    .reverse()
                    .join("/") || ""
                ),
                new Date(value?.split("/").reverse().join("/") || "")
              ) > 0 &&
              is_valid_date(value?.split("/").reverse().join("/") || "")
            );
          }
        }
      )
      .test(
        "check_date_3",
        i18n.t("data_entry_step_2.error_date_season"),
        (value) => {
          return (
            compare_dates(
              new Date(value?.split("/").reverse().join("/") || ""),
              new Date(end_date?.split("/").reverse().join("/") || "")
            ) <= 0 && is_valid_date(value?.split("/").reverse().join("/") || "")
          );
        }
      )
      .test("check_date_2", i18n.t("data_entry_step_2.error_date"), (value) => {
        return (
          compare_dates(
            new Date(value?.split("/").reverse().join("/") || ""),
            new Date()
          ) >= 0 && is_valid_date(value?.split("/").reverse().join("/") || "")
        );
      })
      .test(
        "check_date_4",
        i18n.t("data_entry_step_2.error_date_period_min") +
          ((min_period || 0) + 1),
        (_, field) => {
          if (field.parent.coverage_radios === "id_period") {
            return (
              compare_dates(
                new Date(
                  format_date(
                    add_start_days(
                      field.parent.coverage_start_date
                        ?.split("/")
                        .reverse()
                        .join("/") || "",
                      min_period || 0
                    )
                  )
                    .split("/")
                    ?.reverse()
                    ?.join("/")
                ),
                new Date(
                  format_date(
                    field.parent.coverage_end_date
                      ?.split("/")
                      .reverse()
                      .join("/") || ""
                  )
                    .split("/")
                    ?.reverse()
                    ?.join("/")
                )
              ) <= 0
            );
          } else {
            return true;
          }
        }
      ),
    insured_list: Yup.array().of(
      Yup.object().shape({
        name: ystr_required.matches(
          regex_string,
          i18n.t("commons.error_is_wrong")
        ),
        surname: ystr_required.matches(
          regex_string,
          i18n.t("commons.error_is_wrong")
        ),
        nationality: ystr,
        fc_question: ystr_required,
        birth: ybirth_date
          .when("fc_question", (item, schema) => {
            return item === "no_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .test(
            "check_date",
            i18n.t("data_entry_step_2.error_date"),
            (value, field) => {
              return (
                compare_dates(
                  new Date(value?.split("/").reverse().join("/") || ""),
                  new Date()
                ) <= 0
              );
            }
          )
          .test(
            "check_validation",
            i18n.t("data_entry_step_2.error_date"),
            (value, field) => {
              return field.schema.exclusiveTests["required"] === true
                ? is_valid_date(value?.split("/").reverse().join("/") || "")
                : true;
            }
          ),
        birth_nation: ystr
          .when("fc_question", (item, schema) => {
            return item === "no_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .matches(regex_string, i18n.t("commons.error_is_wrong")),
        birth_place: ystr
          .when("fc_question", (item, schema) => {
            return item === "no_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .matches(regex_string, i18n.t("commons.error_is_wrong")),
        gender: ystr.when("fc_question", (item, schema) => {
          return item === "no_id"
            ? schema.required(i18n.t("commons.required"))
            : schema;
        }),
        fc: ystr
          .when("fc_question", (item, schema) => {
            return item === "yes_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .matches(regex_fiscal_code, i18n.t("commons.error_is_wrong"))
          .test(
            "check_validation",
            i18n.t("commons.fiscal_code_mismatch"),
            (value, field) => {
              if (field?.parent?.fc_question === "yes_id") {
                return (
                  checkFiscalCode(
                    field?.parent?.name,
                    field?.parent?.surname,
                    value || ""
                  ) || false
                );
              }
              return true;
            }
          ),
      })
    ),
    insured_name: ystr_required.matches(
      regex_string,
      i18n.t("commons.error_is_wrong")
    ),
    insured_surname: ystr_required.matches(
      regex_string,
      i18n.t("commons.error_is_wrong")
    ),
    insured_email: yemail_required,
    insured_email_confirm: yemail_required.test(
      "check_email",
      i18n.t("data_entry_step_2.error_equal_email"),
      (value, field) => {
        return (
          field.parent.insured_email?.toLowerCase()?.trim() ===
          value?.toLowerCase()?.trim()
        );
      }
    ),
    insured_phone: Yup.object().shape({
      formattedPhone: ystr.test(
        'validate-insured-phone',
        i18n.t('commons.error_is_wrong'),
        function () {
          const { country, value } = this.parent

          const formattedValue = value?.replace(/\D/g, '')

          if (
            formattedValue?.length > 0 &&
            formattedValue !== (country as CountryData)?.dialCode &&
            replaceDotPhoneFormat((country as CountryData)?.format, formattedValue).includes('X') &&
            formattedValue?.length < (country as CountryData)?.dialCode.length + 9
          ) {
            return false
          }

          return true
        }
      ),
    }),
    insured_cap: ystr_required.test(
      'check_validation_cap',
      i18n.t('commons.error_is_wrong'),
      (value) => {
        return value && value?.length <= 10 ? true : false
      }
    ),
    insured_nationality: ystr,
    insured_fc_question: ystr_required,
    insured_birth: ybirth_date
      .when("insured_fc_question", (item, schema) => {
        return item === "no_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .test("check_date", i18n.t("data_entry_step_2.error_date"), (value) => {
        return (
          compare_dates(
            new Date(value?.split("/").reverse().join("/") || ""),
            new Date()
          ) <= 0
        );
      })
      .test(
        "check_validation",
        i18n.t("data_entry_step_2.error_date"),
        (value, field) => {
          return field.schema.exclusiveTests["required"] === true
            ? is_valid_date(value?.split("/").reverse().join("/") || "")
            : true;
        }
      ),
    insured_birth_nation: ystr
      .when("insured_fc_question", (item, schema) => {
        return item === "no_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .matches(regex_string, i18n.t("commons.error_is_wrong")),
    insured_birth_place: ystr
      .when("insured_fc_question", (item, schema) => {
        return item === "no_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .matches(regex_string, i18n.t("commons.error_is_wrong")),
    insured_gender: ystr.when("insured_fc_question", (item, schema) => {
      return item === "no_id"
        ? schema.required(i18n.t("commons.required"))
        : schema;
    }),
    insured_fc: ystr
      .when("insured_fc_question", (item, schema) => {
        return item === "yes_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .matches(regex_fiscal_code, i18n.t("commons.error_is_wrong"))
      .test(
        "check_validation",
        i18n.t("commons.fiscal_code_mismatch"),
        (value, field) => {
          if (field?.parent?.insured_fc_question === "yes_id") {
            return (
              checkFiscalCode(
                field?.parent?.insured_name,
                field?.parent?.insured_surname,
                value || ""
              ) || false
            );
          }
          return true;
        }
      ),

    consent_privacy: ybool
      .required(i18n.t("commons.consent_required"))
      .oneOf([true], i18n.t("commons.consent_required")),
    consent_marketing: ybool,
    consent_profiling: ybool,
    consent_communication: ybool,
  }),
  Yup.object().shape({
    informative_vision: ybool_required,
    consent_td: ybool
      .required(i18n.t("commons.consent_required"))
      .oneOf([true], i18n.t("commons.consent_required")),
  }),
];

export const useValidationSchemasCustom = () => [
  Yup.object().shape({
    insured_list: Yup.array().of(
      Yup.object().shape({
        name: ystr_required.matches(
          regex_string,
          i18n.t("commons.error_is_wrong")
        ),
        surname: ystr_required.matches(
          regex_string,
          i18n.t("commons.error_is_wrong")
        ),
        nationality: ystr,
        fc_question: ystr_required,
        birth: ybirth_date
          .when("fc_question", (item, schema) => {
            return item === "no_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .test(
            "check_date",
            i18n.t("data_entry_step_2.error_date"),
            (value, field) => {
              return (
                compare_dates(
                  new Date(value?.split("/").reverse().join("/") || ""),
                  new Date()
                ) <= 0
              );
            }
          )
          .test(
            "check_validation",
            i18n.t("data_entry_step_2.error_date"),
            (value, field) => {
              return field.schema.exclusiveTests["required"] === true
                ? is_valid_date(value?.split("/").reverse().join("/") || "")
                : true;
            }
          ),
        birth_nation: ystr
          .when("fc_question", (item, schema) => {
            return item === "no_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .matches(regex_string, i18n.t("commons.error_is_wrong")),
        birth_place: ystr
          .when("fc_question", (item, schema) => {
            return item === "no_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .matches(regex_string, i18n.t("commons.error_is_wrong")),
        gender: ystr.when("fc_question", (item, schema) => {
          return item === "no_id"
            ? schema.required(i18n.t("commons.required"))
            : schema;
        }),
        fc: ystr
          .when("fc_question", (item, schema) => {
            return item === "yes_id"
              ? schema.required(i18n.t("commons.required"))
              : schema;
          })
          .matches(regex_fiscal_code, i18n.t("commons.error_is_wrong"))
          .test(
            "check_validation",
            i18n.t("commons.fiscal_code_mismatch"),
            (value, field) => {
              if (field?.parent?.fc_question === "yes_id") {
                return (
                  checkFiscalCode(
                    field?.parent?.name,
                    field?.parent?.surname,
                    value || ""
                  ) || false
                );
              }
              return true;
            }
          ),
      })
    ),
    insured_name: ystr_required.matches(
      regex_string,
      i18n.t("commons.error_is_wrong")
    ),
    insured_surname: ystr_required.matches(
      regex_string,
      i18n.t("commons.error_is_wrong")
    ),
    insured_email: yemail_required,
    insured_email_confirm: yemail_required.test(
      "check_email",
      i18n.t("data_entry_step_2.error_equal_email"),
      (value, field) => {
        return (
          field.parent.insured_email?.toLowerCase()?.trim() ===
          value?.toLowerCase()?.trim()
        );
      }
    ),
    insured_phone: Yup.object().shape({
      formattedPhone: ystr.test(
        'validate-insured-phone',
        i18n.t('commons.error_is_wrong'),
        function () {
          const { country, value } = this.parent

          const formattedValue = value?.replace(/\D/g, '')

          if (
            formattedValue?.length > 0 &&
            formattedValue !== (country as CountryData)?.dialCode &&
            replaceDotPhoneFormat((country as CountryData)?.format, formattedValue).includes('X') &&
            formattedValue?.length < (country as CountryData)?.dialCode.length + 9
          ) {
            return false
          }

          return true
        }
      ),
    }),
    insured_nationality: ystr,
    insured_fc_question: ystr_required,
    insured_birth: ybirth_date
      .when("insured_fc_question", (item, schema) => {
        return item === "no_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .test(
        "check_date",
        i18n.t("data_entry_step_2.error_date"),
        (value, field) => {
          return (
            compare_dates(
              new Date(value?.split("/").reverse().join("/") || ""),
              new Date()
            ) <= 0
          );
        }
      )
      .test(
        "check_validation",
        i18n.t("data_entry_step_2.error_date"),
        (value, field) => {
          return field.schema.exclusiveTests["required"] === true
            ? is_valid_date(value?.split("/").reverse().join("/") || "")
            : true;
        }
      ),
    insured_birth_nation: ystr
      .when("insured_fc_question", (item, schema) => {
        return item === "no_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .matches(regex_string, i18n.t("commons.error_is_wrong")),
    insured_birth_place: ystr
      .when("insured_fc_question", (item, schema) => {
        return item === "no_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .matches(regex_string, i18n.t("commons.error_is_wrong")),
    insured_gender: ystr.when("insured_fc_question", (item, schema) => {
      return item === "no_id"
        ? schema.required(i18n.t("commons.required"))
        : schema;
    }),
    insured_fc: ystr
      .when("insured_fc_question", (item, schema) => {
        return item === "yes_id"
          ? schema.required(i18n.t("commons.required"))
          : schema;
      })
      .matches(regex_fiscal_code, i18n.t("commons.error_is_wrong"))
      .test(
        "check_validation",
        i18n.t("commons.fiscal_code_mismatch"),
        (value, field) => {
          if (field?.parent?.insured_fc_question === "yes_id") {
            return (
              checkFiscalCode(
                field?.parent?.insured_name,
                field?.parent?.insured_surname,
                value || ""
              ) || false
            );
          }
          return true;
        }
      ),

    consent_privacy: ybool
      .required(i18n.t("commons.consent_required"))
      .oneOf([true], i18n.t("commons.consent_required")),
    consent_marketing: ybool,
    consent_profiling: ybool,
    consent_communication: ybool,
  }),
  Yup.object().shape({
    registration_file: yobj_required,
  }),
  Yup.object().shape({
    informative_vision: ybool_required,
    consent_td: ybool
      .required(i18n.t("commons.consent_required"))
      .oneOf([true], i18n.t("commons.consent_required")),
  }),
];
