import moment from 'moment'
import { useEffect } from 'react'
import * as Yup from 'yup'

export const is_required = (
  schema?: Yup.AnyObjectSchema,
  key = ""
): boolean => {
  return schema?.fields?.[key]?.exclusiveTests?.required;
};

export const compare_dates = (d1?: Date, d2?: Date): number => {
  if (!d1 || !d2 || isNaN(d1?.getTime()) || isNaN(d2?.getTime())) {
    return -2;
  }
  if (moment(d1).format("DD/MM/YYYY") === moment(d2).format("DD/MM/YYYY")) {
    return 0;
  }
  return d1 < d2 ? -1 : 1;
};

export const useOutsideClick = (ref: any, handler: any) => {
  return useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
