import { useEffect } from "react";
import { Formik, FormikDataEntryValues } from "src/types/formik";
import { useBoolean } from "src/utils";

export const useScrollToError = ({ errors }: Formik<FormikDataEntryValues>) => {
  const [toCheck, startCheck, stopCheck] = useBoolean();

  useEffect(() => {
    if (toCheck) {
      stopCheck();
      const err = Object.keys(errors)[0];
      let error = err;
      if (err === "insured_list") {
        const fields: Record<string, string>[] = errors?.insured_list as any;

        const filteredFields = fields?.filter((field) => field !== undefined);
        const filteredFieldsIndex = fields?.findIndex(
          (field) => field !== undefined
        );

        const firstError = Object.keys(filteredFields[0] || {})[0];
        error = "insured_list." + filteredFieldsIndex + "." + firstError;
      }
      if (error) {
        if (error === "coverage_radios") {
          window.scroll(0, 0);
        }
        document?.getElementById("input-" + error)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, toCheck]);

  const triggerFormikCheck = startCheck;

  return { triggerFormikCheck };
};
