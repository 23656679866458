import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  /**
   * Store
   */
  reduce_started: ["started"],
  reduce_reset: [],

  /**
   * Sagas
   */
  saga_startup: [],
  saga_invalidate_cache: [],
});

export const startup_types = Types;
export const startup_creators = Creators;
