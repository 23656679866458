import { Carousel, Image } from "react-bootstrap";
import {
  HeroLandingIcon,
  HeroLandingMobileIcon,
  RightArrowIcon,
} from "src/assets";
import { Button } from "src/widgets/button/button";
import { useSection1 } from "./section1.hook";

export const Section1 = () => {
  const { t, toInsertPage } = useSection1();

  const CarouselForm = (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item>
        <Image
          src={HeroLandingIcon}
          alt="ItasActiveHero"
          width="100%"
          height="100%"
          className="sliders-image-max-height d-none d-md-block"
        />
        <Image
          src={HeroLandingMobileIcon}
          alt="ItasActiveHeroMobile"
          width="100%"
          height="100%"
          className="sliders-image-max-height d-block d-md-none"
        />

        <Carousel.Caption>
          <h1 className="section1-dynamic-size-title white-space-pre text-uppercase">
            {t("landing.section_1_title")}
          </h1>
          <div className="mt-3 section1-dynamic-size-subtitle mb-4">
            {t("landing.section_1_subtitle")}
          </div>
          <Button
            text={t("commons.buy_now_button")}
            iconRight={RightArrowIcon}
            iconSize={18}
            classes="p-2 ps-3 pe-3 d-flex justify-content-center"
            classesText="font-14-ag-500"
            onPress={toInsertPage}
          />
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );

  return <div>{CarouselForm}</div>;
};
