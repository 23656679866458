import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Download } from "src/assets";
import { shoot } from "src/redux/actions";
import { select_history_item } from "src/redux/store";
import { document_t } from "src/types";
import { get_document_by_id, get_language } from "src/utils";
import { Button } from "src/widgets/button/button";
import ScrollToTop from "src/widgets/scroll-to-top/scroll-to-top";

export const Accessibility = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [language] = useState(get_language().toLocaleLowerCase());

  const documents: document_t[] = useSelector(
    select_history_item("history_documents")
  );
  const [accessibilityLink, setAccessibilityLink] = useState<document_t>();

  /**
   * UseEffect
   */
  useEffect(() => {
    dispatch(shoot.reduce_history_item("history_is_landing", false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documents) {
      const accessibility_file_ = get_document_by_id(documents, "9");
      if (accessibility_file_) setAccessibilityLink(accessibility_file_);
    }
  }, [documents]);

  return (
    <Container fluid className="ps-4 pe-4">
      <ScrollToTop />
      <div className="mt-5" />
      <div className="c-862633 white-space-pre font-20-semibold">
        {t("accessibility.title_1")}
      </div>
      <div className="white-space-pre font-16">
        {t("accessibility.subtitle_1")}
      </div>
      <div className="c-862633 white-space-pre font-20-semibold">
        {t("accessibility.title_2")}
      </div>
      <div className="white-space-pre font-16">
        {t("accessibility.subtitle_2")}
      </div>
      <div className="c-862633 white-space-pre font-20-semibold">
        {t("accessibility.title_3")}
      </div>
      <div className="white-space-pre font-16 display-contents">
        {t("accessibility.subtitle_3")}
      </div>
      <div
        className="c-862633 cursor-pointer font-16 display-contents"
        onClick={() =>
          window.open(
            `mailto:${t("accessibility.subtitle_3_email").replaceAll(" ", "")}`
          )
        }
      >
        {t("accessibility.subtitle_3_email")}
      </div>
      <div className="white-space-pre font-16 display-contents">
        {t("accessibility.subtitle_3_2")}
      </div>
      <div className="white-space-pre font-16 display-contents">
        {t("accessibility.subtitle_3_specification")}
      </div>
      <div className="white-space-pre font-16">
        <div className="font-16 display-contents">
          {t("accessibility.privacy_1")}
        </div>
        <div
          className="c-862633 cursor-pointer font-16 display-contents"
          onClick={() =>
            window.open(
              `mailto:${t("accessibility.privacy_email").replaceAll(" ", "")}`
            )
          }
        >
          {t("accessibility.privacy_email")}
        </div>
        <div className="font-16 display-contents">
          {t("accessibility.privacy_2")}
        </div>
        <div
          className="c-862633 cursor-pointer font-16 display-contents"
          onClick={() => window.open(t("accessibility.privacy_link"))}
        >
          {t("accessibility.privacy_link")}
        </div>
        <div className="font-16 display-contents">
          {t("accessibility.privacy_3")}
        </div>
      </div>

      <div className="mt-4 col-md-4 d-flex align-items-center justify-content-between">
        <div className="font-16">{t("accessibility.title_4")}</div>
        <Button
          flavor="transparentAccent"
          classes="m-0 p-0 text-start"
          classesText="font-16 c-862633 text-uppercase"
          classesIconRight="filter-862633 ms-2"
          iconRight={Download}
          iconSize={20}
          onPress={() => {
            if (accessibilityLink?.file) {
              window.open(accessibilityLink?.file[language], "_blank");
            }
          }}
          text={t("commons.download")}
        />
      </div>
      <div className="mt-5 mb-5" />
    </Container>
  );
};
