import './Loader.scss'

import React from 'react'
import { Image, Modal } from 'react-bootstrap'

import { loader_icon } from '../../assets'
import { LoaderPropsCustom } from '../../types/Loader'

const Loader: React.FC<LoaderPropsCustom> = (
  props: LoaderPropsCustom
) => {
  return props.overlay ? (
    <Modal
      centered
      show={props.overlay}
      animation={false}
      className="loader-modal"
    >
      <Image src={loader_icon} className="loader" />
    </Modal>
  ) : (
    <Image src={loader_icon} className="loader" />
  );
};

export default Loader