import { call, put } from 'redux-saga/effects'
import { http_cookie_templates } from 'src/http'
import { saga_act } from 'src/types'

import { cookie_templates_outcome_t } from '../../types/http/cookie.types'
import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Get cookie_templates
 */
export function* saga_cookie_templates(action: saga_act): unknown {
  const cookie_templates: cookie_templates_outcome_t = yield call(
    saga_do_request,
    http_cookie_templates,
    action
  );
  if (cookie_templates) {
    yield put(shoot.reduce_cookie_templates(cookie_templates));
  }
}
