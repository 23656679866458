import '../../styles/colors.scss'
import '../../styles/filters.scss'

import React from 'react'
import { Image as ImageBT } from 'react-bootstrap'

import { ImagePropsCustom } from '../../types/Image'
import { icon_list } from './Icons'

const Image: React.FC<ImagePropsCustom> = ({
  icon,
  class_icon = "",
  src,
  width = 20,
  height = 20,
  ...props
}) => {
  const _icon = icon_list[icon || ""];
  return (
    <ImageBT
      width={width}
      height={height}
      className={class_icon}
      src={src ? src : _icon}
      {...props}
      data-testid="test_id_image"
    />
  );
};

export default Image;
