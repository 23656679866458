import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/navigation";
import { shoot } from "src/redux/actions";
import './section3.scss'

export const useSection3 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Functions
   */
  const toInsertPage = () => {
    navigate(Paths.DataEntry);
    dispatch(shoot.reduce_history_item("history_is_landing"));
  };

  return {
    t,
    toInsertPage
  };
};
