import '../../styles/colors.scss'
import '../../styles/filters.scss'
import './Button.scss'

import React from 'react'
import { Button as ButtonBT } from 'react-bootstrap'

import { ButtonPropsCustom } from '../../types/Button'
import Image from '../image/Image'
import { flavors } from './Flavors'

const Button: React.FC<ButtonPropsCustom> = ({
  flavor,
  classes_text = "text-uppercase",
  icon_left,
  icon_right,
  src_icon_left,
  src_icon_right,
  icon_left_size = 16,
  icon_right_size = 16,
  classes_icon_left = "me-3",
  classes_icon_right = "ms-3",
  type = "button",
  classes = "d-flex align-items-center",
  children,
  ...props
}) => {
  const fl = flavors[flavor || ""];

  if (fl) {
    classes += ` ${fl?.classes || ""}`;
    classes_text += ` ${fl?.classes_text || ""}`;
    classes_icon_left += ` ${fl?.classes_icon_left || ""}`;
    classes_icon_right += ` ${fl?.classes_icon_right || ""}`;
    icon_left = icon_left || fl?.icon_left;
    icon_right = icon_right || fl?.icon_right;
  }

  return (
    <ButtonBT className={classes} type={type} {...props} bsPrefix="prefix-off">
      {/* Icon left */}
      {(icon_left || src_icon_left) && (
        <Image
          className={classes_icon_left}
          src={src_icon_left}
          icon={icon_left}
          width={icon_left_size}
          height={icon_left_size}
          alt=""
        />
      )}

      <div className={classes_text}>{children}</div>

      {/* Icon right */}
      {(icon_right || src_icon_right) && (
        <Image
          className={classes_icon_right}
          src={src_icon_right}
          icon={icon_right}
          width={icon_right_size}
          height={icon_right_size}
          alt=""
        />
      )}
    </ButtonBT>
  );
};

export default Button;
