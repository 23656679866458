import './wrapper.scss'

import { ReactNode } from 'react'
import { Card } from 'react-bootstrap'

import { Footer } from '../footer/footer'
import { Header } from '../header/header'

export const WrapperRoot = ({ children }: { children: ReactNode }) => {
  return (
    <Card>
      <div className="wrapper--header">
        <Header />
      </div>
      <div className="wrapper--body">{children}</div>
      <div className="layout--footer">{<Footer />}</div>
    </Card>
  )
}

