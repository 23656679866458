import { call, put } from 'redux-saga/effects'
import { http_product } from 'src/http'
import { saga_act } from 'src/types'
import { product_outcome_t } from 'src/types/http/product.types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Get product
 */
export function* saga_product(action: saga_act): unknown {
  const product: product_outcome_t = yield call(
    saga_do_request,
    http_product,
    action
  );
  if (product) {
    yield put(shoot.reduce_product(product));
  }
}
