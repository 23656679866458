import './SelectMenu.scss'

import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'

import { left_icon } from '../../assets'
import { opt, select_option_t } from '../../types'
import { SelectMenuPropsCustom } from '../../types/SelectMenu'
import { is_required } from '../../utils/commons'

const SelectMenu: React.FC<SelectMenuPropsCustom> = ({
  id = "",
  formik_schema,
  touched,
  separator = true,
  disabled,
  label,
  error,
  value,
  placeholder,
  options,
  default_option,
  classes,
  classes_field = "m-0 ps-3 pe-3 d-flex justify-content-between min-input-height",
  classes_input = "py-2 d-flex align-items-center flex-1 w-100",
  classes_label = "m-0",
  classes_error = "m-0 c-862633",
  classes_icon = "ms-3",
  classes_menu = "c-7f7f7f",
  set_field_value,
  set_field_touched,
}: SelectMenuPropsCustom) => {
  const [expanded, set_expanded] = useState(false);
  const [selected_option, set_selected_option] =
    useState<opt<select_option_t>>(default_option);

  /**
   * UseEffect
   */
  useEffect(() => {
    if (value === "" || value === undefined) set_selected_option(default_option);
  }, [default_option]);

  useEffect(() => {
    set_field_value?.(id, selected_option?.id || "");
  }, [selected_option, value]);

  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label;
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? "d-block" : "d-none"}
          ${label ? "" : "opacity-0"}
        `}
      >
        {`${label_} `}
      </label>
    );
  };

  const render_head = () => (
    <div
      className={`min-height ${disabled ? "bg-e5e5e5" : " bg-ffffff"} ${classes_field}`}
      onClick={() => set_expanded(!expanded)}
      role={disabled ? "" : "button"}
    >
      <div
        className={`
          ${classes_input}
          ${selected_option?.name ? "" : "c-7f7f7f"}
        `}
      >
        {selected_option?.name || placeholder}
      </div>

      <Image
        src={left_icon}
        width={24}
        height={24}
        className={`icon m-auto
          ${classes_icon}
          ${expanded && !disabled ? "" : "rotate"}
        `}
      />
    </div>
  );

  const render_menu = () => (
    <div className="p-1 mt-2 w-100 position-absolute shadow z-index-style bg-ffffff">
      {options?.map((option, index) => (
        <div key={index} className={separator ? "child" : ""}>
          <div
            className={`p-2 hover ${classes_menu} ${
              option.name === "" ? "pb-4" : ""
            }`}
            onClick={() => {
              set_selected_option(option);
              set_expanded(false);
            }}
            role="button"
          >
            {option.name}
          </div>

          {separator && <div className="mx-1 border c-0000001a" />}
        </div>
      ))}
    </div>
  );

  const render_field = () => (
    <div
      className={`position-relative ${
        touched && error ? "border-error" : "border-normal"
      }`}
      onClick={() => {
        if (expanded) set_field_touched?.(id, true);
      }}
    >
      {/* HEAD */}
      {render_head()}

      {/* MENU */}
      {expanded && !disabled && render_menu()}
    </div>
  );

  const render_error = () => (
    <p
      className={`
        ${classes_error}
        ${classes_error ? "d-block" : "d-none"}
        ${touched && error ? "" : "opacity-0"}
      `}
    >
      {`${error} `}
    </p>
  );

  return (
    <main className={classes}>
      <div className="d-flex flex-column">
        {render_label()}
        {render_field()}
        {render_error()}
      </div>
    </main>
  );
};

export default SelectMenu;
