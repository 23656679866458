import { opt, redux_store } from "src/types";
import { certificate_outcome_t } from "src/types/http/certificate.types";
import { cookie_templates_outcome_t } from "src/types/http/cookie.types";
import { evaluate_premium_t } from "src/types/http/evaluate-premium.types";
import { payment_outcome_t } from "src/types/http/payment.types";
import { product_outcome_t } from "src/types/http/product.types";
import { quote_outcome_t } from "src/types/http/quote.types";
import { registration_outcome_t } from "src/types/http/Registration.types";

/**
 * Certificate
 */
export const select_certificate = (
  state: redux_store
): opt<certificate_outcome_t> => state?.data?.certificate;

/**
 * Payment
 */
export const select_payment = (state: redux_store): opt<payment_outcome_t> =>
  state?.data?.payment;

/**
 * Registration
 */
export const select_registration = (
  state: redux_store
): opt<registration_outcome_t> => state?.data?.registration;

/**
 * Product
 */
export const select_product = (state: redux_store): opt<product_outcome_t> =>
  state?.data?.product;

/**
 * Quote
 */
export const select_quote = (state: redux_store): opt<quote_outcome_t> =>
  state?.data?.quote;

  /**
 * Evaluate premium
 */
export const select_evaluate_premium = (state: redux_store): opt<evaluate_premium_t> =>
state?.data?.evaluate_premium;

/**
 * Cookie templates
 */
export const select_cookie_templates = (
  state: redux_store
): opt<cookie_templates_outcome_t> => state?.data?.cookie_templates;
