import { response_t, saga_act } from 'src/types'
import { cookie_templates_outcome_t } from 'src/types/http/cookie.types'

import { do_request } from './base.http'

/**
 * Payment
 */
export async function http_cookie_templates({
  args,
  type,
}: saga_act): Promise<response_t<cookie_templates_outcome_t>> {
  let value = await do_request<cookie_templates_outcome_t>({
    path: "/cookie",
    args: args,
    saga_type: type,
  });

  return value;
}
