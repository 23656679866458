/** LINK */
export const TO_GRUPPO_ITAS = "https://www.gruppoitas.it/";
export const MAPS_LINK = "http://maps.google.com/?q=";

/** FLOW */
export const FLOW_1_DEFAULT_AGENCY_CODE = "0000";
export const FLOW_1_DEFAULT_NAME = "ITASactive";

export const ERROR_406 = "406";

export const RESOURCE_NOT_FOUND_EC = "404001"

export const QUOTE_ERRORS = [
  "500100",
  "500101",
  "500102",
  "500103",
]

export const MAX_FILE_MB = 5
export const MAX_FILE = 1024 ** MAX_FILE_MB