import "./App.scss";

import { useDispatch } from "react-redux";
import { Routes as ReactRoutes, Route } from "react-router";
import smoothscroll from "smoothscroll-polyfill";
import { Routes } from "src/navigation";
import ScrollToTop from "src/widgets/scroll-to-top/scroll-to-top";

import { WrapperRoot } from "./containers/wrapper/wrapper";
import { shoot } from "./redux/actions";
import { PrivateRoute } from "./widgets/private-route/private-route";

const App: React.FC = () => {
  smoothscroll.polyfill();
  const dispatch = useDispatch();

  window.addEventListener("message", (message) => {
    const data = message?.data || "";
    if (data.toString()?.startsWith("FROM_APP")) {
      dispatch(shoot.reduce_history_item("FROM_APP", true));
    }
  });

  return (
    <WrapperRoot>
      <ScrollToTop />
      <ReactRoutes>
        {Object.values(Routes)?.map(
          ({ path, element, private: pvt }, index) => (
            <Route
              key={index}
              path={path}
              element={pvt ? <PrivateRoute element={element} /> : element}
            />
          )
        )}
      </ReactRoutes>
    </WrapperRoot>
  );
};
export default App;
