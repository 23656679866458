import * as Yup from "yup";

import i18n from "../i18n";
import { regex_birth_date, regex_date } from "../regex";
import { dict } from "../types";

export const ynum = Yup.number().typeError(i18n.t("commons.error_number"));
export const ynum_required = ynum.required(i18n.t("commons.required"));
export const ystr = Yup.string();
export const ystr_required = ystr.required(i18n.t("commons.required"));
export const yobj = Yup.object();
export const yobj_required = yobj.required(i18n.t("commons.required"));
export const ybool = Yup.bool();
export const ybool_required = ybool
  .required(i18n.t("commons.required"))
  .oneOf([true], i18n.t("commons.required"));
export const yemail = Yup.string()
  ?.trim()
  .email(i18n.t("commons.invalid_email"));
export const yemail_required = yemail.required(
  i18n.t("commons.email_required")
);
export const ydate = ystr.matches(regex_date, i18n.t("commons.invalid_date"));
export const ybirth_date = ystr.matches(regex_birth_date, i18n.t("commons.invalid_date"));
export const ydate_required = ydate.required(i18n.t("commons.required"));

export const is_required = (
  schema?: Yup.AnyObjectSchema,
  key = ""
): boolean => {
  return schema?.fields?.[key]?.exclusiveTests?.required;
};

export const formikify =
  (args: {
    get_field_props: (key: string) => unknown;
    schema?: Yup.AnyObjectSchema;
    errors?: dict;
    touched?: dict;
    classes?: string;
  }): ((id: string) => dict) =>
  (id: string): dict => {
    return {
      id,
      formik_props: args?.get_field_props(id),
      formik_schema: args?.schema,
      error: args?.errors?.[id],
      touched: args?.touched?.[id],
      classes: args?.classes,
    };
  };

const isRequired = (
  validationSchema?: Yup.AnyObjectSchema,
  key = ""
): boolean => {
  return validationSchema?.fields?.[key]?.exclusiveTests?.required;
};

export const formatLabel = (
  validationSchema?: Yup.AnyObjectSchema,
  id = "",
  label = ""
) => {
  return isRequired(validationSchema, id) ? `${label}* ` : `${label} `;
};
