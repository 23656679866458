import { call, put } from 'redux-saga/effects'
import { http_registration } from 'src/http'
import { saga_act } from 'src/types'
import { registration_outcome_t } from 'src/types/http/Registration.types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Get registration
 */
export function* saga_registration(action: saga_act): unknown {
  const registration: registration_outcome_t = yield call(
    saga_do_request,
    http_registration,
    action
  );
  if (registration) {
    yield put(shoot.reduce_registration(registration));
  }
}
