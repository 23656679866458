/* eslint-disable @typescript-eslint/no-explicit-any */

import { call, put } from 'redux-saga/effects'
import { ERROR_406 } from 'src/constants'
import i18n from 'src/i18n'
import { response_t, saga_act } from 'src/types'

import { shoot } from '../actions'

/**
 * Do request
 */
export function* saga_do_request(
  service_call: (this: unknown, ...args: any[]) => any,
  action: saga_act
): unknown {
  try {
    yield put(shoot.reduce_loading(true, action?.type));

    const data: response_t<unknown> = yield call(service_call, {
      type: action.type,
      args: action.args,
    });

    if (data && !data?.errorCode) {
      yield put(shoot.reduce_loading(false, action?.type));
      return data;
    } else {

      const err_value = {
        code: data?.errorCode,
        description_fe: data?.errorDescription
          ? data?.errorDescription
          : i18n.t("error.page_error_description"),
        saga_type: "error",
      };

      yield put(shoot.reduce_loading(false, action?.type));
      yield put(shoot.reduce_error(err_value));
    }

    yield put(shoot.reduce_loading(false, action?.type));
    return undefined;
  } catch (error: any) {

    let err_value = {};
    if (
      error?.response?.status &&
      (error?.response?.status).toString() === ERROR_406
    ) {
      err_value = {
        code: error?.response?.status.toString(),
        title: i18n.t("error.page_error_title"),
        description_be: error,
        description_fe: error?.response?.data?.message,
        saga_type: "error",
      };
    } else {
      err_value = {
        code: "000",
        title: i18n.t("error.page_error_title"),
        description_be: error,
        description_fe: i18n.t("error.page_error_description"),
        saga_type: "error",
      };
    }
    yield put(shoot.reduce_loading(false, action?.type));
    yield put(shoot.reduce_error(err_value));
    return undefined;
  }
}
