import moment from 'moment'

export const is_valid_date = (value: string) => {
  return moment(value, "YYYY-MM-DD").isValid();
};

export const compare_dates = (d1?: Date, d2?: Date): number => {
  if (!d1 || !d2 || isNaN(d1?.getTime()) || isNaN(d2?.getTime())) {
    return -2;
  }
  if (moment(d1).format("DD/MM/YYYY") === moment(d2).format("DD/MM/YYYY")) {
    return 0;
  }
  return d1 < d2 ? -1 : 1;
};

export const get_difference_in_days = (d1?: string, d2?: string): number => {
  if (d1 !== undefined && d1 !== "" && d2 !== undefined && d2 !== "") {
    const date1 = new Date(d1.split("/").reverse().join("/")).valueOf();
    const date2 = new Date(d2.split("/").reverse().join("/")).valueOf();
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  } else {
    return -1;
  }
};

export const format_date = (
  date?: Date,
  from = "YYYY-MM-DD",
  to = "DD/MM/YYYY"
): string => {
  return date ? moment(date, from).format(to) : "";
};

export const add_days = (days: number): Date => {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const add_start_days = (start_date:string, days: number): Date => {
  var date = new Date(start_date);
  date.setDate(date.getDate() + days);
  return date;
};

export function capitalizeCase(str: string) {
  if (str.length === 0) return str; 
  const firstLetter = str.charAt(0).toUpperCase();
  const restOfWord = str.slice(1).toLowerCase();
  return firstLetter + restOfWord;
}