import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { LeftArrowIcon } from "src/assets";
import { Paths } from "src/navigation";
import { shoot } from "src/redux/actions";
import { Button } from "src/widgets/button/button";
export const PageNotFound = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * useEffect
   */
  useEffect(() => {
    dispatch(shoot.reduce_history_item("history_is_landing", false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="font-50 mx-auto my-auto text-center">
      {t("error.page_not_found")}
      <div className="mt-4 d-flex align-items-center justify-content-center">
        <Button
          text={t("commons.back_to_homepage")}
          iconLeft={LeftArrowIcon}
          iconSize={18}
          classesIconLeft="me-3 filter-ffffff"
          classes="ms-4 p-2 ps-3 pe-3 d-none d-md-flex align-items-center justify-content-center bg-4145e5 c-ffffff"
          classesText="font-16-ag-500"
          onPress={() => navigate(Paths?.Landing)}
        />
      </div>
    </div>
  );
};
