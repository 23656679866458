import React from 'react'
import { Image } from 'react-bootstrap'

import {
  radio_empty_disabled_icon,
  radio_empty_enabled_icon,
  radio_full_disabled_icon,
  radio_full_enabled_icon,
} from '../../assets'
import { dict } from '../../types'
import { RadioGroupPropsCustom } from '../../types/RadioGroup'
import { is_required } from '../../utils/commons'

const SVG: dict<string> = {
  "full-disabled": radio_full_disabled_icon,
  "full-enabled": radio_full_enabled_icon,
  "empty-disabled": radio_empty_disabled_icon,
  "empty-enabled": radio_empty_enabled_icon,
};

const RadioGroup: React.FC<RadioGroupPropsCustom> = ({
  id,
  formik_props,
  formik_schema,
  disabled,
  label,
  options,
  error,
  touched,
  classes = "w-100",
  classes_label = "m-0 p-0 font-16 d-flex align-items-center",
  classes_error = "m-0 font-14 c-862633 mt-2",
  vertically,
  on_change,
  ...props
}: RadioGroupPropsCustom) => {
  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label;
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? "d-block" : "d-none"}
          ${label ? "" : "opacity-0"}
          ${disabled ? "c-7f7f7f" : ""}
        `}
      >
        {`${label_} `}
      </label>
    );
  };

  const render_error = () => (
    <p
      className={`
        ${classes_error}
        ${classes_error && touched && error ? "d-block" : "d-none"}
        ${touched && error ? "" : "opacity-0"}
      `}
    >
      {`${error} `}
    </p>
  );

  const render_input = () => (
    <div className={`d-flex ${vertically ? "flex-column" : ""}`}>
      {options?.map((option, idx) => {
        const svg_1 = formik_props?.value === option?.id ? "full" : "empty";
        const svg_2 = disabled ? "disabled" : "enabled";
        const src = SVG[`${svg_1}-${svg_2}`];
        return (
          <label
            key={idx}
            role="button"
            className={idx > 0 && !vertically ? "ms-5 " : ""}
            {...props}
          >
            <input
              type="radio"
              hidden={true}
              value={option?.id}
              disabled={disabled}
              checked={formik_props?.value === option?.id}
              onChange={() => on_change?.(option?.id)}
              role="button"
            />
            <Image src={src} width={18} height={18} className="m-0 p-0 me-2" />
            {option?.value}
          </label>
        );
      })}
    </div>
  );

  return (
    <main className={classes}>
      {render_label()}
      {render_input()}
      {render_error()}
    </main>
  );
};

export default RadioGroup;
