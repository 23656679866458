/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArrayRenderProps } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CountryData } from "react-phone-input-2";
import { lowerLanguage } from "src/i18n";
import { select_history_item } from "src/redux/store";
import {
  DataEntryStep2Props,
  coverages_t,
  document_t,
  language_t,
} from "src/types";
import { get_document_by_id, get_language } from "src/utils";

export const useDataEntryCustomStep1 = ({ formik }: DataEntryStep2Props) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = formik;

  const nationalityOptions = [
    { id: "italy_id", value: t("data_entry_step_2.insured_nationality_italy") },
    {
      id: "foreign_id",
      value: t("data_entry_step_2.insured_nationality_foreign"),
    },
  ];

  const fcQuestionOptions = [
    { id: "yes_id", value: t("data_entry_step_2.insured_fc_question_yes") },
    { id: "no_id", value: t("data_entry_step_2.insured_fc_question_no") },
  ];

  const genderOptions = [
    { id: "male", value: t("data_entry_step_2.insured_gender_male") },
    { id: "female", value: t("data_entry_step_2.insured_gender_female") },
  ];

  /**
   * UseSelector
   */
  const documents: document_t[] = useSelector(
    select_history_item("history_documents")
  );
  const coverages: coverages_t = useSelector(
    select_history_item("history_coverages")
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * useState
   */
  const [web_policy, set_web_policy] = useState<document_t>();
  const [coverageOptions, setCoverageOptions] = useState<language_t>();
  const [language] = useState(get_language().toLocaleLowerCase());

  /**
   * UseEffect
   */
  useEffect(() => {
    if (documents) {
      const web_policy_ = get_document_by_id(documents, "6");
      if (web_policy_) set_web_policy(web_policy_);
    }
  }, [documents]);

  useEffect(() => {
    if (coverages) {
      setCoverageOptions(coverages[values?.coverage_type_radios]?.name);
    }
  }, [coverages, values?.coverage_type_radios]);

  /**
   * Functions
   */
  const openInformativePersonalDataLink = () => {
    web_policy?.file &&
      web_policy?.file[language] !== "" &&
      window.open(
        web_policy?.file[language]?.replaceAll('"', "").toString(),
        "_blank"
      );
  };

  const addInsured = (fieldArray: FieldArrayRenderProps) => {
    fieldArray.push({
      name: "",
      surname: "",
      nationality: "italy_id",
      fc_question: "",
      birth: "",
      birth_nation: "",
      birth_place: "",
      gender: "",
      fc: "",
    });
  };

  const removeInsured = (fieldArray: FieldArrayRenderProps) => {
    fieldArray.pop();
  };

  
  const onChangePhone = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setFieldValue('insured_phone', {
      country: data,
      formattedPhone: value === (data as CountryData)?.dialCode ? value : formattedValue,
      value: formattedValue,
    })
  }

  return {
    t,
    lowerLanguage,
    nationalityOptions,
    fcQuestionOptions,
    genderOptions,
    coverageOptions,
    openInformativePersonalDataLink,
    addInsured,
    removeInsured,
    onChangePhone,
  };
};
