import { Loader } from "react-component-library";
import { Helmet } from "react-helmet";
import { Download } from "src/assets";
import { document_t } from "src/types";
import { Button } from "src/widgets/button/button";
import { DataEntryCustomStep1 } from "./Steps/data-entry-custom-step1/data-entry-custom-step1";
import { DataEntryCustomStep2 } from "./Steps/data-entry-custom-step2/data-entry-custom-step2";
import DataEntryCustomStep3 from "./Steps/data-entry-custom-step3/data-entry-custom-step3";
import { DataEntryStep1 } from "./Steps/data-entry-step1/data-entry-step1";
import { DataEntryStep2 } from "./Steps/data-entry-step2/data-entry-step2";
import DataEntryStep3 from "./Steps/data-entry-step3";
import { useDataEntry } from "./data-entry.hook";

export const DataEntry = () => {
  const {
    t,
    currentStep,
    formik,
    formikCustom,
    agency,
    evaluatedPremium,
    minMaxContractor,
    quote,
    loading,
    daysOptions,
    dailyOptions,
    validationSchemas,
    validationSchemasCustom,
    documents,
    language,
    goBack,
    evaluatePremium,
    triggerFormikCheck,
    triggerFormikCheckCustom,
  } = useDataEntry();

  const { values } = formik;

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      classes="mt-3 px-0 ps-md-5 ms-md-2"
      classesText="text-uppercase font-14-semibold"
      text={t("commons.back")}
      onPress={goBack}
    />
  );

  const Title = (
    <div className="px-md-3 mt-1">
      <div className="mb-2 font-30-semibold">{t("data_entry.title")}</div>
      <div className="mb-3 font-16">
        {t("data_entry.step")} {currentStep + 1} {t("data_entry.of")} 4
      </div>
      <div className="mt-3 mb-3 border-top" />
    </div>
  );

  const OriginalFormik = (
    <div>
      {currentStep === 0 && (
        <DataEntryStep1
          formik={formik}
          daysOptions={daysOptions}
          dailyOptions={dailyOptions}
          minMaxContractor={minMaxContractor || {}}
          validationSchema={validationSchemas[currentStep]}
        />
      )}
      {currentStep === 1 && (
        <DataEntryStep2
          agency={agency}
          formik={formik}
          validationSchema={validationSchemas[currentStep]}
        />
      )}
      {currentStep === 2 && (
        <DataEntryStep3
          formik={formik}
          validationSchema={validationSchemas[currentStep]}
        />
      )}
    </div>
  );

  const Document = ({ id, description, file }: document_t) => {
    if (
      !id ||
      id === "5" ||
      id === "6" ||
      id === "7" ||
      id === "8" ||
      id === "9"
    ) {
      return <div key={id}></div>;
    }

    return (
      <div key={id}>
        <div className="d-flex align-items-center justify-content-between">
          <div>{description && description[language]}</div>

          <Button
            flavor="transparentAccent"
            classes="p-0 c-ff3a47 d-flex align-items-start"
            classesText="font-15 c-ff3a47 text-uppercase"
            iconRight={Download}
            classesIconRight="filter-ff3a47 ms-2"
            iconSize={20}
            onPress={() => {
              if (file) {
                window.open(file[language], "_blank");
              }
            }}
            text={t("commons.download")}
          />
        </div>
        <div className="mt-4 mb-4 border-top" />
      </div>
    );
  };

  const Documents = (
    <div className="d-flex justify-content-center align-items-center p-4">
      <div className="col-lg-5 pt-lg-5 pb-lg-5">
        {(documents || [])?.length > 0 && (
          <div className="font-28-semibold mb-4 text-md-center text-uppercase">
            <div>{t("landing.document_title")}</div>
            <div className="font-15-semibold mt-4 d-flex justify-content-md-center align-items-center">
              <div className="col-12 col-md-8">
                <div className="mb-4 border-top c-000000" />
                {documents?.map(Document)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const CustomFormik = (
    <div>
      <div>
        {currentStep === 0 && (
          <DataEntryCustomStep1
            agency={agency}
            minMaxContractor={minMaxContractor || {}}
            formik={formikCustom}
            validationSchema={validationSchemasCustom[currentStep]}
          />
        )}
        {currentStep === 1 && (
          <DataEntryCustomStep2 agency={agency} formik={formikCustom} />
        )}
        {currentStep === 2 && (
          <DataEntryCustomStep3
            formik={formikCustom}
            validationSchema={validationSchemasCustom[currentStep]}
          />
        )}
      </div>
    </div>
  );

  const Form = (
    <div>
      {agency?.isOriginalPayment === undefined || agency?.isOriginalPayment
        ? OriginalFormik
        : CustomFormik}
    </div>
  );

  const EvaluatedPremium = (
    <div className="ps-3 pe-3">
      {evaluatedPremium?.premium &&
        (currentStep === 0 || currentStep === 1) && (
          <div className="mb-4 d-flex justify-content-center justify-content-md-end align-items-center">
            <div className="font-15-semibold text-uppercase">
              {t("data_entry_step_3.total")}
            </div>

            {values?.is_family && (
              <div className="font-30-semibold ms-3 line-over">
                {evaluatedPremium?.premium?.oldQuoteValue?.replace(".", ",")} €
              </div>
            )}
            <div className="font-30-semibold ms-3">
              {evaluatedPremium?.premium?.quoteValue?.replace(".", ",")} €
            </div>
          </div>
        )}
    </div>
  );

  const SubmitButton = (
    <div className="col-12 px-md-3 pl-md-3 mb-2 pt-4 pt-md-0 mb-md-1">
      <div className="d-md-flex justify-content-center justify-content-md-end">
        {currentStep === 2 &&
          (agency?.isOriginalPayment === undefined ||
            agency?.isOriginalPayment) && (
            <div className="font-15 col-md-7 mb-4 mb-md-0 text-center text-md-start">
              {t("data_entry_step_3.click_label")}
            </div>
          )}

        {(currentStep === 0 || currentStep === 1) &&
          (agency?.isOriginalPayment === undefined ||
            agency?.isOriginalPayment) && (
            <div className="mb-4 mb-md-0 d-flex d-md-block justify-content-center">
              <Button
                flavor="accent"
                classes="me-md-4 ps-5 pe-5 d-flex justify-content-center"
                classesText="font-14-semibold text-uppercase"
                disabled={
                  !values?.coverage_type_radios ||
                  !values?.insured_number ||
                  Number(values?.insured_number) < 0 ||
                  Number(values?.insured_number) > 7 ||
                  !values?.insured_days
                }
                onPress={evaluatePremium}
                text={t("data_entry_step_1.evaluate")}
              />
            </div>
          )}
        {
          <Button
            flavor="accent"
            classes="ps-5 pe-5 d-flex justify-content-center m-auto m-md-0"
            type="submit"
            classesText="font-14-semibold text-uppercase"
            text={
              currentStep === 0
                ? t("commons.go_next")
                : currentStep !== 2
                ? t("commons.continue")
                : agency?.isOriginalPayment === undefined ||
                  agency?.isOriginalPayment
                ? t("data_entry.payment_label")
                : t("data_entry.payment_registration")
            }
            disabled={
              agency?.isOriginalPayment === undefined ||
              agency?.isOriginalPayment
                ? (values?.insured_list &&
                    values?.insured_list?.length <
                      (minMaxContractor?.min || 0) - 1 &&
                    currentStep === 1) ||
                  (!quote?.quoteValue && currentStep === 2) ||
                  false
                : false
            }
            onPress={
              agency?.isOriginalPayment === undefined ||
              agency?.isOriginalPayment
                ? triggerFormikCheck?.triggerFormikCheck
                : triggerFormikCheckCustom?.triggerFormikCheck
            }
          />
        }
      </div>
    </div>
  );

  return (
    <div className="mb-5 pb-5 ps-3 pe-3 d-flex justify-content-center container-fluid">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {loading && <Loader overlay={true} />}
      <div className="col-lg-11">
        <form
          onSubmit={(event) => {
            event?.preventDefault();
            return agency?.isOriginalPayment === undefined ||
              agency?.isOriginalPayment
              ? formik?.handleSubmit(event)
              : formikCustom?.handleSubmit(event);
          }}
          className="row justify-content-center m-0 p-0"
        >
          <div className="col-lg-10">
            {agency?.isOriginalPayment === undefined ||
            agency?.isOriginalPayment ? (
              Topbar
            ) : currentStep !== 0 ? (
              Topbar
            ) : (
              <div className="mt-4 pt-4">
                <div className="mt-4"></div>
              </div>
            )}
            <div className="mx-lg-4 col-sm-12 p-0 ps-lg-3 pe-lg-3 pb-lg-3">
              {Title}
              {Form}
              {EvaluatedPremium}
              {SubmitButton}
              {agency?.isOriginalPayment === undefined ||
              agency?.isOriginalPayment ? (
                <></>
              ) : (
                Documents
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
