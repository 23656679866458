import React, { useEffect, useState } from "react";
import { Loader } from "react-component-library";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FLOW_1_DEFAULT_AGENCY_CODE } from "src/constants";
import { DataEntry, PageNotFound } from "src/containers";
import { Paths } from "src/navigation";
import { shoot } from "src/redux/actions";
import { saga_invalidate_cache_history } from "src/redux/sagas/startup.saga";
import { select_history_item, select_product } from "src/redux/store";
import { regex_alphanumeric } from "src/regex";
import {
  ProductCoverageDocumentsOffline,
  ProductDocuments,
  agency_product_t,
  coverage_t,
  coverages_t,
  document_t,
} from "src/types";
import { get_language } from "src/utils";

export type PrivateRouteProps = {
  element: JSX.Element;
};

export const typedMemo: <T>(c: T) => T = React.memo;

export const PrivateRoute = typedMemo(
  ({ element }: PrivateRouteProps): JSX.Element => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    /**
     * UseSelector
     */
    const [wait, set_wait] = useState(true);
    const product = useSelector(select_product);
    const agency: agency_product_t = useSelector(
      select_history_item("history_agency")
    );

    /**
     * UseState
     */
    const [is_enabled, set_is_enabled] = useState(false);
    const [block_dispatch, set_block_dispatch] = useState(true);

    /**
     * UseEffect
     */
    useEffect(() => {
      const path_ = pathname;
      if (path_) {
        if (
          pathname !== Paths.DataEntry &&
          pathname !== Paths.CookiePolicy &&
          pathname !== Paths.PaymentOutcomeOk &&
          pathname !== Paths.PaymentOutcomeKo &&
          pathname !== Paths.Accessibility &&
          pathname !== Paths.Faq
        ) {
          let agency_code =
            pathname === "/"
              ? FLOW_1_DEFAULT_AGENCY_CODE
              : pathname.replace("/", "").toString();
          if (
            agency &&
            agency?.isOriginalPayment !== undefined &&
            !agency?.isOriginalPayment
          ) {
            agency_code = agency?.id || "";
          }

          if (agency_code?.match(regex_alphanumeric)) {
            if (agency?.id !== agency_code) {
              dispatch(shoot.saga_product(agency_code?.toUpperCase()));
              set_wait(true);
            } else {
              // Prevent load data if they are stored
              set_wait(false);
              set_is_enabled(true);
            }
            set_block_dispatch(false);
          } else {
            // NO MATCH
            set_wait(false);
            set_is_enabled(false);
            set_block_dispatch(true);
          }
        } else {
          // RETURN TO ROOT
          set_wait(false);
          set_is_enabled(true);
          set_block_dispatch(true);
        }
      } else {
        // ERROR
        set_wait(false);
        set_is_enabled(false);
        set_block_dispatch(true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    useEffect(() => {
      if (product !== undefined && !block_dispatch) {
        set_is_enabled(true);
        set_wait(false);

        //Clear
        saga_invalidate_cache_history();

        //Set
        dispatch(shoot.reduce_history_item("history_agency", product?.agency));

        if (
          product?.agency?.isOriginalPayment !== undefined &&
          !product?.agency?.isOriginalPayment
        ) {
          dispatch(shoot.reduce_history_item("history_current_step", "0"));
        }

        if (product?.agency?.isCustomActive) {
          const documents = [
            ...(product?.configuration?.documents?.filter(
              (document) =>
                document?.id !== "2" &&
                document?.id !== "3" &&
                document?.id !== "4"
            ) || []),
            ...(product?.agency?.documents || []),
          ];

          if (
            product?.agency?.isOriginalPayment === undefined ||
            product?.agency?.isOriginalPayment
          ) {
            dispatch(shoot.reduce_history_item("history_documents", documents));
          } else {
            const documentsFiltered: (document_t | ProductDocuments)[] =
              documents?.filter((document) => document?.id !== "1");

            let documents_: (document_t | ProductDocuments)[] = [];

            if (product?.agency?.coveragePackageType !== undefined) {
              documents_ = [
                ...(product?.configuration?.documentsOffline?.[
                  product?.agency
                    ?.coveragePackageType as keyof ProductCoverageDocumentsOffline
                ] || []),
                ...documentsFiltered,
              ];
            } else {
              documents_ = [
                ...(product?.configuration?.documentsOffline?.["base"] || []),
                ...documentsFiltered,
              ];
            }

            dispatch(
              shoot.reduce_history_item("history_documents", documents_)
            );
          }
        } else {
          const documents = product?.configuration?.documents;

          if (
            product?.agency?.isOriginalPayment === undefined ||
            product?.agency?.isOriginalPayment
          ) {
            dispatch(shoot.reduce_history_item("history_documents", documents));
          } else {
            const documents = [
              ...(product?.configuration?.documents?.filter(
                (document) => document?.id !== "1"
              ) || []),
            ];

            let documents_: (document_t | ProductDocuments)[] = [];

            if (product?.agency?.coveragePackageType !== undefined) {
              documents_ = [
                ...(product?.configuration?.documentsOffline?.[
                  product?.agency
                    ?.coveragePackageType as keyof ProductCoverageDocumentsOffline
                ] || []),
                ...documents,
              ];
            } else {
              documents_ = [
                ...(product?.configuration?.documentsOffline?.["base"] || []),
                ...documents,
              ];
            }
            dispatch(
              shoot.reduce_history_item("history_documents", documents_)
            );
          }
        }

        dispatch(
          shoot.reduce_history_item(
            "history_languages",
            product?.configuration?.languages
          )
        );
        dispatch(
          shoot.reduce_history_item(
            "history_domains",
            product?.configuration?.domains
          )
        );
        dispatch(
          shoot.reduce_history_item(
            "history_contacts",
            product?.configuration?.contacts
          )
        );
        dispatch(
          shoot.reduce_history_item(
            "history_factors",
            product?.configuration?.factors
          )
        );
        dispatch(
          shoot.reduce_history_item(
            "history_periods",
            product?.configuration?.periods
          )
        );
        if (product?.configuration?.coverages) {
          if (product?.agency?.isCustomActive) {
            const coveragesBase: coverage_t = {
              ...product?.configuration?.coverages["1"],
              policyCode: product?.agency?.policyNumberBase,
            };

            const coveragesPlus: coverage_t = {
              ...product?.configuration?.coverages["2"],
              policyCode: product?.agency?.policyNumberPlus,
            };

            const coverages: coverages_t = {
              "1": coveragesBase,
              "2": coveragesPlus,
            };

            dispatch(shoot.reduce_history_item("history_coverages", coverages));
          } else {
            dispatch(
              shoot.reduce_history_item(
                "history_coverages",
                product?.configuration?.coverages
              )
            );
          }
        }

        if (product?.configuration?.faqs) {
          dispatch(
            shoot.reduce_history_item(
              "history_faqs",
              product?.configuration?.faqs
            )
          );
        }

        if (product?.configuration?.cookieTemplates) {
          const cookie_templates_link_ = {
            start:
              product?.configuration?.cookieTemplates?.startTemplate[
                get_language().toLowerCase()
              ],
            end: product?.configuration?.cookieTemplates?.endTemplate[
              get_language().toLowerCase()
            ],
          };
          dispatch(shoot.saga_cookie_templates(cookie_templates_link_));
        }

        if (product?.configuration?.logos) {
          dispatch(
            shoot.reduce_history_item(
              "history_logos",
              product?.configuration?.logos
            )
          );
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    return is_enabled ? (
      agency?.isOriginalPayment === undefined || agency?.isOriginalPayment ? (
        element
      ) : pathname !== Paths.PaymentOutcomeOk &&
        pathname !== Paths.PaymentOutcomeKo ? (
        <DataEntry />
      ) : (
        element
      )
    ) : !wait ? (
      <PageNotFound />
    ) : (
      <Loader overlay></Loader>
    );
  }
);
