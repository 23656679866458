import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/navigation";
import { select_history_item } from "src/redux/store";
import { faq_t } from "src/types";
import { get_language } from "src/utils";
import "./section8.scss";

export const useSection8 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const FAQS_TO_SHOW = 4;

  /**
   * UseSelector
   */
  const faqs: faq_t[] = useSelector(select_history_item("history_faqs"));

  /**
   * UseState
   */
  const [language] = useState(get_language().toLowerCase());

  /**
   * Functions
   */
  const goToFaqs = () => {
    navigate(Paths.Faq)
  }

  return {
    t,
    language,
    faqs,
    FAQS_TO_SHOW,
    goToFaqs,
  };
};
