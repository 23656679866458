import { ItasMinusIcon, ItasPlusIcon, RightArrowIcon } from "src/assets";
import { faq_t } from "src/types";
import Accordion from "src/widgets/accordion/Accordion";
import { Button } from "src/widgets/button/button";
import { useSection8 } from "./section8.hook";

export const Section8 = () => {
  const { t, language, faqs, FAQS_TO_SHOW, goToFaqs } = useSection8();

  const Title = (
    <h3 className="mb-4 section8-dynamic-size-title d-flex align-item-center text-uppercase">
      {t("landing.section_8_title")}
    </h3>
  );

  const Subtitle = (
    <div className="mb-4 section8-dynamic-size-subtitle d-flex align-items-center white-space-pre-subtitle">
      {t("landing.section_8_subtitle")}
    </div>
  );

  const ButtonForm = (
    <div className="mb-5">
      <Button
        text={t("landing.section_8_all_faqs")}
        iconRight={RightArrowIcon}
        classesIconRight="ms-3 filter-ffffff"
        iconSize={18}
        classes="p-2 ps-3 pe-3 d-flex justify-content-center bg-4145e5"
        classesText="font-14-ag-500 c-ffffff"
        onPress={goToFaqs}
      />
    </div>
  );

  const Faqs = (
    <div className="pt-4 pt-md-0">
      <div>
        {faqs &&
          faqs.slice(0, FAQS_TO_SHOW).map((item: faq_t) => {
            return (
              <Accordion
                key={item?.id}
                title={item?.request?.[language]}
                classes_title="m-0 p-0 me-3 section8-dynamic-size-faqs-title col-8"
                icon_size={24}
                icon_open={ItasMinusIcon}
                icon_close={ItasPlusIcon}
                button_read={false}
                classes="ps-md-3 pe-md-3 section8-class"
                classes_icon_open="section8-open-icon"
                separator="section8-separator"
              >
                <div className="py-2 section8-dynamic-size-faqs-info white-space-pre mb-5">
                  {item?.answer?.[language]}
                </div>
              </Accordion>
            );
          })}
      </div>
    </div>
  );

  return (
    <div className="col-xl-12 d-xl-flex justify-content-center">
      <div className="col-xl-6">
        {Title}
        {Subtitle}
        {ButtonForm}
      </div>
      <div className="col-xl-6">{Faqs}</div>
    </div>
  );
};
