import './Checkbox.scss'

import React from 'react'

import {
  checkbox_empty_disabled_icon,
  checkbox_empty_enabled_icon,
  checkbox_full_disabled_icon,
  checkbox_full_enabled_icon,
} from '../../assets'
import { dict } from '../../types'
import { CheckboxPropsCustom } from '../../types/Checkbox'
import { is_required } from '../../utils/commons'
import Image from '../image/Image'

const SVG: dict<string> = {
  "full-disabled": checkbox_full_disabled_icon,
  "full-enabled": checkbox_full_enabled_icon,
  "empty-disabled": checkbox_empty_disabled_icon,
  "empty-enabled": checkbox_empty_enabled_icon,
};

const CheckBox: React.FC<CheckboxPropsCustom> = ({
  id,
  formik_props,
  formik_schema,
  touched,
  disabled,
  label,
  error,
  classes = "w-100",
  classes_field = "d-flex width-fit-content",
  classes_label = "m-0 p-0 font-16 d-flex align-items-center",
  classes_error = "m-0 p-0 font-14 c-862633",
}: CheckboxPropsCustom) => {
  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label;
    const svg = `${formik_props?.value ? "full" : "empty"}-${
      disabled ? "disabled" : "enabled"
    }`;
    const src = SVG[svg];
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? "d-block" : "d-none"}
          ${label ? "" : "opacity-0"}
          ${disabled ? "c-7f7f7f" : ""}
        `}
        role="button"
      >
        <Image src={src} className="check m-0 p-0 me-2" />
        {`${label_}`}
      </label>
    );
  };

  const render_error = () => (
    <p
      className={`
        ${classes_error}
        ${classes_error && touched && error ? "d-block" : "d-none"}
        ${touched && error ? "" : "opacity-0"}
      `}
    >
      {`${error} `}
    </p>
  );

  return (
    <main className={classes}>
      <div className={classes_field} role="button">
        <input
          id={id}
          type="checkbox"
          disabled={disabled}
          hidden={true}
          checked={!!formik_props?.value || false}
          {...formik_props}
        />
        {render_label()}
      </div>
      {render_error()}
    </main>
  );
};

export default CheckBox;
