import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountryData } from "react-phone-input-2";
import { lowerLanguage } from "src/i18n";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { select_history_item } from "src/redux/store";
import {
  Callbacks,
  SelectProps,
  document_t,
  factors_t,
  periods_t,
  structure_t,
} from "src/types";
import { Formik, FormikDataEntryValues } from "src/types/formik";
import {
  addStartDays,
  formatDate,
  get_document_by_id,
  get_language,
  reverseDate,
  useBoolean,
} from "src/utils";

export const useDataEntryStep2 = (formik: Formik<FormikDataEntryValues>) => {
  const { t } = useTranslation();

  const {
    values: {
      insured_days,
      insured_daily,
      coverage_start_date,
      coverage_radios,
    },
    setFieldValue,
  } = formik;

  const urlSearchParams = new URLSearchParams(useLocation().search);
  const urlCoverage = urlSearchParams.get("coverage");

  const [closePickerTrigger, , , toggleClosePickerTrigger] = useBoolean();

  const nationalityOptions = [
    { id: "italy_id", value: t("data_entry_step_2.insured_nationality_italy") },
    {
      id: "foreign_id",
      value: t("data_entry_step_2.insured_nationality_foreign"),
    },
  ];

  const fcQuestionOptions = [
    { id: "yes_id", value: t("data_entry_step_2.insured_fc_question_yes") },
    { id: "no_id", value: t("data_entry_step_2.insured_fc_question_no") },
  ];

  const genderOptions = [
    { id: "male", value: t("data_entry_step_2.insured_gender_male") },
    { id: "female", value: t("data_entry_step_2.insured_gender_female") },
  ];

  const radioOptions = [
    { id: "id_daily", value: t("data_entry_step_1.coverage_single_daily") },
    { id: "id_weekly", value: t("data_entry_step_1.coverage_weekly") },
    { id: "id_monthly", value: t("data_entry_step_1.coverage_monthly") },
    { id: "id_quarterly", value: t("data_entry_step_1.coverage_quarterly") },
    { id: "id_period", value: t("data_entry_step_1.coverage_period") },
  ];

  /**
   * UseSelector
   */
  const documents: document_t[] = useSelector(
    select_history_item("history_documents")
  );

  const periods: periods_t = useSelector(
    select_history_item("history_periods")
  );

  const factors: factors_t = useSelector(
    select_history_item("history_factors")
  );

  const MIN_DATE_PERIOD_TEMP =
    periods && periods["3"]?.min ? periods["3"]?.min : 0;
  const MAX_DATE_PERIOD_TEMP =
    periods && periods["3"]?.max ? periods["3"]?.max - 1 : 0;
  const MAX_DATE_WEEKLY_TEMP =
    periods && periods["4"]?.max ? periods["4"]?.max - 1 : 0;
  const MAX_DATE_MONTHLY_TEMP =
    periods && periods["5"]?.max ? periods["5"]?.max - 1 : 0;
  const MAX_DATE_QUARTERLY_TEMP =
    periods && periods["6"]?.max ? periods["6"]?.max - 1 : 0;

  const seasonalEndDate = new Date(
    reverseDate(factors?.["2"]?.maxDate || "", "/", "-")
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const coverageStartDate = reverseDate(coverage_start_date);
  const coverageStartDatePeriod = formatDate(
    addStartDays(coverageStartDate, MIN_DATE_PERIOD_TEMP)
  );

  const coverageStartDateDisabledFrom = new Date();
  const coverageStartDateDisabledTo = seasonalEndDate;

  const coverageEndDateDisabledFrom = new Date(
    reverseDate(coverageStartDatePeriod, "/", "-")
  );
  const coverageEndDateDisabledTo = new Date(
    reverseDate(
      formatDate(addStartDays(coverageStartDate, MAX_DATE_PERIOD_TEMP)),
      "/",
      "-"
    )
  );
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * useState
   */
  const [web_policy, set_web_policy] = useState<document_t>();
  const [language] = useState(get_language().toLocaleLowerCase());

  /**
   * UseEffect
   */

  useEffect(() => {
    if (documents) {
      const web_policy_ = get_document_by_id(documents, "6");
      if (web_policy_) set_web_policy(web_policy_);
    }
  }, [documents]);

  useEffect(() => {
    if (coverage_start_date) {
      const callbacks: Callbacks = {
        id_daily: () => {
          setFieldValue(
            "coverage_end_date",
            formatDate(addStartDays(reverseDate(coverage_start_date), 0))
          );
        },
        id_weekly: () => {
          setFieldValue(
            "coverage_end_date",
            formatDate(
              addStartDays(
                reverseDate(coverage_start_date),
                MAX_DATE_WEEKLY_TEMP || 0
              )
            )
          );
        },
        id_monthly: () => {
          setFieldValue(
            "coverage_end_date",
            formatDate(
              addStartDays(
                reverseDate(coverage_start_date),
                MAX_DATE_MONTHLY_TEMP || 0
              )
            )
          );
        },
        id_quarterly: () => {
          setFieldValue(
            "coverage_end_date",
            formatDate(
              addStartDays(
                reverseDate(coverage_start_date),
                MAX_DATE_QUARTERLY_TEMP || 0
              )
            )
          );
        },
        id_period: () => {
          let days = 0;

          if (insured_days === "daily") {
            days = Number(insured_daily?.split("_")[1]) || 0;
          } else {
            days = Number(insured_days?.split("_")[1]) || 0;
          }

          if (days > 1 && days <= 4) {
            setFieldValue(
              "coverage_end_date",
              formatDate(addStartDays(new Date()?.toString(), days - 1))
            );
          } else {
            setFieldValue(
              "coverage_end_date",
              formatDate(
                addStartDays(
                  reverseDate(coverage_start_date),
                  MAX_DATE_PERIOD_TEMP || 0
                )
              )
            );
          }
        },
      };
      callbacks[coverage_radios]?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverage_start_date, coverage_radios]);

  useEffect(() => {
    if (urlCoverage && urlCoverage !== "") {
      setFieldValue("coverage_radios", urlCoverage);
      setFieldValue("coverage_start_date", formatDate(new Date()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCoverage]);

  /**
   * Functions
   */
  const openInformativePersonalDataLink = () => {
    web_policy?.file &&
      web_policy?.file[language] !== "" &&
      window.open(
        web_policy?.file[language]?.replaceAll('"', "").toString(),
        "_blank"
      );
  };

  const getStructures = (structures: structure_t[]) => {
    const filtered_structures: SelectProps[] = [];

    structures?.forEach((structure: structure_t) => {
      if (structure?.id && structure?.name)
        filtered_structures.push({
          id: structure?.id,
          value: structure?.name[language] || "",
        });
    });

    filtered_structures?.push({
      id: "ID_OTHER",
      value: t("commons.other"),
    });
    return filtered_structures || [];
  };

  const onChangePhone = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setFieldValue("insured_phone", {
      country: data,
      formattedPhone:
        value === (data as CountryData)?.dialCode ? value : formattedValue,
      value: formattedValue,
    });
  };

  return {
    t,
    lowerLanguage,
    nationalityOptions,
    fcQuestionOptions,
    genderOptions,
    radioOptions,
    closePickerTrigger,
    coverageStartDateDisabledFrom,
    coverageStartDateDisabledTo,
    coverageEndDateDisabledFrom,
    coverageEndDateDisabledTo,
    getStructures,
    toggleClosePickerTrigger,
    openInformativePersonalDataLink,
    onChangePhone,
  };
};
