/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import {
  WEBAPP_API_NAME,
  WEBAPP_ENDPOINT,
  WEBAPP_REGION
} from "./config";

const awsmobile = {
  aws_project_region: WEBAPP_REGION,
  aws_cloud_logic_custom: [{
    name: WEBAPP_API_NAME,
    endpoint: WEBAPP_ENDPOINT,
    region: WEBAPP_REGION,
  }, ]
};

export default awsmobile;