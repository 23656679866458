import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  /**
   * Store
   */
  reduce_certificate: ["certificate"],
  reduce_payment: ["payment"],
  reduce_registration: ["registration"],
  reduce_product: ["product"],
  reduce_quote: ["quote"],
  reduce_cookie_templates: ["cookie_templates"],
  reduce_evaluate_premium: ["evaluate_premium"],

  /**
   * Sagas
   */
  saga_certificate: ["args"],
  saga_payment: ["args"],
  saga_registration: ["args"],
  saga_product: ["args"],
  saga_quote: ["args"],
  saga_cookie_templates : ["args"],
  saga_evaluate_premium: ["args"],
});

export const data_types = Types;
export const data_creators = Creators;
