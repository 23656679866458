import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { select_history_item } from "src/redux/store";
import { document_t } from "src/types";
import { get_language } from "src/utils";
import "./section7.scss";

export const useSection7 = () => {
  const { t } = useTranslation();
  /**
   * UseSelector
   */
  const documents_: document_t[] = useSelector(
    select_history_item("history_documents")
  );

  /**
   * UseState
   */
  const [language] = useState(get_language().toLowerCase());
  const [documents, setDocuments] = useState(documents_ || []);

  /**
   * UseEffect
   */
  useEffect(() => {
    if (documents_ && documents_?.length > 0) {
      setDocuments(
        documents_?.filter(
          (document_) =>
            document_?.id !== "5" &&
            document_?.id !== "6" &&
            document_?.id !== "7" &&
            document_?.id !== "8" &&
            document_?.id !== "9"
        )
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Functions
   */

  const downloadFile = (document: document_t) => {
    if (document?.file) {
      window.open(document?.file[language], "_blank");
    }
  };

  return {
    t,
    language,
    documents,
    downloadFile,
  };
};
