import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/navigation";
import { shoot } from "src/redux/actions";
import { select_history_item } from "src/redux/store";
import { agency_product_t } from "src/types";
import { Button } from "src/widgets/button/button";

export const PaymentOutcomeKo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const agency: agency_product_t = useSelector(
    select_history_item("history_agency")
  );

  /**
   * UseEffect
   */

  return (
    <div className="font-35 mx-auto my-auto text-center white-space-pre p-4">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div>
        <div className="mb-3">{t("payment.failure_title")}</div>
        <div className="mb-5">{t("payment.failure_sub_title")}</div>
        <div className="d-flex justify-content-center">
          <Button
            flavor="accent"
            classesText="text-uppercase font-14-semibold"
            classes="mt-2 ps-5 pe-5"
            onPress={() => {
              if (
                agency?.isOriginalPayment === undefined ||
                agency?.isOriginalPayment
              ) {
                dispatch(
                  shoot.reduce_history_item("history_current_step", "2")
                );
              } else {
                dispatch(
                  shoot.reduce_history_item("history_current_step", "3")
                );
              }
              navigate(Paths.DataEntry);
            }}
            text={t("commons.back")}
          />
        </div>
      </div>
    </div>
  );
};
