import './input-radio.scss'

import { Image } from 'react-bootstrap'
import {
  RadioEmptyDisabled,
  RadioEmptyEnabled,
  RadioFullDisabled,
  RadioFullEnabled
} from 'src/assets'
import { InputRadioOption, InputRadioProps, typedMemo } from 'src/types'
import { concatClasses, formatLabel } from 'src/utils'

const Sources = {
  'full-disabled': RadioFullDisabled,
  'full-enabled': RadioFullEnabled,
  'empty-disabled': RadioEmptyDisabled,
  'empty-enabled': RadioEmptyEnabled,
}

export const InputRadio = typedMemo(
  ({
    name,
    label,
    disabled,
    validationSchema,
    touched,
    error,
    value: selectedOptionId,
    options,
    handleBlur,
    handleChange,
    vertically = false,
    classes,
    classesOption,
    classesLabel = 'input-radio--label',
    classesError = 'input-radio--error',
  }: InputRadioProps) => {
    const allClassesError = concatClasses(classesError, touched && error ? '' : 'opacity-0')

    const allClassesOption = (index: number) =>
      concatClasses(
        `input-radio--option`,
        ` ${classesOption}`,
        index > 0 && !vertically ? 'ms-5' : '',
        disabled ? 'cursor-not-allowed color-disabled' : 'cursor-pointer'
      )

    const allClassesOptions = concatClasses(
      vertically ? 'input-radio--options-v' : 'input-radio--options-h'
    )

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    const Label = (
      <label htmlFor={name} className={classesLabel}>
        {formatLabel(validationSchema, name, label)}
      </label>
    )

    const Error = <p className={allClassesError}>{`${error} `}</p>

    const Option = ({ id, value }: InputRadioOption, index: number) => (
      <label key={index} className={allClassesOption(index)}>
        <input
          type="radio"
          value={id}
          disabled={disabled}
          checked={selectedOptionId === id}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          hidden
        />
        <Image
          src={
            Sources[
              `${selectedOptionId === id ? 'full' : 'empty'}-${disabled ? 'disabled' : 'enabled'}`
            ]
          }
          width={18}
          height={18}
          className="m-0 p-0 me-2"
        />
        {value}
      </label>
    )

    const Options = <div className={allClassesOptions}>{options?.map(Option)}</div>

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
      <main id={`input-${name}`} className={classes}>
        {Label}
        {Options}
        {Error}
      </main>
    )
  }
)
