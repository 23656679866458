
import { dict } from '../../types'
import { ButtonPropsCustom } from '../../types/Button'

export const flavors: dict<ButtonPropsCustom> = {
  /**
   * Primary
   */
  primary: {
    classes: "primary",
  },
  primary_flip: {
    classes: "primary-flip",
  },
  primary_back: {
    classes: "primary",
    classes_icon_left: "filter-ffffff",
    icon_left: "left_arrow",
  },
  primary_next: {
    classes: "primary",
    classes_icon_right: "filter-ffffff",
    icon_right: "right_arrow",
  },
  primary_flip_back: {
    classes: "primary-flip",
    classes_icon_left: "filter-862633",
    icon_left: "left_arrow",
  },
  primary_flip_next: {
    classes: "primary-flip",
    classes_icon_right: "filter-862633",
    icon_right: "right_arrow",
  },
  primary_flip_back_border: {
    classes: "primary-flip-border",
    classes_icon_left: "filter-862633",
    icon_left: "left_arrow",
  },
  primary_flip_next_border: {
    classes: "primary-flip-border",
    classes_icon_right: "filter-862633",
    icon_right: "right_arrow",
  },

  /**
   * Accent
   */
  accent: {
    classes: "accent",
  },
  accent_flip: {
    classes: "accent-flip",
  },
  accent_back: {
    classes: "accent",
    classes_icon_left: "filter-ffffff",
    icon_left: "left_arrow",
  },
  accent_next: {
    classes: "accent",
    classes_icon_right: "filter-ffffff",
    icon_right: "right_arrow",
  },
  accent_flip_back: {
    classes: "accent-flip",
    classes_icon_left: "filter-059881",
    icon_left: "left_arrow",
  },
  accent_flip_next: {
    classes: "accent-flip",
    classes_icon_right: "filter-059881",
    icon_right: "right_arrow",
  },
  accent_flip_back_border: {
    classes: "accent-flip-border",
    classes_icon_left: "filter-059881",
    icon_left: "left_arrow",
  },
  accent_flip_next_border: {
    classes: "accent-flip-border",
    classes_icon_right: "filter-059881",
    icon_right: "right_arrow",
  },

  /**
   * Transparent primary
   */
  transparent_primary: {
    classes: "transparent-primary",
  },
  transparent_primary_back: {
    classes: "transparent-primary",
    classes_icon_left: "filter-862633",
    icon_left: "left_arrow",
  },
  transparent_primary_next: {
    classes: "transparent-primary",
    classes_icon_right: "filter-862633",
    icon_right: "right_arrow",
  },

  /**
   * Transparent accent
   */
  transparent_accent: {
    classes: "transparent-accent",
  },
  transparent_accent_back: {
    classes: "transparent-accent",
    classes_icon_left: "filter-059881",
    icon_left: "left_arrow",
  },
  transparent_accent_next: {
    classes: "transparent-accent",
    classes_icon_right: "filter-059881",
    icon_right: "right_arrow",
  },

  /**
   * Transparent white
   */
  transparent_white: {
    classes: "transparent-white",
  },
  transparent_white_border: {
    classes: "transparent-white-border",
  },
};
