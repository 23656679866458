import './input-select.scss'

import { Image } from 'react-bootstrap'
import { LeftIcon } from 'src/assets'
import i18n from 'src/i18n'
import { InputSelectOption, InputSelectProps, typedMemo } from 'src/types'
import { formatLabel } from 'src/utils'

import { useInputSelect } from './input-select.hook'

export const InputSelect = typedMemo((props: InputSelectProps) => {
  const {
    name = '',
    value: selectedOptionId,
    validationSchema,
    disabled,
    label,
    error,
    placeholder = i18n.t('commons.select'),
    options,
    classes = '',
    classesLabel = 'input-select--label',
    classesMenu = 'input-select--menu',
  } = props

  const {
    expanded,
    toggleMenu,
    updateTouched,
    updateOption,
    allClassesArrow,
    allClassesInput,
    allClassesHead,
    allClassesField,
    allClassesError,
  } = useInputSelect(props)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const Label = (
    <label htmlFor={name} className={classesLabel}>
      {formatLabel(validationSchema, name, label)}
    </label>
  )

  const Input = (
    <div className={allClassesInput}>
      {options?.find(({ id }) => id === selectedOptionId)?.value || placeholder}
    </div>
  )

  const Arrow = <Image src={LeftIcon} width={24} height={24} className={allClassesArrow} />

  const Head = (
    <div className={allClassesHead} onClick={toggleMenu}>
      {Input}
      {Arrow}
    </div>
  )

  const Option = (option: InputSelectOption, index: number) => (
    <div key={index} className="input-select--option">
      <div className="input-select--option-value" onClick={() => updateOption(option)}>
        {option.value}
      </div>
      <div className="input-select--separator" />
    </div>
  )

  const Menu = <div className={classesMenu}>{options?.map(Option)}</div>

  const Field = (
    <div className={allClassesField} onClick={updateTouched}>
      {Head}
      {expanded && !disabled && Menu}
    </div>
  )

  const Error = <p className={allClassesError}>{`${error} `}</p>

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <main id={`input-${name}`} className={classes}>
      <div className="d-flex flex-column">
        {Label}
        {Field}
        {Error}
      </div>
    </main>
  )
})
