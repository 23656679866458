import './input-text.scss'

import i18n from 'src/i18n'
import { InputTextProps } from 'src/types'
import { concatClasses } from 'src/utils'

export const useInputText = ({
  name,
  label,
  disabled,
  touched,
  error,
  value,
  type,
  placeholder = i18n.t('commons.insert'),
  warning,
  classesLabel = 'input-text--label',
  classesInput = 'input-text--input',
  classesField = 'input-text--field',
  classesError = 'input-text--error',
  classesWarning = 'input-text--warning',
  classesIcon = 'input-text--icon',
  onPressIcon,
}: InputTextProps) => {
  const onPressEnabledIcon = () => {
    if (!disabled) {
      onPressIcon?.()
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const conditionalClasses1 = disabled
    ? {
        input: 'cursor-not-allowed color-disabled',
        field: 'input-text--field-disabled',
        icon: 'cursor-not-allowed',
      }
    : {
        input: 'cursor-text',
        field: 'cursor-text',
        icon: 'bg-white',
      }

  const conditionalClasses2 =
    touched && error
      ? { field: 'input-text--border-error', error: '' }
      : { field: 'input-text--border-normal', error: 'opacity-0' }

  const conditionalClasses3 = warning
    ? { field: 'input-text--border-warning', warning: 'd-block' }
    : { field: '', warning: 'd-none' }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const allClassesLabel = concatClasses(classesLabel, label ? 'd-block' : 'd-none')

  const allClassesInput = concatClasses(classesInput, conditionalClasses1.input)

  const allClassesField = concatClasses(
    classesField,
    conditionalClasses1.field,
    conditionalClasses2.field,
    conditionalClasses3.field
  )

  const allClassesError = concatClasses(classesError, conditionalClasses2.error)

  const allClassesWarning = concatClasses(classesWarning, conditionalClasses3.warning)

  const allClassesIcon = concatClasses(
    classesIcon,
    conditionalClasses1.icon,
    onPressIcon ? 'cursor-pointer' : 'cursor-auto'
  )

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const inputProps = {
    name,
    value,
    disabled,
    placeholder,
    type,
    className: allClassesInput,
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return {
    onPressEnabledIcon,
    allClassesLabel,
    allClassesField,
    allClassesError,
    allClassesWarning,
    allClassesIcon,
    inputProps,
  }
}
