import './tooltip.scss'

import { FC, useRef, useState } from 'react'
import { Overlay, Row, Tooltip as RBTooltip } from 'react-bootstrap'
import { Image } from 'react-component-library'
import { InfoIcon } from 'src/assets'

import { tooltipProps } from '../../types/widgets/tooltip.types'

export const Tooltip: FC<tooltipProps> = ({
  label,
  children,
  placement = "bottom",
  width_perc = 0.7,
  width_max = 400,
  icon_size = 16,
  classes = "",
  classes_label = "m-0 p-0 font-16",
  classes_icon = "",
}: tooltipProps) => {
  const target = useRef(null);
  const [expanded, set_expanded] = useState(false);

  return (
    <main className={classes}>
      <Row className="m-0 p-0 align-items-center cursor-pointer h-100">
        {label && <p className={classes_label}>{label}</p>}
        <div className='m-0 p-0'
          ref={target}
          onMouseEnter={() => set_expanded(true)}
          onMouseLeave={() => set_expanded(false)}
        >
          <Image
            src={InfoIcon}
            width={icon_size}
            height={icon_size}
            className={classes_icon}
            alt="i"
          />
        </div>
      </Row>
      <Overlay target={target.current} show={expanded} placement={placement}>
        {(props) => (
          <RBTooltip id="tooltip-wrapper" {...props}>
            <div
              style={{
                width: Math.min(width_perc * window.innerWidth, width_max),
              }}
            >
              {children}
            </div>
          </RBTooltip>
        )}
      </Overlay>
    </main>
  );
};
