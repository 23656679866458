import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FLOW_1_DEFAULT_AGENCY_CODE } from "src/constants";
import { Paths, SAGA_GUARDS } from "src/navigation";
import { shoot } from "src/redux/actions";
import {
  select_evaluate_premium,
  select_history_item,
  select_loading,
  select_payment,
  select_quote,
  select_registration,
} from "src/redux/store";
import {
  AxeptaWindow,
  InputSelectOption,
  MinMaxContractor,
  agency_product_t,
  document_t,
  domains_t,
  factors_t,
  periods_t,
  seasonal_t,
} from "src/types";
import { FormikDataEntryValues, FormikOtherInsured } from "src/types/formik";
import { formatDate, getDifferenceInDays, get_language } from "src/utils";
import { submit_captcha } from "src/widgets/recaptcha/recaptcha";
import { getInitialValues } from "./handlers/form-initial-values";
import { useScrollToError } from "./handlers/scroll-to-error.hook";
import {
  useValidationSchemas,
  useValidationSchemasCustom,
} from "./handlers/validation-schema";

export const useDataEntry = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const daysOptions: InputSelectOption[] = [
    {
      id: "daily",
      value: t("data_entry_step_1.coverage_daily"),
    },
    {
      id: "weekly_7",
      value: t("data_entry_step_1.coverage_weekly"),
    },
    {
      id: "monthly_30",
      value: t("data_entry_step_1.coverage_monthly"),
    },
    {
      id: "quarterly_90",
      value: t("data_entry_step_1.coverage_quarterly"),
    },
  ];

  const dailyOptions: InputSelectOption[] = [
    {
      id: "daily_1",
      value: "1",
    },
    {
      id: "daily_2",
      value: "2",
    },
    {
      id: "daily_3",
      value: "3",
    },
    {
      id: "daily_4",
      value: "4",
    },
  ];

  const urlSearchParams = new URLSearchParams(useLocation().search);
  const urlCoverage = urlSearchParams.get("coverage");
  const urlPackage = urlSearchParams.get("package");

  /**
   * useSelector
   */
  const agency: agency_product_t = useSelector(
    select_history_item("history_agency")
  );

  const historyCurrentStep: number = useSelector(
    select_history_item("history_current_step")
  );
  const documents: document_t[] = useSelector(
    select_history_item("history_documents")
  );
  const factors: factors_t = useSelector(
    select_history_item("history_factors")
  );
  const products: string = useSelector(select_history_item("history_products"));

  const periods: periods_t = useSelector(
    select_history_item("history_periods")
  );
  const domains: domains_t = useSelector(
    select_history_item("history_domains")
  );
  const payment = useSelector(select_payment);
  const registration = useSelector(select_registration);

  const evaluatedPremium = useSelector(select_evaluate_premium);
  const quote = useSelector(select_quote);

  const loadingPayment = useSelector(select_loading(SAGA_GUARDS.PAYMENT));
  const loadingPremium = useSelector(
    select_loading(SAGA_GUARDS.EVALUATE_PREMIUM)
  );

  /**
   * useState
   */
  const [language] = useState(get_language().toLocaleLowerCase());
  const [initialValues, setInitialValues] = useState<FormikDataEntryValues>(
    products ? JSON.parse(products || "") : getInitialValues
  );
  const [minMaxContractor, setMinMaxContractor] = useState<MinMaxContractor>();
  const [currentStep, setCurrentStep] = useState(
    historyCurrentStep >= 0 && historyCurrentStep
      ? Number(historyCurrentStep)
      : 0
  );
  const [seasonal, setSeasonal] = useState<seasonal_t>();
  const [updatePremium, setUpdatePremium] = useState(false);
  const [updateDays, setUpdateDays] = useState(false);
  const [scrollEvaluated, setScrollEvaluated] = useState(true);

  /**
   * useEffect
   */
  useEffect(() => {
    if (evaluatedPremium && scrollEvaluated && currentStep !== 0) {
      window?.scrollTo(0, 0);
      setScrollEvaluated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluatedPremium, scrollEvaluated, currentStep]);

  useEffect(() => {
    dispatch(shoot.reduce_history_item("history_is_landing"));
    dispatch(shoot.reduce_payment());
    dispatch(shoot.reduce_registration());
    dispatch(shoot.reduce_evaluate_premium());

    if (agency?.isOriginalPayment === undefined || agency?.isOriginalPayment) {
      if (!domains || !factors || !periods || !agency || currentStep < 0) {
        dispatch(shoot.reduce_history_item("history_current_step", "0"));
        navigate(
          Paths.Landing +
            (agency && agency.id !== FLOW_1_DEFAULT_AGENCY_CODE
              ? agency.id
              : "")
        );
      }
    } else {
      if (!domains || !factors || !periods || !agency || currentStep < 0) {
        dispatch(shoot.reduce_history_item("history_current_step", "1"));
        navigate(
          Paths.DataEntry +
            (agency && agency.id !== FLOW_1_DEFAULT_AGENCY_CODE
              ? agency.id
              : "")
        );
      }
    }

    if (products) {
      setInitialValues(JSON.parse(products || ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (factors) {
      setMinMaxContractor({
        max: factors["1"]?.max || 1,
        min: factors["1"]?.min || 1,
      });

      setSeasonal({
        seasonal_end_date: factors["2"]?.maxDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factors]);

  useEffect(() => {
    if (
      payment &&
      payment?.paymentId &&
      (agency?.isOriginalPayment === undefined || agency?.isOriginalPayment)
    ) {
      trackPurchase();
      const axeptaWindow: AxeptaWindow = window;
      axeptaWindow
        ?.callAxeptaClient?.()
        ?.proceedToPayment?.(payment?.paymentId);
      dispatch(shoot.reduce_payment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  useEffect(() => {
    if (
      registration &&
      (agency?.isOriginalPayment !== undefined || !agency?.isOriginalPayment)
    ) {
      if (registration?.shopId && registration?.shopId !== "") {
        navigate(
          Paths.PaymentOutcomeOk +
            "?contractId=" +
            registration?.shopId?.replace("/", "-") +
            "&email=" +
            registration?.email +
            "&securityHash=" +
            registration?.securityHash +
            "&isRegistration=" +
            true
        );
      } else {
        navigate(Paths.PaymentOutcomeKo);
      }
      dispatch(shoot.reduce_registration());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  const trackPurchase = () => {
    const googleWindow: any = window;
    googleWindow.dataLayer = googleWindow?.dataLayer || [];
    googleWindow.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    const ecommerce = {
      value: +(quote?.quoteValue || 0),
      currency: "EUR",
      items: [
        {
          item_category: formik?.values?.coverage_radios,
          item_category2:
            formik?.values?.coverage_type_radios === "1" ? "Base" : "Plus",
        },
      ],
    };
    dispatch(shoot.reduce_history_item("history_ecommerce", ecommerce));
    googleWindow.dataLayer.push({ event: "begin_checkout", ecommerce });
  };

  const doPostPayment = () => {
    submit_captcha().then((token) => {
      dispatch(
        shoot.saga_payment({
          agencyId: agency?.id,
          quoteId: quote?.quoteId,
          token: token,
          language: language.toUpperCase(),
        })
      );
    });
  };

  const doPostRegistration = () => {
    submit_captcha().then((token) => {
      dispatch(
        shoot.saga_registration({
          registrationFile: JSON.parse(products || "")?.registration_file,
          agencyId: agency?.id,
          quoteId: quote?.quoteId,
          token: token,
          language: language.toUpperCase(),
        })
      );
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Formik
   */
  const validationSchemas = useValidationSchemas(
    seasonal ? seasonal?.seasonal_end_date : "",
    periods && periods["3"]?.min ? periods["3"]?.min : 0,
    periods && periods["3"]?.max ? periods["3"]?.max - 1 : 0
  );

  const validationSchemasCustom = useValidationSchemasCustom();

  const formik = useFormik<FormikDataEntryValues>({
    initialValues: initialValues,
    validationSchema: validationSchemas[currentStep],
    enableReinitialize: true,
    onSubmit: (values, { setTouched, setSubmitting }) => {
      dispatch(
        shoot.reduce_history_item(
          "history_products",
          JSON.stringify({
            ...values,
          })
        )
      );

      if (currentStep < 2) {
        dispatch(
          shoot.reduce_history_item(
            "history_current_step",
            (currentStep + 1).toString()
          )
        );
        setUpdateDays(true);
        setCurrentStep(currentStep + 1);
        setTouched({});
        setSubmitting(false);
        if (currentStep === 0) {
          evaluatePremium();
        }
      } else {
        doPostPayment();
      }
    },
  });

  const formikCustom = useFormik<FormikDataEntryValues>({
    initialValues: initialValues,
    validationSchema: validationSchemasCustom[currentStep],
    onSubmit: (values, { setTouched, setSubmitting }) => {
      dispatch(
        shoot.reduce_history_item(
          "history_products",
          JSON.stringify({
            ...values,
          })
        )
      );

      if (currentStep < 2) {
        dispatch(
          shoot.reduce_history_item(
            "history_current_step",
            (currentStep + 1).toString()
          )
        );
        setCurrentStep(currentStep + 1);
        setTouched({});
        setSubmitting(false);
      } else {
        doPostRegistration();
      }
    },
  });

  const { values, setValues, setFieldValue } = formik;

  const triggerFormikCheck = useScrollToError(formik);
  const triggerFormikCheckCustom = useScrollToError(formikCustom);

  useEffect(() => {
    if (
      agency?.isOriginalPayment !== undefined &&
      !agency?.isOriginalPayment &&
      agency?.coveragePackageType !== undefined
    ) {
      formikCustom?.setFieldValue(
        "coverage_type_radios",
        agency?.coveragePackageType === "base" ? "1" : "2"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency?.coveragePackageType]);

  useEffect(() => {
    if (
      currentStep === 0 &&
      values?.insured_list?.length === 0 &&
      Number(values?.insured_number) > 1 &&
      (agency?.isOriginalPayment === undefined || agency?.isOriginalPayment)
    ) {
      const insuredList_: FormikOtherInsured[] = [];

      Array.from(Array(Number(values?.insured_number) - 1).keys())?.map(() =>
        insuredList_?.push({
          name: "",
          surname: "",
          nationality: "italy_id",
          fc_question: "",
          birth: "",
          birth_nation: "",
          birth_place: "",
          gender: "",
          fc: "",
        })
      );

      if (insuredList_?.length > 0) {
        const state = { ...values };
        state["insured_list"] = [];
        state["insured_list"] = insuredList_?.filter(
          (item) => item !== undefined
        );
        setValues(state);
      } else {
        const state = { ...values };
        state["insured_list"] = [];
        setValues(state);
      }
    } else if (
      currentStep === 0 &&
      values?.insured_list &&
      values?.insured_list?.length > 0 &&
      Number(values?.insured_number) === 1
    ) {
      const state = { ...values };
      state["insured_list"] = [];
      setValues(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, values?.insured_list, values?.insured_number, currentStep]);

  useEffect(() => {
    if (values?.coverage_radios) {
      if (values?.coverage_radios === "id_daily") {
        setFieldValue("insured_days", "daily");
        setFieldValue("insured_daily", "daily_1");
      } else if (values?.coverage_radios === "id_weekly") {
        setFieldValue("insured_days", "weekly_7");
      } else if (values?.coverage_radios === "id_monthly") {
        setFieldValue("insured_days", "monthly_30");
      } else if (values?.coverage_radios === "id_quarterly") {
        setFieldValue("insured_days", "quarterly_90");
      } else if (
        values?.coverage_radios === "id_period" &&
        values?.coverage_start_date &&
        values?.coverage_end_date
      ) {
        const days = getDifferenceInDays(
          values?.coverage_start_date,
          values?.coverage_end_date
        );

        setFieldValue("insured_days", "daily");
        setFieldValue("insured_daily", "daily_" + (days + 1));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.coverage_radios,
    values?.coverage_start_date,
    values?.coverage_end_date,
  ]);

  useEffect(() => {
    if (updateDays && values?.insured_days && values?.insured_daily) {
      
      if(values?.coverage_start_date === "" || values?.coverage_start_date === undefined){
        setFieldValue("coverage_start_date", formatDate(new Date()));
      }

      if (
        values?.insured_days === "daily" &&
        values?.insured_daily === "daily_1"
      ) {
        setFieldValue("coverage_radios", "id_daily");
      } else if (values?.insured_days === "weekly_7") {
        setFieldValue("coverage_radios", "id_weekly");
      } else if (values?.insured_days === "monthly_30") {
        setFieldValue("coverage_radios", "id_monthly");
      } else if (values?.insured_days === "quarterly_90") {
        setFieldValue("coverage_radios", "id_quarterly");
      } else {
        setFieldValue("coverage_radios", "id_period");
      }
      setUpdateDays(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.insured_days, values?.insured_daily, updateDays]);

  useEffect(() => {
    if (updatePremium) {
      if (currentStep === 1) {
        setScrollEvaluated(true);
      }

      let insuredDays_ = "";

      if (values?.insured_days !== "daily") {
        insuredDays_ =
          daysOptions
            ?.find((item) => item?.id === values?.insured_days)
            ?.id?.split("_")[1] || "";
      } else {
        insuredDays_ =
          dailyOptions
            ?.find((item) => item?.id === values?.insured_daily)
            ?.id?.split("_")[1] || "";
      }

      dispatch(
        shoot.saga_evaluate_premium({
          coverage: values?.coverage_type_radios,
          insuredNumber: values?.insured_number,
          insuredDays: insuredDays_,
          isFamily: values?.is_family,
        })
      );
      setUpdatePremium(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updatePremium,
    currentStep,
    values?.coverage_type_radios,
    values?.insured_number,
    values?.insured_days,
    values?.insured_daily,
    values?.is_family,
  ]);

  useEffect(() => {
    if (evaluatedPremium) {
      dispatch(shoot.reduce_evaluate_premium());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.coverage_type_radios,
    values?.insured_number,
    values?.insured_days,
    values?.insured_daily,
    values?.coverage_radios,
    values?.is_family,
  ]);

  useEffect(() => {
    if (urlCoverage) {
      if (urlCoverage === "id_daily") {
        setFieldValue("insured_days", "daily");
        setFieldValue("insured_daily", "daily_1");
      } else if (urlCoverage === "id_weekly") {
        setFieldValue("insured_days", "weekly_7");
      } else if (urlCoverage === "id_monthly") {
        setFieldValue("insured_days", "monthly_30");
      } else if (urlCoverage === "id_quarterly") {
        setFieldValue("insured_days", "quarterly_90");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCoverage]);

  useEffect(() => {
    if (urlPackage && urlPackage !== "") {
      setFieldValue("coverage_type_radios", urlPackage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPackage]);

  useEffect(() => {
    if (Number(values?.insured_number) !== undefined) {
      setFieldValue("is_family", false);
      const state = { ...values };
      state["insured_list"] = [];
      setValues(state);
    }

    if (Number(values?.insured_number) <= 1) {
      setFieldValue("is_family", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.insured_number]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Functions
   */
  const goBack = () => {
    if (currentStep > 0) {
      let state = { ...values };
      state["discount"] = "";
      setValues(state);

      if (currentStep === 1) {
        const state = { ...values };
        state["insured_list"] = [];
        setValues(state);
        setUpdateDays(true);
        dispatch(shoot.reduce_evaluate_premium());
        setScrollEvaluated(true);
      }

      setCurrentStep(currentStep - 1);
      dispatch(
        shoot.reduce_history_item(
          "history_current_step",
          (historyCurrentStep - 1).toString()
        )
      );
      dispatch(shoot.reduce_error());
    } else {
      if (
        agency?.isOriginalPayment === undefined ||
        agency?.isOriginalPayment
      ) {
        dispatch(shoot.reduce_history_item("history_current_step", "0"));
        navigate(
          Paths.Landing +
            (agency.id !== FLOW_1_DEFAULT_AGENCY_CODE ? agency.id : "")
        );
      } else {
        dispatch(shoot.reduce_history_item("history_current_step", "1"));
        navigate(
          Paths.DataEntry +
            (agency.id !== FLOW_1_DEFAULT_AGENCY_CODE ? agency.id : "")
        );
      }
    }
  };

  const evaluatePremium = () => {
    setUpdatePremium(true);
  };

  return {
    t,
    currentStep,
    formik,
    formikCustom,
    agency,
    evaluatedPremium,
    minMaxContractor,
    quote,
    loading: loadingPayment || loadingPremium,
    daysOptions,
    dailyOptions,
    validationSchemas,
    validationSchemasCustom,
    documents,
    language,
    triggerFormikCheck,
    triggerFormikCheckCustom,
    goBack,
    evaluatePremium,
  };
};
