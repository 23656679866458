import { createReducer } from 'reduxsauce'
import { data_store } from 'src/types'

import { data_types } from '../../actions'

/**
 * Certificate
 */
 export const reduce_certificate = (
  state: data_store,
  { certificate }: data_store
): data_store => ({
  ...state,
  certificate,
});

/**
 * Payment
 */
 export const reduce_payment = (
  state: data_store,
  { payment }: data_store
): data_store => ({
  ...state,
  payment,
});

/**
 * Registration
 */
export const reduce_registration = (
  state: data_store,
  { registration }: data_store
): data_store => ({
  ...state,
  registration,
});

/**
 * Product
 */
export const reduce_product = (
  state: data_store,
  { product }: data_store
): data_store => ({
  ...state,
  product,
});

/**
 * Quote
 */
export const reduce_quote = (
  state: data_store,
  { quote }: data_store
): data_store => ({
  ...state,
  quote,
});

/**
 * Cookie templates
 */
 export const reduce_cookie_templates = (
  state: data_store,
  { cookie_templates }: data_store
): data_store => ({
  ...state,
  cookie_templates,
});

/**
 * Evaluate premium
 */
export const reduce_evaluate_premium = (
  state: data_store,
  { evaluate_premium }: data_store
): data_store => ({
  ...state,
  evaluate_premium,
});

export default createReducer(
  {},
  {
    [data_types.REDUCE_CERTIFICATE]: reduce_certificate,
    [data_types.REDUCE_PAYMENT]: reduce_payment,
    [data_types.REDUCE_PRODUCT]: reduce_product,
    [data_types.REDUCE_REGISTRATION]: reduce_registration,
    [data_types.REDUCE_QUOTE]: reduce_quote,
    [data_types.REDUCE_COOKIE_TEMPLATES]: reduce_cookie_templates,
    [data_types.REDUCE_EVALUATE_PREMIUM]: reduce_evaluate_premium,
  }
);
