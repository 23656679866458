import './Modal.scss'

import React from 'react'
import { Modal as RBModal } from 'react-bootstrap'

import { close_icon } from '../../assets'
import { ModalPropsCustom } from '../../types/Modal'
import Image from '../image/Image'

const Modal: React.FC<ModalPropsCustom> = ({
  visible,
  children,
  on_close,
  classes,
  classes_head_custom = "d-flex justify-content-end pt-4 pe-4",
}: ModalPropsCustom) => {
  return (
    <RBModal
      dialogClassName="modal-width"
      onHide={() => null}
      centered
      show={visible}
      animation={false}
    >
      {on_close && (
        <div className={classes_head_custom}>
          <Image src={close_icon} fluid role="button" onClick={on_close} />
        </div>
      )}
      <RBModal.Body className={classes}>{children}</RBModal.Body>
    </RBModal>
  );
};

export default Modal;
