import moment from 'moment'

export const formatDate = (date?: Date, from = 'YYYY-MM-DD', to = 'DD/MM/YYYY') => {
  return date ? moment(date, from).format(to) : ''
}

export const isValidDate = (value: string) => {
  return moment(value, 'YYYY-MM-DD').isValid()
}

export const reverseDate = (datestr = '', splitWith = '/', joinWith = '/') => {
  return datestr?.split(splitWith).reverse().join(joinWith)
}

export const compareDates = (date1?: Date, date2?: Date) => {
  if (!date1 || !date2 || isNaN(date1?.getTime()) || isNaN(date2?.getTime())) {
    return -2
  }
  if (moment(date1).format('DD/MM/YYYY') === moment(date2).format('DD/MM/YYYY')) {
    return 0
  }
  return date1 < date2 ? -1 : 1
}

export const compareFullDates = (date1?: Date, date2?: Date) => {
  if (!date1 || !date2 || isNaN(date1?.getTime()) || isNaN(date2?.getTime())) {
    return -2
  }
  if (moment(date1).format('DD/MM/YYYYTHH:mm') === moment(date2).format('DD/MM/YYYYTHH:mm')) {
    return 0
  }
  return date1 < date2 ? -1 : 1
}

export const getDifferenceInDays = (d1?: string, d2?: string) => {
  if (d1 !== undefined && d1 !== '' && d2 !== undefined && d2 !== '') {
    const date1 = new Date(d1.split('/').reverse().join('/')).valueOf()
    const date2 = new Date(d2.split('/').reverse().join('/')).valueOf()
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60 * 60 * 24)
  }
  return -1
}

export const addDays = (days: number) => {
  const date = new Date()
  date.setDate(date.getDate() + days)
  return date
}

export const addStartDays = (start_date: string, days: number) => {
  const date = new Date(start_date)
  date.setDate(date.getDate() + days)
  return date
}

export const extactTimeFromDateString = (date: string | undefined) => {
  if (!date) return ''

  return date?.split('T')[1]?.slice(0, 5) || ''
}

export const extractDateFromDateString = (date: string | undefined) => {
  if (!date) return ''

  return date?.split('T')[0]?.split('-')?.reverse()?.join('/') || ''
}
