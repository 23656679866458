import { DownloadDocumentIcon } from "src/assets";
import { Button } from "src/widgets/button/button";
import { useSection7 } from "./section7.hook";

export const Section7 = () => {
  const { t, language, documents, downloadFile } = useSection7();

  const Title = (
    <h3 className="mb-4 section7-dynamic-size-title d-flex align-item-center text-uppercase">
      {t("landing.section_7_title")}
    </h3>
  );

  const Subtitle = (
    <div className="mb-4 section7-dynamic-size-subtitle d-flex align-items-center white-space-pre-subtitle">
      {t("landing.section_7_subtitle")}
    </div>
  );

  const Documents = (
    <div className="pt-4 pt-md-0">
      {(documents || [])?.length > 0 &&
        documents.map((document) => (
          <div key={document?.id}>
            {document?.id && (
              <div>
                <div className="ps-md-3 pe-md-3 d-flex align-items-center justify-content-between">
                  <div className="section7-dynamic-size-document-title">
                    {document?.description && document?.description[language]}
                  </div>

                  <Button
                    text={t("commons.download")}
                    iconRight={DownloadDocumentIcon}
                    iconSize={18}
                    flavor="transparentAccent"
                    classes="p-2 pb-0 ps-3 pe-3 pe-md-0 d-flex align-items-center"
                    classesText="font-20-ag-400 c-4145e5"
                    onPress={() => downloadFile(document)}
                  />
                </div>
                <div className="section7-separator"></div>
              </div>
            )}
          </div>
        ))}
    </div>
  );

  return (
    <div className="col-xl-12 d-xl-flex justify-content-center">
      <div className="col-xl-6">
        {Title}
        {Subtitle}
      </div>
      <div className="col-xl-6">{Documents}</div>
    </div>
  );
};
