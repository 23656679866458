import { call, put } from 'redux-saga/effects'
import { http_quote } from 'src/http'
import { saga_act } from 'src/types'
import { quote_outcome_t } from 'src/types/http/quote.types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Get quote
 */
export function* saga_quote(action: saga_act): unknown {
  const quote: quote_outcome_t = yield call(
    saga_do_request,
    http_quote,
    action
  );
  if (quote) {
    yield put(shoot.reduce_quote(quote));
  }
}
