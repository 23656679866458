/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, takeLatest } from 'redux-saga/effects'

import * as acts from '../actions'
import * as certificate from './certificate.saga'
import * as cookie_templates from './cookie.saga'
import * as payment from './payment.saga'
import * as registration from './registration.saga'
import * as evaluate_premium from './evaluate-premium.saga'
import * as product from './product.saga'
import * as quote from './quote.saga'
import * as startup from './startup.saga'

export default function* root() {
  yield all([
    /**
     * Startup sagas
     */
    takeLatest(acts.startup_types.SAGA_STARTUP, startup.saga_startup),
    takeLatest(
      acts.startup_types.SAGA_INVALIDATE_CACHE,
      startup.saga_invalidate_cache
    ),
    // Certificate
    takeLatest(acts.data_types.SAGA_CERTIFICATE, certificate.saga_certificate),
    // Payment
    takeLatest(acts.data_types.SAGA_PAYMENT, payment.saga_payment),
    // Registration
    takeLatest(acts.data_types.SAGA_REGISTRATION, registration.saga_registration),
    // Product
    takeLatest(acts.data_types.SAGA_PRODUCT, product.saga_product),
    // Evaluate premium
    takeLatest(acts.data_types.SAGA_EVALUATE_PREMIUM, evaluate_premium.saga_evaluate_premium),
    // Quote
    takeLatest(acts.data_types.SAGA_QUOTE, quote.saga_quote),
    // Cookie
    takeLatest(acts.data_types.SAGA_COOKIE_TEMPLATES, cookie_templates.saga_cookie_templates),
  ]);
}
