import './recaptcha.scss'

import { FC, useEffect } from 'react'
import { WEBAPP_SITE_KEY } from 'src/config'
import { recaptcha_window } from 'src/types'

const SITE_KEY = WEBAPP_SITE_KEY;

const id = "recaptcha-key";
const url = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;

export const Recaptcha: FC = () => {
  /**
   * UseEffect
   */
  useEffect(() => {
    const recaptcha_container = document.getElementById(id);
    if (!recaptcha_container) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      document.body.appendChild(script);
    }
  }, []);

  return <div />;
};

export const submit_captcha = (): Promise<string> => {
  return new Promise<string>((resolve) => {
    const recaptcha: recaptcha_window = window;
    const g_recaptcha = recaptcha?.grecaptcha;
    if (g_recaptcha) {
      g_recaptcha?.ready(() => {
        resolve(g_recaptcha?.execute(SITE_KEY || "", { action: "submit" }));
      });
    }
  });
};
