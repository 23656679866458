import { SelfCanonicalPath } from "src/navigation";

const CanonicalMetaTag = () => {
  const currentPath = window.location.pathname;

  if (SelfCanonicalPath?.includes(currentPath)) {
    return <meta name="robots" content="self-canonical" />;
  }
  // Do not render self-canonical tag for excluded pages
  return null;
};

export default CanonicalMetaTag;
