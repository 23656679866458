import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { select_history_item } from "src/redux/store";
import {
  DataEntryStep1Props,
  InputSelectOption,
  coverages_t,
  option_t,
} from "src/types";
import { get_language } from "src/utils";

export const useDataEntryStep1 = (props: DataEntryStep1Props) => {
  const { t } = useTranslation();
  const { minMaxContractor } = props;

  /**
   * useSelector
   */
  const coverages: coverages_t = useSelector(
    select_history_item("history_coverages")
  );

  /**
   * useState
   */
  const [coverageOptions, setCoverageOptions] = useState<option_t[]>();
  const [insuredNumberOptions, setInsuredNumberOptions] = useState<
    InputSelectOption[]
  >([]);

  /**
   * useEffect
   */

  useEffect(() => {
    const options: InputSelectOption[] = [];
    Array.from({ length: minMaxContractor?.max || 1 }, (_, item) =>
      options?.push({
        id: (item + 1)?.toString(),
        value: (item + 1)?.toString(),
      })
    );

    setInsuredNumberOptions(options);
  }, [minMaxContractor]);

  useEffect(() => {
    if (coverages) {
      const coverages_: option_t[] = Object.entries(coverages).map(
        ([key, value]) => {
          return {
            id: key,
            value: value?.name?.[get_language().toLowerCase()] || "",
          };
        }
      );
      setCoverageOptions(coverages_);
    }
  }, [coverages]);

  return { t, coverageOptions, insuredNumberOptions };
};
