import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/navigation";
import { select_history_item } from "src/redux/store";
import { document_t } from "src/types";
import { get_document_by_id, get_language } from "src/utils";
import "./section2.scss";

export const useSection2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const PACKAGE_BASE_PRICE: Record<string, string> = {
    id_daily: "3,50 €",
    id_weekly: "18,50 €",
    id_monthly: "31 €",
    id_quarterly: "50 €",
  };

  const PACKAGE_PLUS_PRICE: Record<string, string> = {
    id_daily: "4,50 €",
    id_weekly: "24 €",
    id_monthly: "40,50 €",
    id_quarterly: "65 €",
  };

  /**
   * useSelector
   */
  const documents: document_t[] = useSelector(
    select_history_item("history_documents")
  );

  /**
   * useState
   */
  const [language] = useState(get_language().toLocaleLowerCase());
  const [selectedPeriod, setSelectedPeriod] = useState("id_daily");
  const [selectedPackage, setSelectedPackage] = useState("base");
  const [informativeFile, setInformativeFile] = useState<document_t>();
  const [goNext, setGoNext] = useState(false);

  /**
   * useEffect
   */
  useEffect(() => {
    if (goNext) {
      setGoNext(false);
      navigate(
        Paths.DataEntry +
          `?package=${
            selectedPackage === "base" ? 1 : selectedPackage === "plus" ? 2 : ""
          }&coverage=${selectedPeriod}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goNext, selectedPeriod, selectedPackage]);

  useEffect(() => {
    if (documents) {
      const informativeSetFile_ = get_document_by_id(documents, "1");
      if (informativeSetFile_) setInformativeFile(informativeSetFile_);
    }
  }, [documents]);

  /**
   * Functions
   */
  const nextPage = () => {
    setGoNext(true);
  };

  const openInformationSetFile = () => {
    if (informativeFile && informativeFile?.file) {
      window.open(
        informativeFile?.file[language]?.replaceAll('"', "").toString(),
        "_blank"
      );
    }
  };

  return {
    t,
    PACKAGE_BASE_PRICE,
    PACKAGE_PLUS_PRICE,
    selectedPeriod,
    selectedPackage,
    setSelectedPeriod,
    setSelectedPackage,
    nextPage,
    openInformationSetFile,
  };
};
