import { response_t, saga_act } from 'src/types'

import { do_request } from './base.http'

/**
 * Product
 */
export async function http_product({
  args,
  type,
}: saga_act): Promise<response_t<string>> {
  let value = await do_request<string>({
    path: "/product",
    args: args,
    saga_type: type,
  });

  return value;
}
