import { response_t, saga_act } from 'src/types'
import { evaluate_premium_t } from 'src/types/http/evaluate-premium.types'

import { do_request } from './base.http'

/**
 * evaluate_premium
 */
export async function http_evaluate_premium({
  args,
  type,
}: saga_act): Promise<response_t<evaluate_premium_t>> {
  let value = await do_request<evaluate_premium_t>({
    path: "/evaluate-premium",
    args: args,
    saga_type: type,
  });

  return value;
}
