import i18n from 'src/i18n'

export const MONTHS: string[] = [
  i18n.t('months.january'),
  i18n.t('months.february'),
  i18n.t('months.march'),
  i18n.t('months.april'),
  i18n.t('months.may'),
  i18n.t('months.june'),
  i18n.t('months.july'),
  i18n.t('months.august'),
  i18n.t('months.september'),
  i18n.t('months.october'),
  i18n.t('months.november'),
  i18n.t('months.december'),
]

export const DAYS: string[] = [
  i18n.t('days_abbreviation.mon'),
  i18n.t('days_abbreviation.tue'),
  i18n.t('days_abbreviation.wed'),
  i18n.t('days_abbreviation.thu'),
  i18n.t('days_abbreviation.fri'),
  i18n.t('days_abbreviation.sat'),
  i18n.t('days_abbreviation.sun'),
]

export const toDate = (datestr = '') => {
  const fields = datestr?.replaceAll(' ', '')?.split('/')
  const DD = +fields[0]
  const MM = (+fields[1] || new Date().getMonth()) - 1
  const YYYY = fields[2]?.length === 4 ? +fields[2] : new Date().getFullYear()
  return new Date(YYYY, MM, DD)
}

export const toDateString = (date: Date) => {
  const DD = date.getDate()
  const MM = date.getMonth() + 1
  const YYYY = date.getFullYear()
  const DD_ = `${DD}`?.length === 1 ? `0${DD}` : `${DD}`
  const MM_ = `${MM}`?.length === 1 ? `0${MM}` : `${MM}`
  return `${DD_}/${MM_}/${YYYY}`
}

export const easyInsertion = (datestr = '') => {
  const str = datestr.substring(0, 10)
  const slash1 = str.match(/^(0[1-9]|[12][0-9]|3[01])$/)
  const slash2 = str.match(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])$/)

  return slash1 || slash2 ? `${str}/` : str
}
