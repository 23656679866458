import { Checkbox, RadioGroup } from "react-component-library";
import { DataEntryStep1Props } from "src/types";
import { get_error } from "src/utils";
import { InputSelect } from "src/widgets/input-select/input-select";
import { InputText } from "src/widgets/input-text/input-text";
import { Tooltip } from "src/widgets/tooltip/tooltip";
import { useDataEntryStep1 } from "./data-entry-step1.hook";

export const DataEntryStep1 = (props: DataEntryStep1Props) => {
  const { t, coverageOptions } = useDataEntryStep1(props);

  const {
    daysOptions,
    dailyOptions,
    formik: {
      values,
      errors,
      touched,
      getFieldProps,
      setFieldValue,
      handleChange,
      handleBlur,
    },
    validationSchema,
  } = props;

  const CoverageRadio = (
    <div className="col-sm-12 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-14">
      <RadioGroup
        id={"coverage_type_radios"}
        formik_props={getFieldProps("coverage_type_radios")}
        formik_schema={validationSchema}
        error={get_error(errors?.coverage_type_radios)}
        touched={touched?.coverage_type_radios}
        label={t("data_entry_step_1.coverage_type_title")}
        options={coverageOptions}
        classes_label="m-0 p-0 font-16 d-flex align-items-center mb-3"
        on_change={(id) => setFieldValue("coverage_type_radios", id)}
        vertically={true}
        classes="m-0 p-0 mb-3"
      />
    </div>
  );

  const CoverageInsuredNumber = (
    <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
      <InputText
        name="insured_number"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.insured_number}
        value={values.insured_number}
        error={errors.insured_number}
        type="number"
        label={t("data_entry_step_1.coverage_insured_number")}
        placeholder={t("data_entry_step_1.coverage_insured_number_placeholder")}
        classesError="font-15 m-0 c-862633"
        classesLabel="font-15"
      />
    </div>
  );

  const InsuredDays = (
    <div className="col-12 d-md-flex">
      <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
        <InputSelect
          name="insured_days"
          validationSchema={validationSchema}
          setFieldValue={setFieldValue}
          options={daysOptions}
          touched={touched.insured_days}
          value={values.insured_days}
          error={errors.insured_days}
          label={t("data_entry_step_1.coverage_insured_days")}
        />
      </div>
      {values?.insured_days === "daily" && (
        <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputSelect
            name="insured_daily"
            validationSchema={validationSchema}
            setFieldValue={setFieldValue}
            options={dailyOptions}
            touched={touched.insured_daily}
            value={values.insured_daily}
            error={errors.insured_daily}
            label={`${t("data_entry_step_1.coverage_insured_daily")}*`}
          />
        </div>
      )}
    </div>
  );

  const FamilyField = (
    <div className="col-sm-12 p-0 ps-md-3 pe-md-3">
      <div className="mb-4 mt-3 d-flex">
        <Checkbox
          id="is_family"
          classes=""
          formik_props={getFieldProps("is_family")}
          formik_schema={validationSchema}
          error={get_error(errors?.is_family)}
          touched={touched?.is_family}
          label={t("data_entry_step_3.is_family")}
          disabled={
            Number(values?.insured_number) <= 1 ||
            Number(values?.insured_number) > 7 ||
            (get_error(errors?.insured_number) !== "" &&
              get_error(errors?.insured_number) !== undefined &&
              touched?.insured_number)
          }
          classes_label="m-0 font-20 d-flex align-items-baseline white-space-pre me-2"
        />
        <Tooltip placement="top">
          {
            <div className="font-14">
              {t("data_entry_step_3.is_family_tooltip_label")}
            </div>
          }
        </Tooltip>
      </div>
    </div>
  );
  return (
    <div>
      {CoverageRadio}
      {CoverageInsuredNumber}
      {InsuredDays}
      {FamilyField}
    </div>
  );
};
