import { document_t, FiscalCode, Optional } from "src/types";
import { Base64 } from "js-base64";

import * as FiscalCodeUtils from "@marketto/codice-fiscale-utils";

import i18n from "../i18n";

const { Parser, Validator } = FiscalCodeUtils;

export const get_language = (): "IT" | "DE" | "EN" => {
  var language = i18n.language.slice(0, 2).toUpperCase();
  return language === "DE" ? "DE" : language === "EN" ? "EN" : "IT";
};

export const get_error = (item?: any, key?: string) => {
  let error_ = "";
  if (key) error_ = item?.[key];
  else {
    error_ = item;
  }
  return error_;
};

export const get_touch = (item?: any, key?: string) => {
  let touch_ = false;
  if (key) touch_ = item?.[key];
  else {
    touch_ = item;
  }
  return touch_ || false;
};

export const get_value = (item?: any, key?: string) => {
  let value_ = "";
  if (key) value_ = item?.[key];
  else {
    value_ = item;
  }
  return value_;
};

export const get_document_by_id = (documents: document_t[], id: string) => {
  if (documents && id) {
    return documents?.filter((document) => document?.id === id)[0];
  }
  return;
};

export const checkFiscalCode = (
  name: string,
  surname: string,
  fiscalCode: string
): boolean => {
  return (
    (Validator.codiceFiscale(fiscalCode).matchFirstName(
      name?.replaceAll(" ", "")
    ) &&
      Validator.codiceFiscale(fiscalCode).matchLastName(
        surname?.replaceAll(" ", "")
      )) ||
    false
  );
};

export const generateFiscalCode = ({
  name,
  surname,
  birthDate,
  gender,
  birthPlace,
}: FiscalCode): string => {
  return (
    Parser.encodeCf({
      lastName: surname?.trim(),
      firstName: name?.trim(),
      date: new Date(birthDate?.split("/")?.reverse()?.join("/")),
      gender: gender,
      place: birthPlace,
    }) || ""
  );
};

export const concatClasses = (...classes: Optional<string>[]) => {
  return classes?.filter((x) => x)?.join(" ");
};

export const fromBase64ToBlob = (base64String: string) => {
  const base64WithoutPrefix = base64String?.replace(
    "data:application/pdf;base64,",
    ""
  );

  const bytes = Base64.atob(base64WithoutPrefix || "");
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  const blob = new Blob([out], { type: "application/pdf" });

  return blob;
};

export const replaceDotPhoneFormat = (formatterPath: string, formattedNumber: string) => {
  let result = ''
  let dotIndex = 0

  for (const char of formatterPath) {
    if (char === '.') {
      if (dotIndex < formattedNumber.length) {
        result += formattedNumber[dotIndex]
        dotIndex += 1
      } else {
        result += 'X'
      }
    } else {
      result += char
    }
  }

  console.log('result', result)

  return result
}
