import React from 'react'
import { Image } from 'react-bootstrap'

import { failure_icon } from '../../../assets'
import { FailureModalPropsCustom } from '../../../types/Modal'
import Button from '../../button/Button'
import Modal from '../Modal'

const FailureModal: React.FC<FailureModalPropsCustom> = ({
  error,
  icon = failure_icon,
  icon_size = 72,
  description = error?.description_fe,
  classes_description = "mt-4",
  button_text,
  button_flavor,
  classes_button_text = "text-uppercase",
  on_close,
  button = {
    flavor: button_flavor ? button_flavor : "primary_next",
    classes_text: classes_button_text,
    onClick: () => {
      on_close?.();
      window.location.reload();
    },
  },
  classes_button = "mt-4 mb-2 d-flex align-items-center justify-content-center",
}: FailureModalPropsCustom) => {
  return (
    <Modal visible={!!error?.description_fe}>
      <div className="d-flex align-items-center justify-content-center p-1">
        <div className="d-inline text-center">
          {icon && <Image src={icon} width={icon_size} />}
          {description && <p className={classes_description}>{description}</p>}
          {button_text && (
            <div className={classes_button}>
              <Button {...button}>{button_text}</Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FailureModal;
