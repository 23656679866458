import {
  Accessibility,
  CookiePolicy,
  DataEntry,
  Faq,
  Landing,
  PageNotFound,
  PaymentOutcomeKo,
  PaymentOutcomeOk,
} from "src/containers";
import { Route } from "src/types";

export * from "./saga-guards";

export const Paths = {
  LandingId: "/:id",
  Landing: "/",
  DataEntry: "/inserimento-dati",
  PaymentOutcomeOk: "/esito-pagamento-ok",
  PaymentOutcomeKo: "/esito-pagamento-ko",
  CookiePolicy: "/cookie-policy",
  Faq: "/faq",
  Accessibility: "/accessibilita",
  PageNotFound: "*",
};

export const SelfCanonicalPath = [
  "/",
  "/cookie-policy",
  "/faq",
  "/accessibilita",
];

export const Routes: Record<keyof typeof Paths, Route> = {
  LandingId: {
    path: Paths.LandingId,
    element: <Landing />,
    private: true,
  },
  Landing: {
    path: Paths.Landing,
    element: <Landing />,
    private: true,
  },
  PaymentOutcomeOk: {
    path: Paths.PaymentOutcomeOk,
    element: <PaymentOutcomeOk />,
    private: true,
  },
  PaymentOutcomeKo: {
    path: Paths.PaymentOutcomeKo,
    element: <PaymentOutcomeKo />,
    private: true,
  },
  DataEntry: {
    path: Paths.DataEntry,
    element: <DataEntry />,
    private: true,
  },
  Faq: {
    path: Paths.Faq,
    element: <Faq />,
    private: true,
  },
  Accessibility: {
    path: Paths.Accessibility,
    element: <Accessibility />,
    private: true,
  },
  CookiePolicy: {
    path: Paths.CookiePolicy,
    element: <CookiePolicy />,
  },
  PageNotFound: {
    path: Paths.PageNotFound,
    element: <PageNotFound />,
  },
};
