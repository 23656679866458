import { call, put } from 'redux-saga/effects'
import { http_certificate } from 'src/http'
import { saga_act } from 'src/types'
import { certificate_outcome_t } from 'src/types/http/certificate.types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Get certificate
 */
export function* saga_certificate(action: saga_act): unknown {
  const certificate: certificate_outcome_t = yield call(
    saga_do_request,
    http_certificate,
    action
  );
  if (certificate) {
    yield put(shoot.reduce_certificate(certificate));
  }
}
