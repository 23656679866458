import "./faq.scss";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ItasMinusIcon, ItasPlusIcon, LeftArrowIcon } from "src/assets";
import { Paths } from "src/navigation";
import { shoot } from "src/redux/actions";
import { select_history_item } from "src/redux/store";
import { faq_t } from "src/types";
import { get_language } from "src/utils";
import Accordion from "src/widgets/accordion/Accordion";
import { Button } from "src/widgets/button/button";

export const Faq = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * UseState
   */
  const [language] = useState(get_language().toLocaleLowerCase());

  /**
   * UseSelector
   */
  const faqs: faq_t[] = useSelector(select_history_item("history_faqs"));

  /**
   * UseEffect
   */
  useEffect(() => {
    dispatch(shoot.reduce_history_item("history_is_landing", false));
    dispatch(shoot.reduce_history_item("history_is_faq", true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Title = (
    <h3 className="mb-4 faq-title-size d-flex align-item-center text-uppercase">
      {t("landing.section_8_title")}
    </h3>
  );

  const Subtitle = (
    <div className="mb-4 faq-subtitle-size d-flex align-items-center">
      {t("landing.section_8_subtitle")}
    </div>
  );

  const Faqs = (
    <div>
      {faqs &&
        faqs?.length > 0 &&
        faqs.map((item: faq_t) => {
          return (
            <Accordion
              key={item?.id}
              icon_size={24}
              icon_open={ItasMinusIcon}
              icon_close={ItasPlusIcon}
              button_read={false}
              classes="mb-5 pb-3 ps-md-3 pe-md-3"
              classes_icon_open="section8-open-icon"
              separator="faq-separator"
              head={
                <h2 className="m-0 p-0 me-3 mb-2 section8-dynamic-size-faqs-title col-8">
                  {item?.request?.[language]}
                </h2>
              }
            >
              <p className="mb-3 white-space-pre">{item?.answer?.[language]}</p>
            </Accordion>
          );
        })}
    </div>
  );

  return (
    <div className="bg-ffffff">
      <Helmet>
        <title>{t("meta.faq.title")}</title>
        <meta name="description" content={t("meta.faq.description")} />
      </Helmet>
      <Button
        flavor="transparentAccent"
        classes="mt-3 px-0 ps-3 ps-md-5 ms-md-2"
        classesText="text-uppercase font-14-semibold c-4145e5"
        text={t("commons.back")}
        iconLeft={LeftArrowIcon}
        classesIconLeft="me-2 filter-4145e5"
        onPress={() => {
          navigate(Paths.Landing);
        }}
      />

      <div className="mt-5 d-flex align-items-center justify-content-center text-center">
        {Title}
      </div>
      <div className="mb-md-5 pb-5 d-flex align-items-center justify-content-center text-center">
        {Subtitle}
      </div>
      <div className="ps-4 pe-4 d-md-flex justify-content-center">
        <div className="col-md-7">{Faqs}</div>
      </div>
    </div>
  );
};
