import { Image, Loader, Modal } from "react-component-library";
import {
  ConfirmedBoldIcon,
  DeleteIcon,
  FailureIcon,
  ItasCamera,
  ItasGallery,
  Upload,
} from "src/assets";
import { DataEntryStepCustom2Props, MultiLanguageString } from "src/types";
import { get_language } from "src/utils";
import { Button } from "src/widgets/button/button";
import ScrollToTop from "src/widgets/scroll-to-top/scroll-to-top";
import { Tooltip } from "src/widgets/tooltip/tooltip";
import { useDataEntryCustomStep2 } from "./data-entry-custom-step2.hook";

export const DataEntryCustomStep2 = (props: DataEntryStepCustom2Props) => {
  const {
    t,
    file,
    loadingFile,
    inputFileAccept,
    showUploadModal,
    isOutOfSizeFile,
    isMobile,
    uploadDocument,
    deleteDocument,
    setShowUploadModal,
    setIsOutOfSizeFile,
  } = useDataEntryCustomStep2(props);

  const {
    agency,
    formik: { errors },
  } = props;

  const Title = (
    <div className="font-28-semibold text-center mb-2">
      {!agency?.isCustomPaymentLabel
        ? t("data_entry_step_custom_2.title")
        : t("data_entry_step_custom_2.title_custom_label")}
    </div>
  );

  const Subtitle = (
    <div className="font-20 text-center mb-2 c-212529">
      {
        agency?.customPaymentLabel?.offlineLabelReceipt?.[
          get_language()?.toLowerCase() as keyof MultiLanguageString
        ]
      }
    </div>
  );

  const Border = (
    <div className="d-flex justify-content-center">
      <div className="col-lg-9 d-block">
        <div className="mt-3 mb-3 border-top" />
      </div>
    </div>
  );

  const UploadFromDesktop = (
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <div
        className={`${
          file
            ? "custom-input-upload"
            : "custom-input-upload custom-label-pointer"
        }`}
      >
        <label htmlFor="upload-input">
          <Image src={Upload} width={100} height={100} />
        </label>
        {!file && (
          <input
            onChange={(e) => {
              if (e?.target?.files) uploadDocument(e?.target?.files[0]);
            }}
            type="file"
            accept={inputFileAccept}
            className="d-none"
            id="upload-input"
          />
        )}
      </div>
    </div>
  );

  const ModalUpload = (
    <div>
      <div className="custom-modal-backgroud-upload" />
      <div className="custom-modal-upload">
        <div className="p-3 bg-ffffff h-100">
          <div className="d-flex justify-content-center align-items-center font-16-semibold">
            {t("data_entry_step_custom_2.upload_title")}
          </div>

          <div
            className={`"mt-4 ${
              file
                ? "custom-input-upload custom-label-pointer"
                : "custom-input-upload"
            }`}
          >
            <label htmlFor="upload-input-gallery">
              <div className="d-flex align-items-center">
                <Image src={ItasGallery} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">
                  {t("data_entry_step_custom_2.upload_gallery")}
                </div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) uploadDocument(e?.target?.files[0]);
              }}
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id="upload-input-gallery"
            />
          </div>

          <div
            className={`"mt-4 ${
              file
                ? "custom-input-upload custom-label-pointer"
                : "custom-input-upload"
            }`}
          >
            <label htmlFor="upload-input-photo">
              <div className="d-flex align-items-center">
                <Image src={ItasCamera} width={24} height={24} />
                <div className="ms-3 font-16 c-7f7f7f">
                  {t("data_entry_step_custom_2.upload_photo")}
                </div>
              </div>
            </label>
            <input
              onChange={(e) => {
                if (e?.target?.files) uploadDocument(e?.target?.files[0]);
              }}
              type="file"
              accept={inputFileAccept}
              capture="environment"
              className="d-none"
              id="upload-input-photo"
            />
          </div>

          <Button
            flavor="accent"
            classes="mt-4 ps-4 pe-4 d-flex justify-content-center m-auto w-100"
            classesText="font-14-semibold text-uppercase"
            onPress={() => setShowUploadModal(false)}
            text={t("commons.cancel")}
          />
        </div>
      </div>
    </div>
  );

  const ModalError = (
    <Modal
      visible={isOutOfSizeFile}
      on_close={() => setIsOutOfSizeFile(false)}
      classes="d-flex justify-content-center"
    >
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <Image src={FailureIcon} width={64} height={64} />
        </div>
        <div className="m-3 font-14">{t("commons.max_exceeded")}</div>
      </div>
    </Modal>
  );

  const UploadFromMobile = (
    <div className="mt-4 d-flex justify-content-center align-items-center">
      <div onClick={() => !file && setShowUploadModal(true)}>
        <Image src={Upload} width={100} height={100} />
      </div>
    </div>
  );

  const TooltipInfo = (
    <div className="font-14 c-292929 d-flex flex-column align-items-start">
      <div>{t("commons.tooltip_info_file_1")}</div>
      <div>{t("commons.tooltip_info_file_2")}</div>
    </div>
  );

  const UploadLabel = (
    <div className="mt-3 d-flex align-items-center justify-content-center">
      <div className="font-14 c-7f7f7f">
        {t("data_entry_step_custom_2.upload_label")}
      </div>
      <div className="ps-1">
        <Tooltip placement="top" icon_size={15} classes_icon="d-flex">
          {TooltipInfo}
        </Tooltip>
      </div>
    </div>
  );

  const InfoFile = (
    <div className="d-flex justify-content-center align-items-center">
      <div className="p-2 mt-3 bg-f5e9da col-12 col-md-8 d-flex align-items-center">
        <div className="col-1 d-md-flex justify-content-start">
          <Image src={ConfirmedBoldIcon} width={20} height={20} />
        </div>
        <div className="ps-3 col-10 font-16-semibold">{file?.name}</div>
        <div className="col-1 d-md-flex justify-content-end cursor-pointer">
          <Image
            src={DeleteIcon}
            width={20}
            height={20}
            onClick={deleteDocument}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="mb-1 mb-md-5 pb-1 ps-md-3 pe-md-3">
      <ScrollToTop />
      <div className="border border-1 rounded-3 d-flex justify-content-center container-fluid bg-ffffff pt-4 pb-4">
        <div className="col-12 col-md-12 col-xxl-9 pb-md-5">
          {Title}
          {agency?.isCustomPaymentLabel && Subtitle}
          {Border}
          {isMobile ? UploadFromMobile : UploadFromDesktop}
          {UploadLabel}
          {file && InfoFile}
          {showUploadModal && ModalUpload}
          {isOutOfSizeFile && ModalError}
          {errors?.registration_file && (
            <div className="ps-1 mt-4 font-14 c-862633">
              {t("data_entry_step_custom_2.missing_document")}
            </div>
          )}
        </div>
      </div>
      {loadingFile && <Loader overlay />}
    </div>
  );
};
