import { call, put } from 'redux-saga/effects'
import { http_evaluate_premium } from 'src/http'
import { saga_act } from 'src/types'
import { evaluate_premium_t } from 'src/types/http/evaluate-premium.types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Get evaluate_premium
 */
export function* saga_evaluate_premium(action: saga_act): unknown {
  const evaluate_premium: evaluate_premium_t = yield call(
    saga_do_request,
    http_evaluate_premium,
    action
  );
  if (evaluate_premium) {
    yield put(shoot.reduce_evaluate_premium(evaluate_premium));
  }
}
