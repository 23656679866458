import './input-select.scss'

import { useState } from 'react'
import { InputSelectOption, InputSelectProps } from 'src/types'
import { concatClasses } from 'src/utils'

export const useInputSelect = ({
  name = '',
  value: selectedOptionId,
  touched,
  disabled,
  error,
  setFieldValue,
  setFieldTouched,
  validateField,
  classesInput = 'input-select--input',
  classesHead = 'input-select--head',
  classesField = 'input-select--field',
  classesError = 'input-select--error',
  classesIcon = 'input-select--icon',
}: InputSelectProps) => {
  const [expanded, setExpanded] = useState(false)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const toggleMenu = () => setExpanded((x) => !x)

  const updateTouched = () => {
    if (expanded) {
      setFieldTouched?.(name, true)
    }
  }

  const updateOption = (option: InputSelectOption) => {
    setExpanded(false)
    setFieldValue?.(name, option?.id)
    setTimeout(() => validateField?.(name))
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const conditionalClasses =
    touched && error
      ? {
          head: 'input-select--border-error',
          field: 'input-select--border-error',
          error: '',
        }
      : {
          head: '',
          field: 'input-select--border-normal',
          error: 'opacity-0',
        }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const allClassesArrow = concatClasses(
    classesIcon,
    expanded && !disabled ? '' : 'input-select--rotate'
  )

  const allClassesInput = concatClasses(classesInput, selectedOptionId ? '' : 'color-disabled')

  const allClassesHead = concatClasses(
    classesHead,
    conditionalClasses.head,
    disabled ? 'input-select--head-disabled' : 'cursor-pointer'
  )

  const allClassesField = concatClasses(classesField, conditionalClasses.field)

  const allClassesError = concatClasses(classesError, conditionalClasses.error)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return {
    expanded,
    toggleMenu,
    updateTouched,
    updateOption,
    allClassesArrow,
    allClassesInput,
    allClassesHead,
    allClassesField,
    allClassesError,
  }
}
