import { FieldInputProps } from "formik";
import React from "react";
import { AnyObjectSchema } from "yup";
import { Formik, FormikDataEntryValues, FormikOtherInsured } from "./formik";
import { RegistrationFileProps } from "./http/Registration.types";
import { InputSelectOption } from "./widgets/input-select.types";
import { CountryData } from "react-phone-input-2";

export type Route = {
  path: string;
  element: JSX.Element;
  private?: boolean;
};

export type Callbacks<T = () => void> = Record<string, T>;

/**
 * Type
 */
export type gender_t = "M" | "F";

export type nationality_t = "IT" | "EXT";

export type contractor_contact_t = "email" | "mobile";

export type dict<T = unknown> = Record<string, T>;

export type opt<T> = T | undefined;

export type saga_act<T = dict> = { type: string; args: T };

export const typedMemo: <T>(c: T) => T = React.memo;

export type Optional<T> = T | undefined;

/**
 * Option
 */
export interface option_t {
  id: string | number;
  value: string;
}

export interface SelectProps {
  id: string;
  value: string;
}

/**
 * Formik
 */
export interface formik_item<T = string> {
  id?: T;
  formik_props?: FieldInputProps<string>;
  formik_schema?: AnyObjectSchema;
  touched?: boolean;
  disabled?: boolean;
  label?: string;
  error?: string;
}

/**
 * Formik error
 */
export interface formik_check_error_t {
  errors?: any;
  update?: string;
}

/**
 * Formik Values
 */
export interface formik_value_t {
  coverage_radios?: string;
  coverage_type_radios?: string;
  coverage_start_date?: string;
  coverage_end_date?: string;
  convention?: string;
  insured_number?: string;
  insured_days?: string;
  insured_name?: string;
  insured_surname?: string;
  insured_email?: string;
  insured_email_confirm?: string;
  insured_phone?: {
    country?: CountryData
    formattedPhone: string
    value?: string
  }
  insured_cap?: string;
  insured_nationality?: string;
  insured_fc_question?: string;
  insured_birth?: string;
  insured_birth_nation?: string;
  insured_birth_place?: string;
  insured_gender?: string;
  insured_fc?: string;
  consent_td?: boolean;
  consent_privacy?: boolean;
  consent_marketing?: boolean;
  consent_profiling?: boolean;
  consent_communication?: boolean;
  insured_list?: FormikOtherInsured[];
  informative_vision?: boolean;
  is_family?: boolean;
  discount?: string;
  registration_file?: RegistrationFileProps | undefined;
}

/**
 * --- Components type ---
 */

/**
 * Seasonal
 */
export interface seasonal_t {
  seasonal_end_date?: string;
}

/**
 * Contractor Min - Max
 */
export interface MinMaxContractor {
  max?: number;
  min?: number;
}

/**
 * Domain
 */
export interface domain_t {
  id?: string;
  name?: language_t;
  values?: structure_t[];
}

/**
 * Domains
 */
export interface domains_t {
  [key: string]: domain_t;
}

/**
 * Structure
 */
export interface structure_t {
  id?: string;
  name?: language_t;
}

/**
 * Agency quote
 */
export interface agency_quote_t {
  id?: string;
  name?: string;
  structure?: string;
  structureName?: string;
  isCustomActive?: boolean;
  isOriginalPayment?: boolean;
  isCustomPaymentLabel?: boolean;
}

/**
 * Agency
 */
export type AgencyCompleteAddress = {
  country?: string;
  province?: string;
  town?: string;
  location?: string;
  address?: string;
};

export interface MultiLanguageString {
  it?: string;
  en?: string;
  de?: string;
}

export type ProductDocuments = {
  id: string;
  description: MultiLanguageString;
  file: MultiLanguageString;
};

export type ProductCoverageDocumentsOffline = {
  base?: ProductDocuments[];
  plus?: ProductDocuments[];
};

export type CustomPaymentLabel = {
  offlineLabelCertificate?: MultiLanguageString;
  offlineLabelReceipt?: MultiLanguageString;
};

export interface agency_product_t {
  id?: string;
  name?: string;
  type?: string;
  structures?: structure_t[];
  isCustomActive?: boolean;
  isOriginalPayment?: boolean;
  denomination?: string;
  ruiNumber?: string;
  operationalHeadquartersCompleteAddress?: AgencyCompleteAddress;
  registeredOfficeCompleteAddress?: AgencyCompleteAddress;
  phoneNumber?: string;
  pecMail?: string;
  mail?: string;
  policyNumberBase?: string;
  policyNumberPlus?: string;
  documents?: ProductDocuments[];
  isCustomPaymentLabel?: boolean;
  customPaymentLabel?: CustomPaymentLabel;
  coveragePackageType?: string;
}

/**
 * Language
 */
export interface language_t {
  [key: string]: string;
}

/**
 * Document
 */
export interface document_t {
  id?: string;
  description?: language_t;
  file?: language_t;
}

/**
 * Factor
 */
export interface factor_t {
  name?: string;
  max?: number;
  min?: number;
  minDate?: string;
  maxDate?: string;
}

/**
 * Factors
 */
export interface factors_t {
  [key: string]: factor_t;
}

/**
 * Period Min - Max
 */
export interface period_min_max_t {
  name?: string;
  max?: number;
  min?: number;
}

/**
 * Periods
 */
export interface periods_t {
  [key: string]: period_min_max_t;
}

/**
 * Guarantee
 */
export interface guarantee_t {
  id?: string;
  name?: language_t;
  ceiling?: language_t;
}

/**
 * Section
 */
export interface section_t {
  name?: language_t;
  premium?: number;
  seasonal_value?: number;
  mandatory?: boolean;
  guarantees?: guarantee_t[];
}

/**
 * Sections
 */
export interface sections_t {
  [key: string]: section_t;
}

/**
 * Main contact
 */
export interface main_contact_t {
  type?: contractor_contact_t;
  value?: string;
}

/**
 * Other contact
 */
export interface other_contact_t {
  type?: contractor_contact_t;
  value?: string;
}

/**
 * Coverage
 */
export interface coverage_t {
  name?: language_t;
  types?: language_t[];
  policyCode?: string;
  basePremium?: number;
}

/**
 * Coverages
 */
export interface coverages_t {
  [key: string]: coverage_t;
}

/**
 * Faq
 */
export interface faq_t {
  id?: string;
  request?: language_t;
  answer?: language_t;
}

/**
 * Logo
 */
export interface Logo {
  id?: string;
  logoDesktop?: string;
  logoMobile?: string;
  logoUrl?: string;
}

/**
 * Constrctor info
 */
export interface contractor_info_t {
  name?: string;
  surname?: string;
  nationality?: nationality_t;
  fiscalCode?: string;
  birthDate?: string;
  birthNation?: string;
  birthPlace?: string;
  gender?: gender_t;
  tdConsent?: boolean;
  privacyConsent?: boolean;
  marketingConsent?: boolean;
}

/**
 * Contractor
 */
export interface contractor_t extends contractor_info_t {
  contractor?: boolean;
  mainContact?: main_contact_t;
  otherContacts?: other_contact_t[];
}

/**
 * Period from - to
 */
export interface period_from_to_t {
  // 1 = Daily
  // 2 = Seasonal
  // 3 = Period
  // 4 = Weekly
  // 5 = Monthly
  // 6 = Quarterly
  type?: "1" | "2" | "3" | "4" | "5" | "6";
  from?: string;
  to?: string;
}

/**
 * DataEntry Steps
 */

/** Step 1 */
export interface DataEntryStep1Props {
  formik: Formik<FormikDataEntryValues>;
  validationSchema: AnyObjectSchema;
  daysOptions: InputSelectOption[];
  dailyOptions: InputSelectOption[];
  minMaxContractor: MinMaxContractor;
}

/** Step 2 */
export interface DataEntryStep2Props {
  agency?: agency_product_t;
  validationSchema: AnyObjectSchema;
  formik: Formik<FormikDataEntryValues>;
  minMaxContractor?: MinMaxContractor;
}

/** Step 3 */
export interface DataEntryStep3Props {
  validationSchema: AnyObjectSchema;
  formik: Formik<FormikDataEntryValues>;
}

export interface DataEntryStepCustom2Props {
  agency?: agency_product_t;
  formik: Formik<FormikDataEntryValues>;
}

/**
 * Transaction
 */
export interface transaction_t {
  acknowledge_consent?: boolean;
  insured?: FormikOtherInsured[];
  coverage_id?: string;
  contact_email?: string;
  coverage_start_date?: string;
  coverage_end_date?: string;
  process_type?: string;
  guarantee_id?: string;
  td_consent?: boolean;
  privacy_consent?: boolean;
  consent_rc?: boolean;
  informative_vision?: boolean;
  caller_id?: string;
  language?: string;
}

export interface card_t {
  id?: number;
  title?: string;
  description?: string;
  bg_color?: string;
}

export type FiscalCode = {
  name: string;
  surname: string;
  birthDate: string;
  gender: "M" | "F";
  birthPlace: string;
};
