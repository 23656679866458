export const HOSTNAME = process.env.REACT_APP_HOSTNAME;

export const WEBAPP_ENV = process.env.REACT_APP_ENV;

export const WEBAPP_API_NAME = process.env.REACT_APP_API_NAME;

export const WEBAPP_ENDPOINT = process.env.REACT_APP_ENDPOINT;

export const WEBAPP_REGION = process.env.REACT_APP_REGION;

export const WEBAPP_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const WEBAPP_GOOGLE_ADS_KEY = process.env.REACT_APP_GOOGLE_ADS_KEY;

export const WEBAPP_GOOGLE_ADS_SEND_KEY =
  process.env.REACT_APP_GOOGLE_ADS_SEND_KEY;

export const MOCKED = process.env.REACT_APP_MOCKED;
