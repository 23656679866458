import {
  calendar_icon,
  checkbox_empty_disabled_icon,
  checkbox_empty_enabled_icon,
  checkbox_full_disabled_icon,
  checkbox_full_enabled_icon,
  close_icon,
  download_icon,
  email_icon,
  failure_icon,
  left_arrow_icon,
  left_icon,
  loader_icon,
  minus_icon,
  phone_icon,
  plus_icon,
  print_icon,
  radio_empty_disabled_icon,
  radio_empty_enabled_icon,
  radio_full_disabled_icon,
  radio_full_enabled_icon,
  right_arrow_icon,
  right_icon,
  success_icon,
  valid_icon,
} from '../../assets'
import { dict } from '../../types'

export const icon_list: dict<string> = {
  calendar: calendar_icon,
  checkbox_empty_disabled: checkbox_empty_disabled_icon,
  checkbox_empty_enabled: checkbox_empty_enabled_icon,
  checkbox_full_disabled: checkbox_full_disabled_icon,
  checkbox_full_enabled: checkbox_full_enabled_icon,
  close: close_icon,
  download: download_icon,
  email: email_icon,
  failure: failure_icon,
  left: left_icon,
  left_arrow: left_arrow_icon,
  loader: loader_icon,
  minus: minus_icon,
  phone: phone_icon,
  plus: plus_icon,
  print: print_icon,
  radio_empty_disabled: radio_empty_disabled_icon,
  radio_empty_enabled: radio_empty_enabled_icon,
  radio_full_disabled: radio_full_disabled_icon,
  radio_full_enabled: radio_full_enabled_icon,
  right: right_icon,
  right_arrow: right_arrow_icon,
  success: success_icon,
  valid: valid_icon,
};
